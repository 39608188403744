import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-data-comparison-card',
  templateUrl: './data-comparison-card.component.html',
  styleUrls: ['./data-comparison-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataComparisonCardComponent {

  @HostBinding('class') class = 'flex flex-column w-full overflow-hidden border-4px mat-elevation-z2'

  @Input()
  crmEntity = false;

  @Input()
  primary = false;

  @Input()
  title: string;

  get headerClasses(): string {
    return this.primary ? 'taures-primary-blue' : 'taures-secondary-blue';
  }

  constructor() { }
}
