import {ChangeDetectionStrategy, Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PdfPreviewComponent} from '../pdf-preview/pdf-preview.component';
import {FileDocument} from '@taures/angular-commons';

@Component({
  selector: 'app-pdf-preview-dialog',
  templateUrl: './pdf-preview-dialog.component.html',
  styleUrls: ['./pdf-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PdfPreviewDialogComponent {
  @ViewChild(PdfPreviewComponent, {static: true})
  previewComponent: PdfPreviewComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: FileDocument,
              public dialogRef: MatDialogRef<PdfPreviewDialogComponent>) {
  }


  get filename() {
    return this.data.filename;
  }

  onDownload() {
    this.previewComponent.download();
  }

}
