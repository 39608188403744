import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DocumentService} from '../../services/document.service';
import {Subscription} from 'rxjs';
import {FileDocument} from '@taures/angular-commons';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Platform} from '@angular/cdk/platform';
import {isNil} from 'lodash-es';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PdfPreviewComponent implements OnDestroy {

  @ViewChild('downloadLink', {static: true})
  downloadLink: ElementRef;
  dataURL: SafeResourceUrl;
  noPreview = false;
  loading = false;
  private url: string;
  private file: FileDocument;
  private subscription: Subscription;

  constructor(private documentService: DocumentService,
              private changeDetector: ChangeDetectorRef,
              private sanitizer: DomSanitizer,
              private platform: Platform) {
  }

  get isFirefox(): boolean {
    return this.platform.FIREFOX;
  }

  get fileDocument(): FileDocument {
    return this.file;
  }

  @Input()
  set fileDocument(value: FileDocument) {
    if (value && this.file &&
      ((this.file.fileId && this.file.fileId === value.fileId) || (this.file.id && this.file.id === value.id))
    ) {
      return;
    }
    this.file = value;
    this.cleanup();
    if (this.file.filename && !this.file.filename.endsWith('pdf')) {
      this.changeDetector.markForCheck();
      this.noPreview = true;
      return;
    }
    if (isNil(this.file.fileId) && isNil(this.file.id)) {
      return;
    }
    this.noPreview = false;
    this.loading = true;
    const downloadObservable = !this.file.fileId && Number(this.file.id) > 0 ? this.documentService.downloadCrmFile(this.file.id)
      : this.documentService.downloadInboxFile(this.file.fileId);
    this.subscription = downloadObservable
      .subscribe(file => {
        this.url = URL.createObjectURL(file);
        this.dataURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.loading = false;
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    this.cleanup();
  }

  download(): void {
    const anchor = this.downloadLink.nativeElement as HTMLAnchorElement;
    anchor.setAttribute('href', this.url);
    anchor.setAttribute('download', this.fileDocument.filename);
    anchor.click();
  }

  private cleanup() {
    if (this.url) {
      URL.revokeObjectURL(this.url);
      this.url = null;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
