import {Pipe, PipeTransform} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ContactType} from '../../../services/app-options.service';

@Pipe({name: 'contactTypeGroup', pure: false})
export class ContactTypeGroupPipe implements PipeTransform {
  transform(contactGroups: UntypedFormGroup[], contactTypes: ContactType[], validGroups: string[]) {
    return contactGroups.filter(group => {
      const type = contactTypes.find(ct => ct.id === group.controls.typ.value);
      if (type) {
        return validGroups.indexOf(type.gruppe) !== -1;
      }
      return false;
    });
  }
}
