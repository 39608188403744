<div appDnd *ngIf="(uploadingFiles | async) === false;else loadingTemplate"
     (fileDropped)="onFilesDropped($event)"
     accept="application/pdf"
     (click)="onAdd()">
  <button type="button" style="color:white" mat-mini-fab  matTooltip="Neues Dokument hinzufügen">
    <mat-icon>add</mat-icon>
  </button>
  <span>oder PDF-Dateien auf dieses Feld ziehen</span>
</div>

<ng-template #loadingTemplate>
  <div class="dropzone">
    <mat-progress-spinner mode="indeterminate" [diameter]="40" [strokeWidth]="4"></mat-progress-spinner>
    <span style="margin-left: 0.5rem">Dateien werden verarbeitet...</span>
  </div>
</ng-template>
