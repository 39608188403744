import {Task, TaskDefinition} from '../../shared/common/services/task.service';
import {
  Actions,
  GOTO_STEP,
  NEXT_STEP,
  PREVIOUS_STEP,
  START_WIZARD_SUCCESS,
  UPDATE_TASK_VARIABLES,
  UPDATE_WIZARD_TASK
} from '../actions/wizard.actions';
import {cloneDeep} from 'lodash-es';
import {
  CUSTOMER_CONTRACT_SINGLE,
  FOLLOW_UP_AGENT_STEPS,
  FOLLOW_UP_BACK_OFFICE_STEPS,
  Step,
  UNKNOWN
} from '../step-definitions';
import {Person} from '@taures/angular-commons';

export interface State {
  task: Task | null;
  currentStep: Step;
  customer: Person;
}

export const initialState: State = {
  task: null,
  currentStep: null,
  customer: null
};

export function reducer(state = initialState, action: Actions) {
  switch (action.type) {
    case START_WIZARD_SUCCESS:
      return {
        ...state,
        task: cloneDeep(action.payload.task),
        customer: action.payload.customer,
        currentStep: action.payload.step || nextStep(action.payload.task, action.payload.customer)
      };
    case NEXT_STEP:
      return {
        ...state,
        task: {...state.task, variables: action.payload.variables},
        currentStep: nextStep(state.task, action.payload.customer, state.currentStep)
      };
    case PREVIOUS_STEP:
      return {
        ...state,
        task: {...state.task, variables: action.payload},
        currentStep: previousStep(state.task, state.currentStep)
      };
    case GOTO_STEP:
      return {
        ...state,
        task: {...state.task, variables: action.payload.variables || state.task.variables},
        currentStep: action.payload.step
      };
    case UPDATE_WIZARD_TASK: {
      return {...state, task: {...state.task, variables: action.payload}};
    }
    case UPDATE_TASK_VARIABLES:
      return {...state, task: {...state.task, variables: action.payload.variables || state.task.variables}};
    default:
      return state;
  }
}

export function stepsOfTask(task: Task) {
  switch (task.formKey) {
    case TaskDefinition.FollowUpAgent:
      return FOLLOW_UP_AGENT_STEPS;
    case TaskDefinition.FollowUpBackOffice:
      let steps = FOLLOW_UP_BACK_OFFICE_STEPS;
      if (!task.variables.contractId) {
        steps = steps.filter(step => step.title !== CUSTOMER_CONTRACT_SINGLE.title);
      }
      return steps;
  }
  return [];
}

function nextStep(task: Task, customer?: Person, currentStep?: Step): Step {
  const stepsArray = stepsOfTask(task);
  if (stepsArray.length === 0) {
    return UNKNOWN;
  }

  let index = stepsArray.indexOf(currentStep);
  while (index < stepsArray.length) {
    index++;
    if (!stepsArray[index].canSkip(task, customer)) {
      break;
    }
  }
  return stepsArray[index];
}

function previousStep(task: Task, currentStep?: Step): Step {
  const stepsArray = stepsOfTask(task);
  if (stepsArray.length === 0) {
    return UNKNOWN;
  }

  const prevIndex = stepsArray.indexOf(currentStep) - 1;
  return stepsArray[prevIndex < 0 ? 0 : prevIndex];
}

export function canBack(task: Task, currentStep: Step): boolean {
  return previousStep(task, currentStep) !== currentStep;
}

export function willComplete(task: Task, currentStep: Step): boolean {
  const stepsArray = stepsOfTask(task);
  const index = stepsArray.indexOf(currentStep);
  return index === (stepsArray.length - 1);
}

export function isValidStep(task: Task, step: Step): boolean {
  const stepsArray = stepsOfTask(task);
  return stepsArray.indexOf(step) !== -1;
}

export const getTask = (state: State) => state.task;
export const getCurrentContract = (task: Task | null) => task?.variables?.customer?.vertraege?.filter(c => c.id < 0);
export const getCustomer = (state: State) => state.customer;
export const getCurrentStep = (state: State) => state.currentStep;
