<h2 mat-dialog-title>{{filename}}</h2>
<mat-dialog-content>
  <app-pdf-preview [fileDocument]="data" class="h-full"></app-pdf-preview>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-icon-button (click)="onDownload()">
    <mat-icon>file_download</mat-icon>
  </button>
  <button type="button" mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>


