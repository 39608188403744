import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SingleRelationshipComponent} from './single-relationship/single-relationship.component';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {
  Address,
  Household,
  Person,
  PersonService,
  Relationship,
  RelationshipType,
  Status
} from '@taures/angular-commons';
import {AppOptionsService} from '../../../services/app-options.service';
import {TaskDefinition} from '../../../../shared/common/services/task.service';

@Component({
  selector: 'app-customer-relationships-comparison',
  templateUrl: 'customer-relationships.component.html',
  styleUrls: ['customer-relationships.component.scss']
})
export class CustomerRelationshipsComponent implements OnDestroy, OnInit {
  customer: UntypedFormGroup;
  relationshipTypes: RelationshipType[];
  relationships: UntypedFormArray;
  household: UntypedFormGroup;
  customerId: string;
  customerAddresses: Address[];
  @Input()
  fromDocs = false;
  vpFilter = {};
  betreuerFilter = {only34d: true, excludeInactive: true};

  constructor(private fb: UntypedFormBuilder, readonly options: AppOptionsService, readonly personService: PersonService) {
  }

  private allVariables: UntypedFormGroup;
  private subscription: Subscription;

  get variables() {
    return this.allVariables;
  }


  @Input()
  set variables(value: UntypedFormGroup) {
    this.allVariables = value;
    this.customer = this.variables.controls.customer as UntypedFormGroup;
    this.household = this.customer.get('haushalt') as UntypedFormGroup;
    this.customerId = this.customer.get('id').value;
    this.customerAddresses = (this.customer.get('adressen') as UntypedFormArray).getRawValue() as Address[];
    const beziehungen = this.customer.controls.beziehungen as UntypedFormArray;
    const beziehungenVon = this.customer.controls.beziehungenVon as UntypedFormArray;

    // create a single list with all relationships
    this.relationships = this.fb.array([]);
    for (const control of beziehungen.controls) {
      this.relationships.push(SingleRelationshipComponent.buildGroup(this.fb, control.value));
    }

    for (const control of beziehungenVon.controls) {
      this.relationships.push(SingleRelationshipComponent.buildGroup(this.fb, control.value));
    }
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
    // split the relationships back into the two
    this.subscription = this.relationships.valueChanges.subscribe((relList: Relationship[]) => {
      beziehungen.controls.splice(0);
      beziehungenVon.controls.splice(0);
      relList.forEach(rel => {
        if (SingleRelationshipComponent.isRelationship(rel, this.customerId)) {
          beziehungen.push(SingleRelationshipComponent.buildGroup(this.fb, rel));
        } else if (SingleRelationshipComponent.isRelationshipFrom(rel, this.customerId)) {
          beziehungenVon.push(SingleRelationshipComponent.buildGroup(this.fb, rel));
        }
      });
    });
  }

  public static buildGroup(fb: UntypedFormBuilder, taskDefinition: TaskDefinition, variables: any) {
    const customer = variables.customer as Person;
    const kunde = customer.kunde || {} as any;
    const haushalt = customer.haushalt || {} as Household;
    const beziehungen = fb.array([]);
    const beziehungenVon = fb.array([]);

    if (customer && customer.beziehungen) {
      customer.beziehungen
        .forEach(r => beziehungen.push(SingleRelationshipComponent.buildGroup(fb, r, r.status !== Status.DELETED)));
    }

    if (customer && customer.beziehungenVon) {
      customer.beziehungenVon
        .forEach(r => beziehungenVon.push(SingleRelationshipComponent.buildGroup(fb, r, r.status !== Status.DELETED)));
    }
    if (!haushalt.mitglieder || haushalt.mitglieder.length === 0) {
      haushalt.mitglieder = [customer.id.toString()];
      haushalt.oberhaupt = customer.id.toString();
    }

    return fb.group({
      customer: fb.group({
        id: customer.id,
        adressen: fb.array(customer.adressen),
        beziehungen,
        beziehungenVon,
        haushalt: fb.group({
          mitglieder: fb.array(haushalt.mitglieder),
          oberhaupt: [haushalt.oberhaupt, [Validators.required]]
        }),
        kunde: fb.group({
          ...kunde,
          kategorievp: kunde.kategorievp,
          kundeSeit: [kunde.kundeSeit],
          betreuer: [kunde.betreuer, [Validators.required]],
          vp: [kunde.vp, [Validators.required]]
        })
      })
    });
  }

  ngOnInit(): void {
    this.relationshipTypes = this.options.relationshipTypes;
    if (this.fromDocs) {
      this.relationshipTypes = this.relationshipTypes.filter((rel) => rel.dbvalue !== 'EMPFOHLEN');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  addRelationship() {
    this.relationships.push(SingleRelationshipComponent.buildGroup(this.fb, {}));
  }

  removeRelationship(i: number) {
    const entity = this.relationships.at(i);
    const memberId = SingleRelationshipComponent.isRelationship(entity.value, this.customerId) ?
      entity.get('ziel').value : entity.get('quelle').value;
    if (entity.get('id').value) {
      entity.patchValue({status: Status.DELETED}, {emitEvent: false});
    } else {
      this.relationships.removeAt(i);
    }
    this.removeHouseholdmember(memberId);
  }

  getHaushaltsmitglieder() {
    return this.variables.get('customer.haushalt.mitglieder').value;
  }

  getPersonName(id: string): Observable<string> {
    return this.personService.getPerson(id).pipe(
      map(person => person.vorname + ' ' + person.nachname)
    );
  }

  isSameHousehold(relationship: Relationship) {
    const householdMembers: string[] = this.household.get('mitglieder').value;
    return householdMembers.includes(relationship.quelle) && householdMembers.includes(relationship.ziel);
  }

  addHouseholdmember(memberId: string) {
    const members = this.household.get('mitglieder') as UntypedFormArray;
    members.push(new UntypedFormControl(memberId));
  }

  removeHouseholdmember(memberId: string) {
    if (memberId != null) {
      const members = this.household.get('mitglieder') as UntypedFormArray;
      members.removeAt(members.value.findIndex(id => id === memberId));
      if (this.household.get('oberhaupt').value === memberId) {
        this.household.get('oberhaupt').setValue(null);
      }
    }
  }

  showRecommended(control: AbstractControl) {
    return !this.fromDocs || (control.get('typ').value !== 'EMPFOHLEN');
  }

  showRelationship(relationship: AbstractControl) {
    return this.showRecommended(relationship) && relationship.get('status').value !== Status.DELETED;
  }

  getCategories() {
    return this.options.categoryVpTypes;
  }

  canChangeVP(): boolean {
    const kunde = this.customer.get('kunde.kundeSeit');
    if (kunde && kunde.value) {
      return moment().diff(moment(kunde.value), 'months', false) < 2;
    }
    return true;
  }
}
