import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Rework} from './rework';
import {map} from 'rxjs/operators';

@Injectable()
export class ReworksService {
  private reworkStatus = ['OFFEN',
    'IN_ARBEIT',
    'PAUSIERT',
    'ERLEDIGT_VP',
    'IA_TAURES',
    'IA_KUNDE',
    'IA_PRODUKTANBIETER',
    'IA_KOOPERATIONSPARTNER',
    'IA_VPVM',
    'IA_ZD',
    'SC_WV',
    'SC_GEPRUEFT',
    'IA_VP_SERVICE',
    'IA_VR',
    'IA_ZA',
    'IA_PROVISION'];

  constructor(private http: HttpClient) {
  }

  private static compare(a: Rework, b: Rework): number {
    return ReworksService.compareDate(a.createDate, b.createDate);
  }

  static compareDate(a: Date, b: Date): number {
    return (a || b) ? (!a ? -1 : (!b ? 1 : (a === b ? 0 : (a < b ? 1 : -1)))) : 0;
  }

  getReworks(customerId: number | string): Observable<Rework[]> {
    const status = this.reworkStatus.reduce((prev, cur) => prev + '&status=' + cur, '');
    return this.http
      .get<Rework[]>(`/t-it-s/rest/crm/v1.0/aktionen?kunde=${customerId}&aktionsTyp=NACHBEARBEITUNG${status}`)
      .pipe(
        map(reworks => reworks.sort(ReworksService.compare))
      );
  }
}
