<div [formGroup]="variables" class="flex flex-column flex-grow-1">
  <div [formGroup]="customer" class="flex flex-wrap gap-3 mb-3">

    <div *ngFor="let employment of beschaeftigungsverhaeltnisse.controls | notDeleted; let i=index">
      <app-single-employment
        [control]="employment"
        class="employment-width"
        [employmentOptions]="employmentTypes"
        (deleteCard)="removeCard(i)"
        (setAsPrime)="setToPrime(i)">
      </app-single-employment>
    </div>

  </div>
  <button class="text-white" type="button" mat-fab (click)="addCard()" matTooltip="Neue Beschäftigung hinzufügen">
    <mat-icon>add</mat-icon>
  </button>
</div>
