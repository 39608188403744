import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CreateNewProcessAction} from '../../actions/inbox';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {BROKER_CONTRACT, CUSTOMER_ADDRESSES, CUSTOMER_BANK, CUSTOMER_BASIC, CUSTOMER_RELATIONSHIP} from '../../step-definitions';
import {ProgressSpinnerService} from '../progress-spinner-dialog/progress-spinner-service';

@Component({
  selector: 'app-aza-start',
  templateUrl: './aza-start.component.html',
  styleUrls: ['./aza-start.component.scss']
})
export class AzaStartComponent implements OnInit {

  constructor(private store: Store<fromRoot.State>,
              private route: ActivatedRoute,
              private progressService: ProgressSpinnerService) {
  }

  ngOnInit(): void {
    let step = CUSTOMER_BASIC;
    const customerId = this.route.snapshot.paramMap.get('customerId');
    const variables: any = {customerId};
    this.progressService.show();
    if (this.route.snapshot.paramMap.get('step') === 'address') {
      step = CUSTOMER_ADDRESSES;
    } else if (this.route.snapshot.paramMap.get('step') === 'bank') {
      step = CUSTOMER_BANK;
    } else if (this.route.snapshot.paramMap.get('step') === 'relationship') {
      step = CUSTOMER_RELATIONSHIP;
    } else if (this.route.snapshot.paramMap.get('step') === 'broker-contract') {
      step = BROKER_CONTRACT;
      variables.skipFollowUpAgent = true;
    }
    this.store.dispatch(new CreateNewProcessAction({processDefinitionKey: 'aza', variables, step}));
  }

}
