import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as moment from 'moment';
import {ContactType} from './services/app-options.service';
import {Person} from '@taures/angular-commons';
import {Moment} from "moment";

const CODE_LENGTHS = {
  AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
  CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
  FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
  HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
  LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
  MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
  RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
};

function mod97(value: string) {
  let checksum: string | number = value.slice(0, 2);
  let fragment: string;

  for (let offset = 2; offset < value.length; offset += 7) {
    fragment = checksum + value.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
}

function trimString(value: string) {
  return value ? value.toUpperCase().replace(/[^A-Z0-9]/g, '') : value;
}

export class TauresValidators {

  static accountNumber = Validators.pattern(/^[0-9]{10}$/);
  static phone = Validators.pattern(/^([+0])\d+[\d \/-]*\d+$/);
  static bic = Validators.pattern(/^[A-Za-z0-9]{8}([A-Za-z0-9]{3})?$/); // BIC is 8 or 11 alpha numeric chars
  static blz = Validators.pattern(/^[0-9]{8}$/);
  static chars = Validators.pattern(/^([\p{L} \-'’´`]*)$/u);

  static oneContactGroupRequired(contactTypes: ContactType[], requiredGroups: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control instanceof UntypedFormArray) {
        let i = 0;
        let hasGroup = false;
        while (i < control.length) {
          const group = control.at(i) as UntypedFormGroup;
          const typeId = group.controls.typ.value;
          const contactType = contactTypes.find(type => type.id === typeId);
          if (requiredGroups.includes(contactType.gruppe)) {
            hasGroup = true;
            break;
          }
          i++;
        }
        return !hasGroup ? {groupRequired: requiredGroups} : null;
      }
      return null;
    };
  }

  static maxDate(date: Date = new Date()): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return moment(value).isAfter(moment(date)) ? {maxDate: date} : null;
    };
  }

  static dateRange(minDate: Moment,
                   maxDate: Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const valueMoment = moment(value).startOf("day");
      if (!value || !valueMoment.isValid()) {
        return null;
      }
      const isInvalid = valueMoment.isBefore(minDate) || valueMoment.isAfter(maxDate);
      return isInvalid ? {dateRange: [minDate, maxDate]} : null;
    };
  }


  static duplicateField(fieldName: string, trim = true): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control instanceof UntypedFormArray) {
        if (!control.controls) {
          return null;
        }
        const fieldValues = [];
        const duplicates = [];

        control.controls.forEach(c => {
          if (c.get(fieldName) && c.get(fieldName).value) {
            const originalValue = c.get(fieldName).value;
            let searchValue = originalValue;
            if (typeof originalValue === 'string' && trim) {
              searchValue = originalValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
            }
            if (fieldValues.indexOf(searchValue) !== -1 && duplicates.indexOf(searchValue) === -1) {
              duplicates.push(trim ? searchValue : originalValue);
            }
            fieldValues.push(searchValue);
          }
        });
        if (duplicates.length > 0) {
          return {duplicateField: duplicates};
        }
      }
      return null;
    };
  }

  static duplicateIbans(control: UntypedFormArray): ValidationErrors | null {
    if (!control.controls) {
      return null;
    }

    const duplicates = [];

    control.controls.forEach((c1, indexC1) => {
      const originalValue = c1.get('iban').value;
      const c1SearchValue = trimString(originalValue);
      if (c1SearchValue) {
        control.controls.forEach((c2, indexC2) => {
          const c2SearchValue = trimString(c2.get('iban').value);
          if (indexC1 !== indexC2 && c1SearchValue === c2SearchValue
            && (!c1.get('id').value || !c2.get('id').value)
            && duplicates.indexOf(originalValue) === -1) {
            duplicates.push(originalValue);
          }
        });
      }
    });

    if (duplicates.length > 0) {
      return {duplicateField: duplicates};
    }
    return null;
  }

  static iban(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const error = {iban: {value: control.value}};

    // trim and clear
    const iban = trimString(value);
    // match
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return error;
    }

    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, l => String(l.charCodeAt(0) - 55));

    return mod97(digits) !== 1 ? error : null;
  }

  static bankverbindungRequired(control: AbstractControl): ValidationErrors | null {
    const customer = control.value as Person;

    if (customer.kunde.bezahltPerRechnung) {
      return null;
    }

    if (customer.bankverbindungen.length === 0) {
      return {bankverbindungRequired: true};
    }
    return null;
  }

  static requiredFalse(control: AbstractControl): ValidationErrors | null {
    return control.value ? {requiredFalse: true} : null;
  }

  static meldeanschriftRequired(control: AbstractControl): ValidationErrors | null {
    const customer = control.value as Person;
    if (customer.adressen && customer.adressen.filter(adresse => adresse.meldeanschrift).length === 1) {
      return null;
    }
    return {meldeanschriftRequired: true};
  }

  static postanschriftRequired(control: AbstractControl): ValidationErrors | null {
    const customer = control.value as Person;
    if (customer.adressen && customer.adressen.filter(adresse => adresse.postanschrift).length === 1) {
      return null;
    }
    return {postanschriftRequired: true};
  }

  static hauptverbindungRequired(control: AbstractControl): ValidationErrors | null {
    const customer = control.value as Person;
    if (customer.kunde.bezahltPerRechnung) {
      return null;
    }
    if (customer.bankverbindungen && customer.bankverbindungen.filter(bank => bank.hauptverbindung).length === 1) {
      return null;
    }
    return {hauptverbindungRequired: true};
  }

  static isObject(control: AbstractControl): ValidationErrors | null {
    if (control && typeof control.value === 'object') {
      return null;
    }
    return {isObject: true};
  }

  static isNumber(control: AbstractControl): ValidationErrors | null {
    if (control && typeof control.value === 'number') {
      return null;
    }
    return {isNumber: true};
  }

  static isDocumentType(documentTypeKeys: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return documentTypeKeys.includes(control.value) ? null : {isDocumentType: true};
    };
  }

  static aenderungsauftragVertragRequired(control: AbstractControl): ValidationErrors | null {
    const parent = control.parent as UntypedFormArray;
    const aenderungsauftragVertraege = control.get('aenderungsauftragVertraege');
    if (control.get('aenderungsauftrag').value !== 'YES' ||
      (aenderungsauftragVertraege.value && aenderungsauftragVertraege.value.length > 0)) {
      return null;
    }
    return {aenderungsauftragVertragRequired: true};
  }
}

