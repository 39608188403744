import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';

import {Insurer, Tariff} from '../../../../services/tariff.service';
import {ContractDocumentsComponent} from '../contract-documents/contract-documents.component';
import {isNil, round} from 'lodash-es';
import {TaskDefinition} from '../../../../../shared/common/services/task.service';
import {Tariffs} from '../../../../tariffs';
import * as moment from 'moment';
import {TauresValidators} from '../../../../taures-validators';
import {formatDate} from '@angular/common';
import {ScriptService} from '../../../../services/script.service';
import {Rework} from '../../../../services/rework';
import {IdentityType} from '../../../../services/app-options.service';
import {Contract, IdentificationFeature, Person} from '@taures/angular-commons';
import {Moment} from "moment";

const ANTRAGART_ORDER = ['o', 'h', 'bu', 'p', 'km', 'kb'];

@Component({
  selector: 'app-single-contract-full',
  templateUrl: 'single-contract-full.component.html',
  styleUrls: ['single-contract-full.component.scss']
})
export class SingleContractComponent implements OnInit, OnDestroy {
  @Output() resetContract: EventEmitter<void> = new EventEmitter<void>();
  @Input() taskDefinition: TaskDefinition;
  @Input() contractGroup: UntypedFormGroup;
  @Input() identityTypes: IdentityType[];
  @Input() antragartTypes: { [key: string]: string };
  @Input() antragEmpfaengerTypes: { [key: string]: string };
  @Input() buAntragVariantenTypes: { [key: string]: string };
  @Input() pkvAntragsqualitaeten: { [key: string]: string };
  @Input() paymentOptions: { uivalue: string, dbvalue: string }[];

  insurerControl: UntypedFormControl = new UntypedFormControl();
  insurerFilterControl: UntypedFormControl = new UntypedFormControl();
  insuredCustomerIDControl: UntypedFormControl = new UntypedFormControl();
  versichertePersonen: UntypedFormArray;
  filteredTariffIds: Observable<{ group: string, tariffIds: number[] }[]>;
  includeConsultantFilter = {excludeSalesStaff: true};
  only34dFilter = {only34d: true, includeSupervisorChain: true};

  customer: Person;
  possibleMainContracts: Contract[];
  existingContracts: Contract[];

  softfairContract: any;

  @ViewChild('insurerName', {static: true}) insurerName: MatAutocomplete;
  @ViewChild('tariffName', {static: true}) tariffName: MatAutocomplete;

  showLaufzeitende: boolean;
  showBeitragzahldauer: boolean;
  showIdentifikationsmerkmal: boolean;
  isBUContract: boolean;
  isPkvAntragsqualitaet: boolean;
  today = new Date();
  filteredInsurers: Observable<Insurer[]>;
  private stop = new Subject();

  private static CONTRACT_MIN_DATE = moment({year: 1900}).startOf("year");
  private static CONTRACT_MAX_DATE = moment().add(2, "years").endOf("day");

  constructor(@Inject(LOCALE_ID) private locale: string, private scriptService: ScriptService) {
  }

  private allInsurers: Insurer[] = [];

  protected readonly contractMinDate = SingleContractComponent.CONTRACT_MIN_DATE;
  protected readonly contractMaxDate = SingleContractComponent.CONTRACT_MAX_DATE;

  get insurers() {
    return this.allInsurers;
  }

  set insurers(value: Insurer[]) {
    this.allInsurers = value;
  }

  get isBackoffice() {
    return this.taskDefinition && this.taskDefinition === TaskDefinition.FollowUpBackOffice;
  }

  private allTariffsOfInsurer: Tariff[];

  get tariffsOfInsurer() {
    return this.allTariffsOfInsurer || this.tariffs;
  }

  private allTariffs: Tariff[] = [];

  get tariffs() {
    return this.allTariffs;
  }

  @Input()
  set tariffs(value: Tariff[]) {
    this.allTariffs = value || [];
    this.insurers = this.allTariffs.reduce((list, tariff) => {
      if (!list.find((c: Insurer) => c.id === tariff.gesellschaft.id)) {
        return [...list, tariff.gesellschaft];
      }
      return list;
    }, []).sort((a, b) => a.kurzname.localeCompare(b.kurzname, this.locale));

    // since we receive the tariffs in a asynchronous way, we have to setup the display data here
    if (this.contractGroup && this.tariffs.length) {
      const tariffControl = this.contractGroup.get('tarif');
      const tariffId = tariffControl.value;
      if (tariffId) {
        const tariff = this.tariffs.find(t => t.id === tariffId);
        if (tariff) {
          const insurer = tariff.gesellschaft;
          this.insurerControl.reset(insurer);
          this.allTariffsOfInsurer = this.getTariffsOfInsurer(insurer);
          // trigger change in order to display the tariff name correctly
          tariffControl.reset(tariffId);
          this.updateTariffSpecificFields(tariff);
        }
      }
    }
  }

  private allContracts: Contract[] = [];

  get contracts() {
    return this.allContracts;
  }

  @Input()
  set contracts(value: Contract[]) {
    this.allContracts = value;
    this.possibleMainContracts = this.filterVertraege();
    const hauptvertragControl = this.contractGroup.get('hauptvertrag');
    if (!isNil(hauptvertragControl.value) &&
      isNil(this.possibleMainContracts.find(contract => contract.id === hauptvertragControl.value))) {
      hauptvertragControl.reset({emitEvent: false});
    }
    const isHauptvertrag = value.some(contract => contract.hauptvertrag === this.contractGroup.get('id').value);
    const hasBundlepolice = this.contractGroup.get('buendelpolice').value;
    if ((hasBundlepolice || isHauptvertrag) && hauptvertragControl.enabled) {
      hauptvertragControl.disable();
    } else if (!hasBundlepolice && !isHauptvertrag && hauptvertragControl.disabled) {
      hauptvertragControl.enable();
    }
  }

  @Input()
  set customerGroup(inp: UntypedFormGroup) {
    this.customer = inp.getRawValue() as Person;
  }

  get antragartTypesKeys() {
    return Object.keys(this.antragartTypes)
      .sort((a, b) => SingleContractComponent.compareAntragArt(a, b))
      .map(key => {
        return {key, value: this.antragartTypes[key]};
      })
      .map(entry => entry.key);
  }

  get buAntragVariantenTypesKeys() {
    return Object.keys(this.buAntragVariantenTypes)
      .sort((a, b) => a.localeCompare(b, this.locale))
      .map(key => {
        return {key, value: this.buAntragVariantenTypes[key]};
      })
      .map(entry => entry.key);
  }

  get pkvAntragsqualitaetenKeys() {
    return Object.keys(this.pkvAntragsqualitaeten)
      .sort((a, b) => a.localeCompare(b, this.locale))
      .map(key => {
        return {key, value: this.pkvAntragsqualitaeten[key]};
      })
      .map(entry => entry.key);
  }

  get antragEmpfaengerTypesKeys() {
    return Object.keys(this.antragEmpfaengerTypes)
      .sort((a, b) => a.localeCompare(b, this.locale))
      .map(key => {
        return {key, value: this.antragEmpfaengerTypes[key]};
      })
      .map(entry => entry.key);
  }

  public static buildGroup(fb: UntypedFormBuilder,
                           contract: Contract,
                           customer: Person,
                           taskDefinition: TaskDefinition,
                           validate = true,
                           reworks: Rework[] = [],
                           tariffDisabled = false,
                           priorContractDisabled = false,
                           softfairContract: Contract = null,
                           tariff: Tariff = null): UntypedFormGroup {

    const needsValidation = contract.id < 0 && validate;
    const isBackOffice = taskDefinition === TaskDefinition.FollowUpBackOffice;
    const isBUContract = tariff && tariff.vertragart.buAntragVariante;
    const hasBeitragzahldauer = tariff && tariff.vertragart.beitragzahldauer;

    const validateAusgang = !reworks.filter(rework => rework.objekttyp === 'V').some(rework => rework.gvKategorie === 'SFAI'
      && (rework.geschaeftsvorfall === 'ANW' || rework.geschaeftsvorfall === 'ANF' || rework.geschaeftsvorfall === 'AVP'));
    const validateEingang = !reworks.filter(rework => rework.objekttyp === 'V')
      .some(rework => rework.gvKategorie === 'SFAI' && rework.geschaeftsvorfall === 'ANF');

    return fb.group({
      id: [contract.id, validate ? [Validators.required] : []],
      gesellschaftsname: contract.gesellschaftsname,
      sparte: contract.sparte,
      tarifBezeichnung: contract.tarifBezeichnung,
      tarif: [{
        value: contract.tarif,
        disabled: tariffDisabled
      }, needsValidation ? [Validators.required, Validators.min(1)] : []],
      posteingang: [contract.posteingang, isBackOffice && needsValidation && validateEingang
        ? Validators.required : []],
      postausgang: [contract.postausgang, isBackOffice && needsValidation && validateAusgang
        ? [Validators.required, TauresValidators.maxDate()] : []],
      antragEmpfaenger: [contract.antragEmpfaenger, isBackOffice && needsValidation ? [Validators.required] : []],
      antragsdatum: [contract.antragsdatum, needsValidation ? Validators.required : []],
      beginlaufzeit: [contract.beginlaufzeit, needsValidation ? Validators.required : []],
      laufzeitende: [contract.laufzeitende],
      bewertungssumme: [contract.bewertungssumme, needsValidation ? [Validators.required, Validators.min(0)] : []],
      endAlter: SingleContractComponent.calculateEndAlter(contract.laufzeitJahre,
        contract.laufzeitMonate,
        contract.beginlaufzeit || contract.antragsdatum,
        customer.geburtsdatum),
      laufzeitJahre: [contract.laufzeitJahre,
        hasBeitragzahldauer && isBackOffice && needsValidation ? [Validators.required, Validators.min(0)] : []],
      laufzeitMonate: [contract.laufzeitMonate,
        hasBeitragzahldauer && isBackOffice && needsValidation ? [Validators.required, Validators.min(0)] : []],
      bemerkung: [contract.bemerkung, needsValidation ? [Validators.maxLength(200)] : []],
      versichertePersonen: fb.array(contract.versichertePersonen || []),
      hauptvertrag: [{value: contract.hauptvertrag, disabled: priorContractDisabled || contract.buendelpolice}],
      identifikationsmerkmal: [contract.identifikationsmerkmal],
      abgeloesteVertraege: [contract.abgeloesteVertraege],
      vp: [contract.vp, needsValidation ? [Validators.required] : []],
      consultant: [contract.consultant, needsValidation ? [Validators.required] : []],
      antragart: [contract.antragart, needsValidation ? [Validators.required] : []],
      fremdvertrag: [contract.fremdvertrag],
      tarifbeitrag: [contract.tarifbeitrag, needsValidation ? [Validators.required, Validators.min(0)] : []],
      zahlbeitrag: [contract.zahlbeitrag, needsValidation ? [Validators.required, Validators.min(0)] : []],
      zahlweise: [contract.zahlweise, needsValidation ? [Validators.required] : []],

      dokumente: ContractDocumentsComponent.buildArray(fb, contract.dokumente),
      buAntragVariante: [contract.buAntragVariante, isBUContract && isBackOffice && needsValidation ? [Validators.required] : []],
      pkvAntragsqualitaet: [contract.pkvAntragsqualitaet],
      azaId: contract.azaId,
      newAzaId: [contract.newAzaId],
      softfairContract,
      buendelpolice: [contract.buendelpolice],
      uebergebenAnVU: [contract.uebergebenAnVU],
      antragsnummerVM: [contract.antragsnummerVM]
    });
  }

  public static compareAntragArt(antragart1: string, antragart2: string) {
    return ANTRAGART_ORDER.indexOf(antragart1) - ANTRAGART_ORDER.indexOf(antragart2);
  }

  private static calculateEndAlter(laufzeitJahre: number, laufzeitMonate: number, start: Date, geburtsdatum: Date): number {
    if (isNil(laufzeitJahre) || isNil(laufzeitMonate)) {
      return null;
    }
    const end = moment(start)
      .add(laufzeitJahre, 'year')
      .add(laufzeitMonate, 'month');

    const endAge = Math.floor(end.diff(moment(geburtsdatum), 'year', true));
    return endAge === 0 ? null : endAge;
  }

  getNameFromTariff(tariffID: number): string {
    const tarifName = Tariffs.tariff(this.allTariffs, tariffID).tarifbez;
    return tarifName ? tarifName : '';
  }

  ngOnInit() {
    this.existingContracts = this.allContracts.filter(contract => contract.id > 0);
    this.versichertePersonen = this.contractGroup.get('versichertePersonen') as UntypedFormArray;
    this.softfairContract = this.contractGroup.get('softfairContract').value;

    this.filteredInsurers = this.insurerFilterControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsurers(value))
    );


    this.insurerControl.setValidators(TauresValidators.isObject);
    // preselect gesellschaft from softfair
    if (this.softfairContract && this.softfairContract.gesellschaft) {
      this.insurerControl.setValue(this.softfairInsurer());
    }
    const initInsurer: Insurer = this.insurerControl.value;
    this.insurerControl.valueChanges.pipe(
      startWith(initInsurer),
      takeUntil(this.stop)
    ).subscribe((insurer: Insurer) => {
      if (insurer) {
        if (!initInsurer || insurer.id !== initInsurer.id) {
          // only reset tarif if initial insurer is not the same as the "selected" one
          this.contractGroup.get('tarif').setValue(null, {emitEvent: false});
        }
        this.contractGroup.get('tarif').enable({emitEvent: false});
        this.allTariffsOfInsurer = this.getTariffsOfInsurer(insurer);
        const tariffs = this.filterTariffs(null);
        this.filteredTariffIds = of(this.getFilteredTarifIds(tariffs));
      } else {
        this.filteredTariffIds = EMPTY;
      }
    });

    this.contractGroup.get('tarif').valueChanges.subscribe((tariffId) => {
      const tariff = this.tariffs.find(t => t.id === tariffId);
      this.updateTariffSpecificFields(tariff);
      this.updateTarifbeitragToCalculatedValue();
      this.updateBwsToCalculatedValue();
    });

    this.contractGroup.get('laufzeitMonate').valueChanges.pipe(takeUntil(this.stop)).subscribe(value => {
      const MONTHS_PER_YEAR = 12;
      this.calculateEndAlter(this.contractGroup.get('laufzeitJahre').value);
      if (value >= MONTHS_PER_YEAR) {
        this.contractGroup.get('laufzeitJahre').setValue(this.contractGroup.get('laufzeitJahre').value + value / MONTHS_PER_YEAR);
        this.contractGroup.get('laufzeitMonate').setValue(value % MONTHS_PER_YEAR);
      }
      this.updateBwsToCalculatedValue();
    });

    this.contractGroup.get('laufzeitJahre').valueChanges.pipe(takeUntil(this.stop)).subscribe(value => {
      this.calculateEndAlter(value);
      this.updateBwsToCalculatedValue();
    });

    // calculate laufzeitMontate
    this.contractGroup.get('endAlter').valueChanges
      .pipe(takeUntil(this.stop)).subscribe(value => {
      this.calculateLaufzeit(this.contractGroup.get('beginlaufzeit').value || this.contractGroup.get('antragsdatum').value, value);
    });


    // calculate laufzeitMontate
    this.contractGroup.get('beginlaufzeit').valueChanges
      .pipe(takeUntil(this.stop)).subscribe(value => {
      this.calculateLaufzeit(value, this.contractGroup.get('endAlter').value);
    });

    this.insuredCustomerIDControl.valueChanges
      .pipe(
        takeUntil(this.stop)
      )
      .subscribe((id) => {
        this.addInsuredCustomer(id);
        this.resetCustomerAutocompleteView();
      });

    this.contractGroup.get('zahlbeitrag').valueChanges
      .pipe(
        takeUntil(this.stop),
        filter(() => !this.isTarifbeitragCalculationDisabled())
      )
      .subscribe(() => {
        this.updateBwsToCalculatedValue();
        this.updateTarifbeitragToCalculatedValue();
      });

    this.contractGroup.get('tarifbeitrag').valueChanges
      .pipe(
        takeUntil(this.stop)
      )
      .subscribe(() => this.updateBwsToCalculatedValue());

    this.contractGroup.get('zahlweise').valueChanges
      .pipe(
        takeUntil(this.stop)
      )
      .subscribe(() => this.updateBwsToCalculatedValue());
  }

  ngOnDestroy() {
    this.stop.next(null);
  }

  filterInsurers(value: string): Insurer[] {
    return this.insurers
      .filter((insurer) => insurer.kurzname.toLowerCase().includes(value.toLowerCase()));
  }

  onConvertToMain() {
    this.resetContract.emit();
  }

  displayIdentityFeature(value: IdentificationFeature) {
    const identityType = this.identityTypes.find(it => it.id === value.ausweisart);
    if (identityType) {
      return `${identityType.bezeichnung} (${value.nummer})`;
    } else {
      return 'Unvollständig';
    }
  }

  compareInsurers(i1: Insurer, i2: Insurer): boolean {
    return i1 && i2 ? i1.id === i2.id : i1 === i2;
  }

  displayTariff(value: number): string {
    if (!value) {
      return '';
    }
    const tariff = this.tariffs.find(t => t.id === value);
    return tariff ? tariff.tarifbez : '';
  }

  setSupersededContracts(listOfSupercededContractIds: number[]) {
    this.contractGroup.get('abgeloesteVertraege')
      .setValue(listOfSupercededContractIds);
  }

  softfairTariff(): Tariff {
    if (!this.softfairInsurer()) {
      return null;
    }
    return this.getTariffsOfInsurer(this.softfairInsurer())
      .find(
        e => e.tarifbez === this.softfairContract ? this.softfairContract.tarifBezeichnung : null
      );
  }

  softfairInsurer(): Insurer {
    if (!this.allInsurers) {
      return null;
    }
    return this.allInsurers
      .find(ins => {
        if (!this.softfairContract) {
          return false;
        }
        if (ins.id === this.softfairContract.gesellschaft) {
          return true;
        }
        return ins.kurzname === this.softfairContract.gesellschaftsname;
      });
  }

  public calculateLaufzeit(start: Date, endAlter: number) {
    if (!isNil(endAlter)) {
      const retirement = moment(this.customer.geburtsdatum).add(endAlter, 'year');
      const months = retirement.diff(moment(start), 'month') + 1;
      const MONTHS_PER_YEAR = 12;
      const overhangOfYears = Math.floor(months / MONTHS_PER_YEAR);
      this.contractGroup.get('laufzeitJahre').setValue(overhangOfYears, {emitEvent: false});
      this.contractGroup.get('laufzeitMonate').setValue(months - (overhangOfYears * MONTHS_PER_YEAR), {emitEvent: false});
    }
  }

  public calculateEndAlter(laufzeitJahre: number) {
    const start = this.contractGroup.get('beginlaufzeit').value || this.contractGroup.get('antragsdatum').value;
    const age = SingleContractComponent.calculateEndAlter(laufzeitJahre,
      this.contractGroup.get('laufzeitMonate').value,
      start,
      this.customer.geburtsdatum);
    this.contractGroup.get('endAlter').setValue(age, {emitEvent: false});
  }

  addInsuredCustomer(newCustomerID: number) {
    if (!isNil(newCustomerID) && !this.versichertePersonen.controls.find(c => Number(c.value) === newCustomerID)) {
      this.versichertePersonen.push(new UntypedFormControl(newCustomerID));
    }
  }

  resetCustomerAutocompleteView() {
    if (!isNil(this.insuredCustomerIDControl.value)) {
      this.insuredCustomerIDControl.reset(null);
    }
  }

  removeInsuredCustomer(index: number) {
    this.versichertePersonen.removeAt(index);
  }

  onNewCustomer(customer: Person) {
    this.addInsuredCustomer(customer.id);
  }

  updateTarifbeitragToCalculatedValue() {
    if (this.isTarifbeitragCalculationDisabled()) {
      return;
    }
    this.contractGroup.get('tarifbeitrag').setValue(this.calculateTarifbeitrag());
  }

  calculateTarifbeitrag(): number {
    const zahlbeitragControl = this.contractGroup.get('zahlbeitrag');
    const selectedTariff = this.allTariffs.find(tariff => tariff.id === this.contractGroup.get('tarif').value);
    const versicherungssteuersatz = selectedTariff.vertragart.sparte.versicherungssteuersatz;
    if (versicherungssteuersatz) {
      return round(zahlbeitragControl.value / (versicherungssteuersatz + 100) * 100, 2);
    }
    return undefined;
  }

  isTarifbeitragCalculationDisabled(): boolean {
    const zahlbeitragControl = this.contractGroup.get('zahlbeitrag');
    const selectedTariff = this.allTariffs.find(tariff => tariff.id === this.contractGroup.get('tarif').value);
    return !zahlbeitragControl.valid || !selectedTariff || !selectedTariff.vertragart.sparte.versicherungssteuersatz;
  }

  isBwsCalculationDisabled(): boolean {
    const zahlbeitragControl = this.contractGroup.get('zahlbeitrag');
    const zahlweiseControl = this.contractGroup.get('zahlweise');
    const tarifbeitragControl = this.contractGroup.get('tarifbeitrag');
    const jahreControl = this.contractGroup.get('laufzeitJahre');
    const monateControl = this.contractGroup.get('laufzeitMonate');
    const selectedTariff = this.allTariffs.find(tariff => tariff.id === this.contractGroup.get('tarif').value);
    return !zahlbeitragControl.valid
      || !zahlweiseControl.valid
      || !tarifbeitragControl.valid
      || !selectedTariff
      || !selectedTariff.bwsSkript
      || !monateControl.valid
      || !jahreControl.valid;
  }

  isBwsSameAsCalculated(): boolean {
    const bws = Number(this.contractGroup.get('bewertungssumme').value);
    const bwsCalc = this.calculatedBws();
    return bws === bwsCalc;
  }

  isTarifbeitragSameAsCalculated(): boolean {
    return Number(this.contractGroup.get('tarifbeitrag').value) === this.calculateTarifbeitrag();
  }

  calculatedBws(): number {
    const selectedTariff = this.allTariffs.find(tariff => tariff.id === this.contractGroup.get('tarif').value);
    if (selectedTariff) {
      try {
        return round(Number(this.scriptService.run(selectedTariff.bwsSkript, {vertrag: this.contractGroup.getRawValue()})), 0);
      } catch (e) {
        console.error('Cannot calculate bws', e);
      }
    }
    return undefined;
  }

  updateBwsToCalculatedValue(): void {
    if (this.isBwsCalculationDisabled()) {
      return;
    }
    this.contractGroup.get('bewertungssumme').setValue(this.calculatedBws());
  }

  private getTariffsOfInsurer(insurer: Insurer): Tariff[] {
    return this.tariffs
      .filter(t => t.gesellschaft.id === insurer.id)
      .sort((a, b) => a.tarifbez.localeCompare(b.tarifbez, this.locale));
  }

  private filterTariffs(value): Tariff[] {
    if (value) {
      if (typeof value === 'number') {
        return this.tariffsOfInsurer.filter(tariff => tariff.id === value);
      }
      return this.tariffsOfInsurer
        .filter(tariff => tariff.tarifbez.toLowerCase().indexOf(value.toLowerCase()) === 0);
    }

    return this.tariffsOfInsurer;
  }

  private getFilteredTarifIds(tariffs: Tariff[]): { group: string, tariffIds: number[] }[] {
    const result = [];
    const favorites = tariffs.filter(t => t.favorit).map(t => t.id);
    if (favorites.length) {
      result.push({
        group: 'Favoriten',
        tariffIds: favorites
      });
    }
    const others = tariffs.filter(t => !t.favorit).map(t => t.id);
    if (others.length) {
      result.push({
        group: 'Andere',
        tariffIds: others
      });
    }
    return result;
  }

  private updateTariffSpecificFields(tariff: Tariff) {
    if (tariff) {
      const vertragart = tariff.vertragart;

      this.showBeitragzahldauer = vertragart.beitragzahldauer;
      this.contractGroup.get('laufzeitJahre').setValidators(this.showBeitragzahldauer ? [Validators.required, Validators.min(0)] : []);
      this.contractGroup.get('laufzeitMonate').setValidators(this.showBeitragzahldauer ? [Validators.required, Validators.min(0)] : []);

      this.showIdentifikationsmerkmal = vertragart.identifikationsmerkmal;
      this.showLaufzeitende = vertragart.laufzeitende;
      this.isBUContract = tariff.vertragart.buAntragVariante;
      this.isPkvAntragsqualitaet = tariff.vertragart.pkvAntragsqualitaet;
      const buAntragVariante = this.contractGroup.get('buAntragVariante');
      if (this.isBUContract) {
        // if we have a posteingang validator, we have a backoffice that needs validation - kind of hacky...
        const validate = !!this.contractGroup.get('posteingang').validator;
        buAntragVariante.setValidators(validate ? [Validators.required] : []);
      } else {
        buAntragVariante.reset();
        buAntragVariante.setValidators([]);
      }
    }
  }

  private filterVertraege(): Contract[] {
    return this.allContracts
      .filter(contract => {
        return (contract.id !== Number(this.contractGroup.value.id)
          && isNil(contract.hauptvertrag)
          && contract.id < 0
          && !contract.buendelpolice
          && !this.isBuendelpolice(contract)
          && (this.allTariffs.find(tariff => tariff.id === contract.tarif))
          && (this.allTariffs.find(tariff => tariff.id === this.contractGroup.get('tarif').value))
          && (this.allTariffs.find((tariff) => tariff.id === contract.tarif).gesellschaft.id
            === this.allTariffs.find(tariff => tariff.id === this.contractGroup.get('tarif').value).gesellschaft.id));
      });
  }

  private getHauptvertragLabel(mainContract: Contract): string {
    if (!isNil(mainContract.antragsdatum)) {
      return this.getNameFromTariff(mainContract.tarif) + ' vom ' + formatDate(mainContract.antragsdatum, 'dd.MM.yyyy', this.locale);
    }
    return this.getNameFromTariff(mainContract.tarif);
  }

  isBuendelpolice(contract: Contract) {
    return this.allContracts.some(c => c.buendelpolice === contract.id);
  }

  getDatePickerErrorMessage(controlName: string) {
    const control = this.contractGroup.get(controlName);
    if (control?.hasError('matDatepickerMin')) {
      return `Bitte wähle kein Datum vor dem ${control.getError('matDatepickerMin').min.format('DD.MM.yyyy')} aus.`;
    }
    if (control?.hasError('matDatepickerMax')) {
      return `Bitte wähle kein Datum nach dem ${control.getError('matDatepickerMax').max.format('DD.MM.yyyy')} aus.`;
    }
    return '';
  }
}
