<div [formGroup]="variables" class="flex flex-column gap-4 flex-grow-1">
  <div formGroupName="customer">
    <div formArrayName="vertraege">

      <mat-tab-group #tabGroup dynamicHeight="true" [mat-stretch-tabs]="false" *ngIf="contracts.length > 1; else singleContract">
        <mat-tab label="Übersicht">
          <div class="pt-4 flex flex-wrap gap-3 flex-grow-1">
            <ng-container *ngFor="let contract of getFilteredContracts(); let i=index">
              <app-single-contract-small [control]="contract" [tariffs]="tariffs | async" [title]="contractLabel(contract, i)"
                                         (editContract)="openTab(i + 1)" [showBuendelpoliceField]="false"
                                         class="flex mb-4 w-30rem">
              </app-single-contract-small>
            </ng-container>
          </div>
        </mat-tab>

        <mat-tab *ngFor="let contract of getFilteredContracts(); let i=index" [formGroupName]="i">
          <ng-template mat-tab-label>
            <div class="tab-label" [class.invalid]="contract.invalid">{{contractLabel(contract, i)}}</div>
          </ng-template>
          <app-single-contract-full class="p-4"
                                    [contractGroup]="contract" [tariffs]="tariffs | async"
                                    [identityTypes]="identityTypes | async"
                                    [paymentOptions]="paymentOptions | async"
                                    [antragartTypes]="antragartTypes | async"
                                    [antragEmpfaengerTypes]="antragEmpfaengerTypes | async"
                                    [buAntragVariantenTypes]="buAntragVariantenTypes | async"
                                    [pkvAntragsqualitaeten]="pkvAntragsqualitaeten | async"
                                    [customerGroup]="customerGroup"
                                    [contracts]="allContracts"
                                    [taskDefinition]="taskDefinition"
                                    (resetContract)="onResetContract(contract.value.id)">
          </app-single-contract-full>
        </mat-tab>
      </mat-tab-group>

      <ng-template #singleContract>
        <app-single-contract-full class="p-4"
                                  [contractGroup]="contracts[0]" [tariffs]="tariffs | async"
                                  [identityTypes]="identityTypes | async"
                                  [paymentOptions]="paymentOptions | async"
                                  [antragartTypes]="antragartTypes | async"
                                  [antragEmpfaengerTypes]="antragEmpfaengerTypes | async"
                                  [buAntragVariantenTypes]="buAntragVariantenTypes | async"
                                  [pkvAntragsqualitaeten]="pkvAntragsqualitaeten | async"
                                  [customerGroup]="customerGroup"
                                  [contracts]="allContracts"
                                  [taskDefinition]="taskDefinition"
                                  (resetContract)="onResetContract(contracts[0].value.id)">
        </app-single-contract-full>
      </ng-template>

    </div>
  </div>
</div>
