import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DocumentFormComponent} from '../../document-form/document-form.component';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {SingleContractComponent} from '../customer-contract/single-contract-full/single-contract-full.component';
import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {isNil} from 'lodash-es';
import {Tariff, TariffService} from '../../../services/tariff.service';
import {
  hasRequiredContractDocumentsValidator,
  hasRequiredDocumentsValidator,
  toObservable
} from './documents-validator';
import {filter, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {GotoStepAction} from '../../../actions/wizard.actions';
import {BROKER_CONTRACT} from '../../../step-definitions';
import {DocumentService} from '../../../services/document.service';
import {TaskDefinition, Variables} from '../../../../shared/common/services/task.service';
import {
  FILE_TYP_BROKER_CONTRACT,
  FILE_TYP_BROKER_CONTRACT_SUPPLEMENT
} from '../customer-broker-contract/broker-contract.component';
import {ReworkFormComponent} from '../task-reworks/rework-form/rework-form.component';
import {FileDocumentPreviewPresenter} from '../../document-file-input/document-file-input.component';
import {MatDialog} from '@angular/material/dialog';
import {DocumentPreviewComponent} from './document-preview/document-preview.component';
import {Contract, CustomerDocument, FileDocument, Person} from '@taures/angular-commons';
import {AppOptionsService} from '../../../services/app-options.service';

const CONTRACT_ID_KEY = 'contractId';

@Component({
  selector: 'app-documents-overview',
  templateUrl: './documents-overview.component.html',
  styleUrls: ['./documents-overview.component.scss']
})
export class DocumentsOverviewComponent implements OnInit, OnChanges, OnDestroy, FileDocumentPreviewPresenter {
  customerDocuments: UntypedFormArray;
  conceptDocuments: UntypedFormArray;
  documentTypes: { [key: string]: string };
  generalDocumentTypes: { [key: string]: string };
  conceptDocumentTypes: { [key: string]: string };
  conceptDocumentTypeKeys: string[];
  dropableDocumentTypeKeys: string[];
  contractDocumentsOrderedNewFirst: UntypedFormArray[];
  contractsOrderedNewFirst: UntypedFormArray;
  tariffs: Observable<Tariff[]>;
  @Input()
  taskDefinition: TaskDefinition;
  @Input()
  crmCustomer: Person;
  destroy = new Subject();

  constructor(private fb: UntypedFormBuilder,
              private store: Store<fromRoot.State>,
              private documentService: DocumentService,
              readonly tariffService: TariffService,
              readonly options: AppOptionsService,
              readonly snackbar: MatSnackBar,
              readonly dialog: MatDialog,
              readonly changeDetectorRef: ChangeDetectorRef) {

    this.documentTypes = this.options.documentTypes;
    this.conceptDocumentTypes = {
      bda: this.documentTypes.bda,
      kat: this.documentTypes.kat,
      kdl: this.documentTypes.kdl
    };
    this.conceptDocumentTypeKeys = Object.keys(this.conceptDocumentTypes);
    // Omit 'Anlage zum Maklervertrag', 'Maklervertrag'
    const {mvt, anl,  ...dropableTypes} = this.options.documentTypes;

    // Omit 'Basisdaten', 'Konzept (DynLeb)', 'Konzept (AlVo Tool)'
    const {kat, kdl, bda, ...onlyRelevantTypes} = dropableTypes;
    this.generalDocumentTypes = onlyRelevantTypes;

    this.dropableDocumentTypeKeys = Object.keys(dropableTypes);
    this.tariffs = of(this.tariffService.tariffs);
  }

  private variablesGroup: UntypedFormGroup;

  get previewPresenter() {
    return this.taskDefinition  === TaskDefinition.FollowUpBackOffice ? this : null;
  }


  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    const customerGroup = value.get('customer') as UntypedFormGroup;
    this.customerDocuments = customerGroup.get('dokumente') as UntypedFormArray;
    const contracts = customerGroup.get('vertraege') as UntypedFormArray;
    const contractId = value.get(CONTRACT_ID_KEY);
    this.contractsOrderedNewFirst = this.fb.array([]);
    // filter for all the new contracts or for the specific one given by the index
    contracts.controls.forEach((contractControl: UntypedFormGroup) => {
      const contract = contractControl.getRawValue() as Contract;
      if (!isNil(contractId)) {
        if (contract.id === contractId.value || contract.hauptvertrag === contractId.value) {
          this.contractsOrderedNewFirst.push(contractControl);
        }
      } else {
        if (contract.id < 0) {
          this.contractsOrderedNewFirst.push(contractControl);
        }
      }
    });

    // add old Contracts to the back
    contracts.controls.forEach(contract => {
      if (contract.get('id').value > 0) {
        this.contractsOrderedNewFirst.push(contract);
      }
    });

    this.contractDocumentsOrderedNewFirst = this.contractsOrderedNewFirst.controls.map(e => e.get('dokumente') as UntypedFormArray);
  }

  get defaultConceptDocumentType() {
    return Object.keys(this.conceptDocumentTypes)[0];
  }

  static buildGroup(fb: UntypedFormBuilder, taskDefinition: TaskDefinition, variables: Variables) {
    const customerDocuments = fb.array([]);
    const contracts = fb.array([]);

    const customer = variables.customer as Person;
    const reworks = variables.reworks;

    if (customer.dokumente) {
      customer.dokumente.forEach((doc: CustomerDocument) => {
        // don't validate old documents
        customerDocuments.push(DocumentFormComponent.buildGroup(fb, doc, !doc.id, !!doc.id));
      });
    }

    if (customer.vertraege) {
      customer.vertraege.forEach((contract: Contract) => {
        contracts.push(SingleContractComponent.buildGroup(fb, contract, customer, taskDefinition, false, reworks));
      });
    }

    const reworksControl = fb.array([]);
    if (variables && variables.reworks) {
      variables.reworks.forEach(rework => reworksControl.push(ReworkFormComponent.initGroup(fb, rework)));
    }

    const groupConfig = {
      reworks: reworksControl,
      customer: fb.group({
        geburtsdatum: customer.geburtsdatum,
        dokumente: customerDocuments,
        vertraege: contracts,
        kunde: fb.group({
          maklervertragDatum: customer.kunde.maklervertragDatum,
          maklervertragVersion: fb.group({
            version: customer.kunde.maklervertragVersion ? customer.kunde.maklervertragVersion.version : null,
            latestVersion: customer.kunde.maklervertragVersion ? customer.kunde.maklervertragVersion.latestVersion : null
          }),
          rvaDatum: customer.kunde.rvaDatum,
          rvaVersion: fb.group({
            version: customer.kunde.rvaVersion ? customer.kunde.rvaVersion.version : null,
            latestVersion: customer.kunde.rvaVersion ? customer.kunde.rvaVersion.latestVersion : null
          }),
          maklervollmachtDatum: customer.kunde.maklervollmachtDatum,
          maklervollmachtVersion: fb.group({
            version: customer.kunde.maklervollmachtVersion ? customer.kunde.maklervollmachtVersion.version : null,
            latestVersion: customer.kunde.maklervollmachtVersion ? customer.kunde.maklervollmachtVersion.latestVersion : null
          })
        })
      })
    };

    // add the contract id if we have one (single-contract flow)
    const contractId = variables[CONTRACT_ID_KEY];
    if (!isNil(contractId)) {
      groupConfig[CONTRACT_ID_KEY] = contractId;
    }

    return fb.group(groupConfig);
  }

  ngOnInit() {
    (this.variables.get('customer.dokumente') as UntypedFormArray).valueChanges
      .pipe(
        takeUntil(this.destroy)
      )
      .subscribe(documents => {
        this.checkKundeVersions(documents, 'rva');
        this.checkKundeVersions(documents, 'mvo');
      });
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }

  ngOnChanges() {
    if (this.crmCustomer && this.variables) {
      // load disabled concept documents from crm
      this.updateConceptDocuments();
    }
  }

  analyzeDocument(document: CustomerDocument): Observable<void> {
    const isDocumentValid = (!document.id || (document.currentProcess && this.taskDefinition === TaskDefinition.FollowUpBackOffice))
      && !!document.fileId && (document.typ === 'rva' || document.typ === 'mvo');

    if (!isDocumentValid) {
      return of(null);
    }

    const datum = document.typ === 'rva' ? this.variables.get('customer.kunde.rvaDatum')
      : this.variables.get('customer.kunde.maklervollmachtDatum');
    const version = document.typ === 'rva' ? this.variables.get('customer.kunde.rvaVersion.version')
      : this.variables.get('customer.kunde.maklervollmachtVersion.version');

    return this.documentService.downloadInboxFile(document.fileId)
      .pipe(
        mergeMap(file => this.documentService.analyzePDF(file)),
        tap(result => {
          if (!result) {
            this.snackbar
              .open(`Das Dokument '${document.filename}' konnte nicht verarbeitet werden.
              Es ist kein Datensatz zu dem QR-Code vorhanden.`,
                null,
                {duration: 5000});
            return;
          }
          // update the version of the kunde typed values
          if ((new Date(datum.value)) < result.created) {
            datum.setValue(result.created);
            version.setValue(result.version);
          }
        }),
        map(() => null)
      );
  }

  checkKundeVersions(documents: CustomerDocument[], type: 'rva' | 'mvo') {
    const datum = type === 'rva' ? this.variables.get('customer.kunde.rvaDatum')
      : this.variables.get('customer.kunde.maklervollmachtDatum');
    const version = type === 'rva' ? this.variables.get('customer.kunde.rvaVersion.version')
      : this.variables.get('customer.kunde.maklervollmachtVersion.version');

    const currentVersion = version.value;
    const validDocuments = documents
      .filter(doc => (!doc.id || (doc.currentProcess && this.taskDefinition === TaskDefinition.FollowUpBackOffice))
        && !!doc.fileId && doc.typ === type);

    // we have no document anymore - remove it
    if (!validDocuments.length && !currentVersion) {
      datum.setValue(null);
      version.setValue(null);
    }
  }

  initConceptDocuments() {
    const crmCustomerDocuments = this.crmCustomer.dokumente;
    let filteredDocuments: CustomerDocument[];
    let maxId: number;
    let maxIdDocument: CustomerDocument;
    let maxIdDocumentForm: UntypedFormGroup;
    this.conceptDocuments = new UntypedFormArray([]);
    for (const key of Object.keys(this.conceptDocumentTypes)) {
      filteredDocuments = crmCustomerDocuments
        .filter(document => document.typ === key && document.id !== null);
      if (filteredDocuments.length > 0) {
        maxId = Math.max(...Array.from(filteredDocuments, document => Number(document.id)));
        maxIdDocument = crmCustomerDocuments.find(document => Number(document.id) === maxId);
        maxIdDocumentForm = DocumentFormComponent.buildGroup(this.fb, maxIdDocument, true, true);
        this.conceptDocuments.push(maxIdDocumentForm);
      }
    }
  }

  updateConceptDocuments() {
    this.initConceptDocuments();
    let filteredDocuments: AbstractControl[];
    for (const key of Object.keys(this.conceptDocumentTypes)) {
      filteredDocuments = this.customerDocuments.controls.filter(doc => doc.get('typ').value === key && doc.get('id').value === null);
      filteredDocuments.forEach(filteredDoc => {
        this.conceptDocuments.push(filteredDoc as UntypedFormGroup);
      });
    }
  }

  getOfSameTypeCount(document: AbstractControl) {
    return this.customerDocuments.controls.filter(customerDoc => customerDoc.get('typ').value === document.get('typ').value).length;
  }

  moveCustomerDocument(i: number, event: MatSelectChange) {
    if (event.value === -1) {
      return;
    } else {
      const document = this.customerDocuments.at(i);
      if (event.value === -2) {
        document.get('typ').setValue('bda');
      } else {
        this.customerDocuments.removeAt(i);
        this.contractDocumentsOrderedNewFirst[event.value].push(document);
      }
    }
  }

  moveConceptDocument(i: number, event: MatSelectChange) {
    if (event.value === -2) {
      return;
    } else {
      const document = this.customerDocuments.at(i);
      document.get('typ').setValue(null);
      if (event.value !== -1) {
        this.customerDocuments.removeAt(i);
        this.contractDocumentsOrderedNewFirst[event.value].push(document);
      }
    }
  }

  moveContractDocument(contractIndex: number, documentIndex: number, event: MatSelectChange) {
    const document = this.contractDocumentsOrderedNewFirst[contractIndex].at(documentIndex);
    this.contractDocumentsOrderedNewFirst[contractIndex].removeAt(documentIndex);
    this.customerDocuments.push(document);
    if (event.value === -1) {
      // since this document can be of typ rva or mvo we need to analyze it
      this.analyzeCustomerDocuments([document.value]);
    } else {
      if (event.value === -2) {
        document.get('typ').setValue('bda');
      } else {
        this.contractDocumentsOrderedNewFirst[event.value].push(document);
      }
    }
  }

  gotoMvt() {
    this.store.dispatch(new GotoStepAction({step: BROKER_CONTRACT}));
    return false;
  }

  requiredCustomerDocumentTypes() {
    return toObservable(hasRequiredDocumentsValidator(this.tariffs)(this.variablesGroup.get('customer')))
      .pipe(
        filter(result => !!result),
        map(result => result.requiredDocuments)
      );
  }

  requiredContractDocumentTypes(contractIndex: number) {
    return toObservable(hasRequiredContractDocumentsValidator(this.tariffs)(this.contractsOrderedNewFirst.controls[contractIndex]))
      .pipe(map(result => {
        return result ? result.requiredDocuments : null;
      }));
  }

  shouldShowCustomerDocument(documentGroup: AbstractControl) {
    return (this.isCurrentProcessDocument(documentGroup) || !documentGroup.get('id').value)
      && documentGroup.get('typ').value !== FILE_TYP_BROKER_CONTRACT
      && documentGroup.get('typ').value !== FILE_TYP_BROKER_CONTRACT_SUPPLEMENT
      && !this.isConceptDocument(documentGroup);
  }

  isConceptDocument(documentGroup: AbstractControl) {
    return Object.keys(this.conceptDocumentTypes).indexOf(documentGroup.get('typ').value) >= 0;
  }

  isCurrentProcessDocument(documentGroup: AbstractControl) {
    const currentProcessForm = documentGroup.get('currentProcess');
    return this.taskDefinition === TaskDefinition.FollowUpBackOffice && currentProcessForm && currentProcessForm.value;
  }

  analyzeCustomerDocuments($event: CustomerDocument[]) {
    forkJoin($event.map(doc => this.analyzeDocument(doc)))
      .pipe(
        takeUntil(this.destroy)
      ).subscribe({
      error: error => {
        if (error.status === 415) {
          this.snackbar.open(`Es können nur PDF Dokumente verarbeitet werden.`, null, {duration: 5000});
        }
      }
    });
  }

  openPreview(file: FileDocument) {
    const validCustomerDocuments =
      this.customerDocuments.controls.filter(c => c.enabled);

    const validContractDocuments = this.contractDocumentsOrderedNewFirst
      .filter((cds: UntypedFormArray, index: number) => this.contractsOrderedNewFirst.controls[index].get('id').value < 0 || cds.length > 0)
      .reduce((prev, curr) => {
        return [...prev, ...curr.controls];
      }, []);

    this.dialog.open(DocumentPreviewComponent,
      {
        width: "80vw",
        data: {
          file,
          documents: [...validCustomerDocuments, ...validContractDocuments]
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy))
      .subscribe(reworks => {
        for (const rework of reworks) {
          (this.variables.get('reworks') as UntypedFormArray).push(ReworkFormComponent.initGroup(this.fb, rework));
        }
        this.changeDetectorRef.markForCheck();
      });
  }
}
