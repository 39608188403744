import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {DocumentFormComponent} from '../../../document-form/document-form.component';
import {AppOptionsService} from '../../../../services/app-options.service';
import {ContractDocument} from '@taures/angular-commons';

const DOCTYPE_ORDER = ['ant', 'anb', 'vwe', 'vaf', 'vot', 'san'];

@Component({
  selector: 'app-contract-documents',
  templateUrl: 'contract-documents.component.html',
  styleUrls: ['contract-documents.component.scss']
})
export class ContractDocumentsComponent {

  documentsFormArray: UntypedFormArray;
  documentTypes: { [key: string]: string };

  @Input()
  set documentsArray(documents: UntypedFormArray) {
    this.documentsFormArray = documents;
    const documentValues = documents.value.sort((a, b) => ContractDocumentsComponent.compareAntragArt(a.typ, b.typ));
    this.documentsFormArray.patchValue(documentValues);
  }

  get documentsArray() {
    return this.documentsFormArray;
  }

  constructor(private fb: UntypedFormBuilder,
              readonly options: AppOptionsService) {
    this.documentTypes = this.options.documentTypes;
  }

  public static buildArray(fb: UntypedFormBuilder, documents: ContractDocument[]) {
    const array = fb.array([]);
    if (documents) {
      documents.forEach(d => array.push(DocumentFormComponent.buildGroup(fb, d)));
    }

    return array;
  }

  public static compareAntragArt(type1: string, type2: string) {
    if (DOCTYPE_ORDER.indexOf(type1) < 0) {
      return 1;
    }
    if (DOCTYPE_ORDER.indexOf(type2) < 0) {
      return -1;
    }
    return DOCTYPE_ORDER.indexOf(type1) - DOCTYPE_ORDER.indexOf(type2);
  }

  onRemove(i: number) {
    this.documentsArray.removeAt(i);
  }
}
