import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-document-preview-form',
  templateUrl: './document-preview-form.component.html',
  styleUrls: ['./document-preview-form.component.scss']
})
export class DocumentPreviewFormComponent implements OnDestroy {

  file: UntypedFormControl = new UntypedFormControl();
  @Output() documentChanged: EventEmitter<void> = new EventEmitter();

  private formGroup: UntypedFormGroup;
  private destroy = new Subject<void>();

  constructor() {
  }

  get documentFormGroup(): UntypedFormGroup {
    return this.formGroup;
  }

  @Input()
  set documentFormGroup(group: UntypedFormGroup) {
    this.formGroup = group;

    // load initial pdf
    if (this.formGroup.value.fileId) {
      // copy the values and the validator in our private form control
      this.file.setValidators(group.get('fileId').validator);
      this.file.reset({
        id: group.get('id').value,
        fileId: group.get('fileId').value,
        filename: group.get('filename').value}
      );

      this.file.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(file => {
          this.formGroup.patchValue({
              fileId: file ? file.fileId : null,
              filename: file ? file.filename : null
            },
            {emitEvent: false});
          this.documentChanged.emit();
        });
    }

  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
