<div class="flex flex-column">
  <h1 mat-dialog-title>Nachbearbeitung anlegen</h1>
  <mat-dialog-content>
    <app-rework-form [control]="rework"></app-rework-form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancelDialog()">Abbrechen</button>
    <button mat-raised-button type="button" color="primary" [disabled]="!rework.valid" (click)="createRework()">Anlegen</button>
  </mat-dialog-actions>
</div>
