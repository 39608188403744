import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {COUNTRY_CODES} from './country-codes';

export interface LookupAnswer {
  adminCode2?: string;
  adminCode3?: string;
  adminName3?: string;
  adminCode1?: string;
  lng?: string;
  countryCode?: string;
  postalcode?: string;
  adminName1?: string;
  placeName?: string;
  lat?: string;
}

@Injectable()
export class PlzLookupService {
    constructor(private http: HttpClient) {
    }

    getPlaceNames(plz: string, country: string): Observable<string[]> {
        let params = new HttpParams();
        params = params.set('postal-code', plz);
        if (!!country && !!COUNTRY_CODES.find(code => country === code.code)) {
            params = params.set('country', country);
        }

        return this.http.get<any>(`/inbox/api/postal-codes`, {params})
            .pipe(
                map(items => {
                    return items.postalcodes.map(answer => {
                        return (answer as LookupAnswer).placeName;
                    });
                })
            );
    }
}
