<div class="flex flex-column">
  <div *ngIf="requiredCustomerDocumentTypes() | async as docTypes">
    <b>Vermutlich fehlende Dokumente</b>
    <ul>
      <li *ngFor="let type of docTypes">{{documentTypes[type]}} <span *ngIf="type ==='mvt'"><a href="#" (click)="gotoMvt()">anlegen</a></span></li>
    </ul>
  </div>

  <div [formGroup]="variables" class="flex flex-column gap-6">
    <div class="flex flex-column gap-2">
      <h2 class="mb-0">Allgemein</h2>
      <div formGroupName="customer" class="flex flex-column gap-2">
        <div formArrayName="dokumente" class="flex flex-wrap gap-3">
          <ng-container *ngFor="let document of customerDocuments.controls; let i=index">
            <app-document-card *ngIf="shouldShowCustomerDocument(document)"
                               [previewPresenter]="previewPresenter"
                               [documentArray]="customerDocuments"
                               [documentGroup]="document"
                               [documentTypes]="generalDocumentTypes"
                               [moveTargetForms]="contractDocumentsOrderedNewFirst"
                               [moveTargetGroup]="contractsOrderedNewFirst"
                               [tariffs]="tariffs | async"
                               (moveDocument)="moveCustomerDocument(i, $event)"
                               (documentChange)="analyzeCustomerDocuments([$event]);updateConceptDocuments()"></app-document-card>
          </ng-container>
        </div>
        <div *ngIf="customerDocuments.controls.length === 0">
          <h4 class="empty">Keine allgemeinen Dokumente.</h4>
        </div>
        <app-documents-dropzone [documentArray]="customerDocuments"
                                [validDocumentTypes]="dropableDocumentTypeKeys"
                                (fileDropped)="analyzeCustomerDocuments($event);updateConceptDocuments()"></app-documents-dropzone>
      </div>
    </div>

    <div class="flex flex-column gap-2">
      <h2>Konzeptdokumente</h2>
      <div formGroupName="customer" class="flex flex-column gap-2">
        <div formArrayName="dokumente" class="flex flex-wrap gap-3">
          <ng-container *ngFor="let document of customerDocuments.controls; let i=index">
            <app-document-card *ngIf="isConceptDocument(document)"
                               [previewPresenter]="previewPresenter"
                               [documentArray]="customerDocuments"
                               [documentGroup]="document"
                               [documentTypes]="conceptDocumentTypes"
                               [sameTypeCount]="getOfSameTypeCount(document)"
                               [moveTargetForms]="contractDocumentsOrderedNewFirst"
                               [moveTargetGroup]="contractsOrderedNewFirst"
                               [tariffs]="tariffs | async"
                               (moveDocument)="moveConceptDocument(i, $event)"
                               (removeDocument)="updateConceptDocuments()"></app-document-card>
          </ng-container>
        </div>
        <app-documents-dropzone [documentArray]="customerDocuments"
                                [defaultType]="defaultConceptDocumentType"
                                [validDocumentTypes]="dropableDocumentTypeKeys"
                                (fileDropped)="analyzeCustomerDocuments($event);updateConceptDocuments()"
                                (addDocument)="updateConceptDocuments()"></app-documents-dropzone>

        <div *ngIf="conceptDocuments.controls.length === 0">
          <h4 class="empty">Keine Konzeptdokumente.</h4>
        </div>
      </div>
    </div>

    <div *ngFor="let documents of contractDocumentsOrderedNewFirst; let i=index" class="flex flex-column gap-6">
      <div *ngIf="contractsOrderedNewFirst.controls[i].get('id').value < 0 || documents.length > 0" class="flex flex-column gap-2">
        <h2>Vertrag:
          <app-contract-documents-description [contractForm]="contractsOrderedNewFirst.controls[i]" [tariffs]="tariffs | async"></app-contract-documents-description>
        </h2>

        <div *ngIf="requiredContractDocumentTypes(i) | async as docTypes">
          <b>Vermutlich fehlende Dokumente</b>
          <ul>
            <li *ngFor="let type of docTypes">{{documentTypes[type]}}</li>
          </ul>
        </div>
        <div class="flex flex-wrap gap-3">
          <app-document-card *ngFor="let document of documents.controls; let j=index"
                             [previewPresenter]="previewPresenter"
                             [documentArray]="contractDocumentsOrderedNewFirst[i]"
                             [documentGroup]="document"
                             [documentTypes]="generalDocumentTypes"
                             [moveTargetForms]="contractDocumentsOrderedNewFirst"
                             [moveTargetGroup]="contractsOrderedNewFirst"
                             [tariffs]="tariffs | async"
                             (moveDocument)="moveContractDocument(i, j, $event)"></app-document-card>
        </div>
        <div *ngIf="documents.controls.length === 0">
          <h4 class="empty">Keine Dokumente in diesem Vertrag.</h4>
        </div>

        <app-documents-dropzone [documentArray]="contractDocumentsOrderedNewFirst[i]"></app-documents-dropzone>
      </div>
    </div>
  </div>
</div>
