import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {cache} from '@taures/angular-commons';

export interface UserGroup {
  id?: string;
  bezeichnung: string;
  antragart: string;
  mitglieder: string[];
  anzahlVps?: number;
}

@Injectable()
export class GroupsService {
  readonly groupsURL = '/t-it-s/rest/crm/v1.0/vugruppe';

  constructor(private http: HttpClient) {
  }

  static isPapierantraege(group: UserGroup): boolean {
    return group.antragart === 'p';
  }

  private static sortGroupsAlphabetically(groups: UserGroup[]) {
    groups.sort((a, b) => {
      if (a.bezeichnung.toLowerCase() > b.bezeichnung.toLowerCase()) {
        return 1;
      }
      if (a.bezeichnung.toLowerCase() < b.bezeichnung.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return groups;
  }

  getGroups(skipCache: boolean = false): Observable<UserGroup[]> {
    return (this.http.get<UserGroup[]>(this.groupsURL))
      .pipe(
        map(groups => GroupsService.sortGroupsAlphabetically(groups)),
        catchError((error) => {
          if (error.status === 401 || error.status === 403) {
            return of([]);
          }
          return throwError(error);
        }),
        cache('groups', skipCache ? -1 : undefined)
      );
  }

  addGroup(newGroup: UserGroup): Observable<UserGroup> {
    return this.http.post<UserGroup>(this.groupsURL, newGroup);
  }

  updateGroup(newGroup: UserGroup): Observable<void> {
    return this.http.put<void>(this.groupsURL + '/' + newGroup.id, newGroup);
  }

  deleteGroup(id: string): Observable<void> {
    return this.http.delete<void>(this.groupsURL + '/' + id);
  }
}
