<div class="mat-elevation-z2 border-4px overflow-hidden flex flex-column flex-grow-1" [formGroup]="control">
  <div class="p-2 taures-secondary-blue flex justify-content-between">
    <div>Beziehung</div>
    <div>{{isFromServer ? 'Bestand' : 'Neu'}}</div>
  </div>
  <div class="p-4">
    <div class="flex flex-column gap-2">
      <mat-form-field>
        <mat-label>Typ</mat-label>
        <mat-select [formControl]="relType" [compareWith]="compareWith" required>
          <mat-option *ngFor="let ro of relationshipOptions;" [value]="ro.value">
            <span>{{ ro.label }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex gap-2">
        <tr-user-autocomplete placeholder="Name" [mode]="'customer'" [ignore]="[customerId]" [formControl]="user" required class="flex-grow-1">
        </tr-user-autocomplete>
        <app-create-customer (newCustomer)="onNewCustomer($event)" [addresses]="isSameHousehold ? customerAddresses : null"></app-create-customer>
      </div>
      <mat-checkbox [checked]="isSameHousehold" (change)="changeSameHousehold($event)" [disabled]="disableSameHousehold()">in häuslicher Gemeinschaft</mat-checkbox>
    </div>

    <div class="flex justify-content-end">
      <button type="button" mat-icon-button (click)="deleteCard()" matTooltip="Beziehung löschen">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
