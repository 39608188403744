import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BrokerContractOption} from '../../../../services/app-options.service';

@Component({
  selector: 'app-broker-contract-option',
  templateUrl: './broker-contract-option.component.html',
  styleUrls: ['./broker-contract-option.component.scss']
})
export class BrokerContractOptionComponent implements OnInit {

  @Input()
  control: UntypedFormGroup;
  @Input()
  options: BrokerContractOption[];
  filteredOptions: Observable<BrokerContractOption[]>;
  brokerContractFilterCtrl: UntypedFormControl = new UntypedFormControl();

  constructor() {
  }

  ngOnInit() {
    // listen for search field value changes
    this.filteredOptions = this.brokerContractFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterOptions(value))
      );
  }

  protected filterOptions(search: string) {
    if (!this.options) {
      return [];
    }
    if (!search) {
      return this.options.slice();
    } else {
      search = search.toLowerCase();
    }
    return this.options.filter(option => option.thema.toLowerCase().indexOf(search) > -1);
  }
}
