<app-data-comparison-card title="Familienstand" [crmEntity]="isFromServer">
  <div class="flex flex-column gap-2" [formGroup]="control" tr-data-comparison-card-content>
    <mat-form-field>
      <mat-label>Familienstand</mat-label>
      <mat-select formControlName="familienstand" required>
        <mat-option *ngFor="let key of maritalStatusTypeKeys; let i = index;" [value]="key">
          <span>{{ maritalStatusOptions[key] }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Seit</mat-label>
      <input matInput [matDatepicker]="sincePicker" formControlName="gueltigAb">
      <mat-datepicker-toggle matSuffix [for]="sincePicker"></mat-datepicker-toggle>
      <mat-datepicker #sincePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="flex justify-content-end" tr-data-comparison-card-buttons>
    <button type="button" mat-icon-button (click)="deleteCard()" matTooltip="Familienstand löschen" [disabled]="isFromServer || disableRemove()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

</app-data-comparison-card>
