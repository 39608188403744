import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {environment} from '../../environments/environment';
import {TariffService} from './tariff.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {forkJoin} from 'rxjs';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';
import {AppOptionsService} from './app-options.service';

@Injectable()
export class AppInitService {

  constructor(readonly oauthService: OAuthService,
              readonly optionsService: AppOptionsService,
              readonly tariffService: TariffService,
              @Inject(LOCALE_ID) private locale: string) {
  }

  init() {

    this.oauthService.configure({
      issuer: environment.oauth.issuer,
      redirectUri: window.location.origin + window.location.pathname,
      clientId: environment.oauth.clientId,
      scope: environment.oauth.scope,
      responseType: environment.oauth.responseType,
      disablePKCE: environment.oauth.disablePKCE,
      timeoutFactor: 0.4
    });
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    return new Promise<void>((resolveFn, rejectFn) => {
      // continue initializing app (provoking a token_received event) or redirect to login-page
      this.oauthService.loadDiscoveryDocumentAndLogin({state: window.location.search}).then(isLoggedIn => {
        if (isLoggedIn) {
          if (this.oauthService.state) {
            window.location.search = decodeURIComponent(this.oauthService.state);
          }
          this.oauthService.setupAutomaticSilentRefresh();
          forkJoin([
            this.optionsService.load(),
            this.tariffService.load()
          ]).toPromise().then(() => resolveFn(), (error) => rejectFn(error));
        } else {
          rejectFn();
        }
      });
    });
  }
}

export function AppInitServiceFactory(service: AppInitService): () => Promise<any> {
  return () => service.init();
}
