<h1 mat-dialog-title>Verlauf</h1>

<mat-dialog-content>
  <div *ngIf="historyItems | async as items; else initiatorLoading" class="flex flex-column gap-3">
    <app-single-history-item *ngFor="let historyItem of items" [historyItem]="historyItem"></app-single-history-item>
    <div *ngIf="items.length === 0">
      <i>Keine Einträge vorhanden</i>
    </div>
  </div>
  <ng-template #initiatorLoading>
    <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button type="button" color="primary" (click)="closeDialog()">Schließen</button>
</mat-dialog-actions>
