<h1 mat-dialog-title>Aufgabe zuweisen</h1>
<mat-dialog-content class="flex flex-column gap-4">
  <div class="flex flex-column gap-3">

    <label>Zugewiesene Benutzer:</label>
    <mat-chip-set *ngIf="assignedPersonIds">
      <mat-chip *ngFor="let personId of assignedPersonIds; let i=index" class="chip"
                matTooltip="{{personId | personName: true | async}} entfernen">
        {{personId | personName: true | async}}
        <mat-icon matChipRemove (click)="removeAssignedUser(i)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-set>
    <div *ngIf="assignedPersonIds && assignedPersonIds.length === 0">
      <i>Kein Benutzer zugewiesen</i>
    </div>
  </div>

  <tr-user-autocomplete placeholder="Benutzer suchen"
                         [additionalFilter]="userFilter"
                         [mode]="'user'"
                         [formControl]="assignedUserIDControl">
  </tr-user-autocomplete>


  <ng-container *ngIf="isBackOffice">

    <label>Zugewiesene Gruppen:</label>
    <mat-chip-set *ngIf="assignedGroups.length > 0">
      <mat-chip *ngFor="let group of assignedGroups; let i=index" class="chip"
                matTooltip="{{group?.bezeichnung}} entfernen">
        {{group?.bezeichnung}}
        <mat-icon matChipRemove (click)="removeAssignedGroup(i)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-set>
    <div *ngIf="assignedGroups.length === 0">
      <i>Keine Gruppe zugewiesen</i>
    </div>

    <mat-form-field>
      <mat-label>Gruppe suchen</mat-label>
      <input type="text" matInput [formControl]="assignedGroupIDControl" [matAutocomplete]="groupAutocomplete" #groupSearch>
      <mat-autocomplete #groupAutocomplete="matAutocomplete">
        <mat-option *ngFor="let group of filteredGroups" [value]="group">
          {{ group.bezeichnung }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </ng-container>


  <mat-form-field>
    <mat-label>Kommentar</mat-label>
    <textarea #comment matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="10"></textarea>
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button type="button" (click)="closeDialog()">Abbrechen</button>
  <button mat-raised-button type="button" (click)="assignTask(comment.value)" color="primary">Zuweisen</button>
</mat-dialog-actions>
