import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {Step} from '../../step-definitions';
import {animate, animation, keyframes, state, style, transition, trigger, useAnimation} from '@angular/animations';
import {Task} from '../../../shared/common/services/task.service';
import {stepsOfTask} from '../../reducers/wizard';
import {WizardFormService} from '../../services/wizard-form.service';
import {Person} from '@taures/angular-commons';

export const shake = animation(
  animate(
    '{{ timing }}s {{ delay }}s',
    keyframes([
      style({transform: 'translate3d(0, 0, 0)', offset: 0}),
      style({transform: 'translate3d(-10px, 0, 0)', offset: 0.1}),
      style({transform: 'translate3d(10px, 0, 0)', offset: 0.2}),
      style({transform: 'translate3d(-10px, 0, 0)', offset: 0.3}),
      style({transform: 'translate3d(10px, 0, 0)', offset: 0.4}),
      style({transform: 'translate3d(-10px, 0, 0)', offset: 0.5}),
      style({transform: 'translate3d(10px, 0, 0)', offset: 0.6}),
      style({transform: 'translate3d(-10px, 0, 0)', offset: 0.7}),
      style({transform: 'translate3d(10px, 0, 0)', offset: 0.8}),
      style({transform: 'translate3d(-10px, 0, 0)', offset: 0.9}),
      style({transform: 'translate3d(0, 0, 0)', offset: 1}),
    ])
  ),
  {params: {timing: 1, delay: 0}}
);

@Component({
  selector: 'app-task-steps-control',
  templateUrl: './task-steps-control.component.html',
  styleUrls: ['./task-steps-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  animations: [
    trigger('attention', [
      transition('false => true', useAnimation(shake))
    ]),
    trigger('stepState', [
      state('inactive', style({
        backgroundColor: '#4C719C',
        color: 'white'
      })),
      state('inactive-can-skip', style({
        backgroundColor: '#9E9E9E',
        color: 'white'
      })),
      state('active', style({
        backgroundColor: '#003572',
        color: 'white'
      })),
      state('invalid', style({
        backgroundColor: 'white',
        color: '#f44336'
      })),
      transition('void => *, invalid => *, * => invalid', []),
      transition('* <=> *', animate('500ms'))
    ])]
})
export class TaskStepsControlComponent implements OnChanges {
  @Input() task: Task;
  @Input() currentStep: Step;
  @Input() customer: Person;
  @Output()
  openStep = new EventEmitter<Step>();
  private shake = false;

  constructor(readonly formService: WizardFormService, readonly changeDetector: ChangeDetectorRef) {
  }

  private taskSteps: { step: Step, valid: boolean, canSkip: boolean }[];

  get steps() {
    return this.taskSteps;
  }

  stateOfStep(value: { step: Step, valid: boolean, canSkip: boolean }) {
    if (value.step === this.currentStep) {
      return 'active';
    }
    if (!value.valid) {
      return 'invalid';
    }
    if (value.canSkip) {
      return 'inactive-can-skip';
    }
    return 'inactive';
  }

  seekAttention(valid: boolean) {
    if (valid) {
      return false;
    }
    return this.shake;
  }

  attentionDone() {
    this.shake = false;
    this.changeDetector.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.task && this.customer && (changes.task || changes.customer)) {
      const taskChange = changes.task;
      if (!this.steps || (taskChange && taskChange.currentValue.formKey !== taskChange.previousValue.formKey)) {
        this.taskSteps = stepsOfTask(this.task)
          .map(s => {
            const canSkip = s.canSkip(this.task, this.customer);
            const group = this.formService.buildStepGroup(s, this.task.formKey, this.task.variables, this.customer);
            return {step: s, canSkip, valid: group.valid};
          });
      } else {
        this.taskSteps.forEach(v => {
          v.canSkip = v.step.canSkip(this.task, this.customer);
          let formGroup = this.formService.buildStepGroup(v.step, this.task.formKey, this.task.variables, this.customer);
          v.valid = formGroup.valid;
          if (!v.valid) {
            console.log(formGroup.errors)
          }
        });
      }
    }
  }

  highlightInvalid() {
    this.shake = !this.shake;
    this.changeDetector.markForCheck();
  }
}
