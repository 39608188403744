<div class="flex gap-3 align-items-center" (click)="dismiss()">
  <div>
    <mat-icon color="primary" class="tr-wizard-complete-success-icon">done_outline</mat-icon>
  </div>
  <div>Der Prozess '{{data.name}}' wurde erfolgreich abgeschlossen.
    <span *ngIf="data.formKey === taskDefinition.FollowUpAgent">
      <br/>Dieser Vorgang kann nicht weiter bearbeitet werden, er verschwindet aus dem Dashboard und wird der zuständigen Stelle übergeben.
    </span>
  </div>
</div>
