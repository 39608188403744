import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {UPDATE_CANDIDATE_IDS, UpdateCandidatesIdsAction} from '../actions/task.actions';
import {TaskService} from '../../shared/common/services/task.service';
import {forkJoin} from 'rxjs';

@Injectable()
export class TaskEffects {

  setCandidates = createEffect(() => this.actions
    .pipe(
      ofType<UpdateCandidatesIdsAction>(UPDATE_CANDIDATE_IDS),
      map(action => action.payload),
      mergeMap(payload =>
        forkJoin(
          [this.taskService.updateCandidateUsers(payload.taskID, payload.userIDs),
          this.taskService.updateCandidateGroups(payload.taskID, payload.groupIDs),
          this.taskService.addComment(payload.taskID, payload.comment)]
        ).pipe(catchError(() => null))
      )
    ), {dispatch: false});

  constructor(private actions: Actions,
              private taskService: TaskService) {
  }
}

