import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {initialState} from '../../reducers/inbox';
import {FilterModel, TaskOrder} from '../../../shared/common/services/task.service';

@Injectable()
export class FilterModelResolver  {


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FilterModel> | undefined {
    if (route.queryParamMap.keys.length > 0) {
      const filter: FilterModel = initialState.filter;
      route.queryParamMap.keys.forEach(key => {
        const value = route.queryParamMap.get(key);
        if (!value || value.length === 0) {
          return;
        }
        if (key === 'createdBefore') {
          filter[key] = new Date(value);
          filter[key].setUTCHours(23, 59, 59, 999);
        } else if (key === 'createdAfter') {
          filter[key] = new Date(value);
        } else if (key === 'orderBy') {
          filter[key] = TaskOrder[value];
        } else if (!Number.isNaN(Number(value))) {
          filter[key] = Number(value);
        } else if (value === 'true' || value === 'false') {
          filter[key] = value === 'true';
        } else {
          filter[key] = value;
        }
      });
      return of(filter);
    }
    return undefined;
  }
}
