import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {StartWizardAction} from '../../actions/wizard.actions';
import {isValidStep} from '../../reducers/wizard';
import {TaskService} from '../../../shared/common/services/task.service';
import {Step, VALID_STEPS} from '../../step-definitions';
import {catchError, defaultIfEmpty, filter, map, switchMap, take, tap} from 'rxjs/operators';

@Injectable()
export class TaskWizardGuardService  {

  constructor(private readonly store: Store<fromRoot.State>,
              private readonly inboxService: TaskService,
              private readonly router: Router) {
  }

  private static toStep(slug: string) {
    return VALID_STEPS.find(step => step.slug === slug);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.hasTaskAndValidStep(route.params.taskId, TaskWizardGuardService.toStep(route.params.step))
      .pipe(
        tap(canActivate => {
          if (!canActivate) {
            // go back to the inbox if this route is not valid
            this.router.navigate(['/']);
          }
        }));
  }

  private hasTaskAndValidStep(id: string, step: Step): Observable<boolean> {
    // there is no step - stop
    if (!step) {
      return of(false);
    }
    // check if the wizard is already started with the given task and step
    return this.store.pipe(
      select(fromRoot.getWizardState),
      take(1),
      map(state => state.task && state.task.id === id && state.currentStep === step),
      switchMap(alreadyStarted => {
        if (alreadyStarted) {
          return of(true);
        }
        return this.hasTaskInApi(id, step);
      }));
  }

  /**
   * Loads the task and checks if the given step can be used with the task,
   * if yes, start the wizard - otherwise stop
   */
  private hasTaskInApi(id: string, step: Step): Observable<boolean> {
    return this.inboxService.getTask(id).pipe(
      filter(task => task && isValidStep(task, step)),
      map(task => new StartWizardAction({task, step})),
      tap(action => this.store.dispatch(action)),
      map(action => !!action),
      defaultIfEmpty(false),
      catchError(() => {
        return of(false);
      }));
  }
}
