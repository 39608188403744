import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take, takeWhile, withLatestFrom} from 'rxjs/operators';
import {isNil} from 'lodash-es';

import {ClaimTaskAction, CreateNewProcessAction, LoadTasksAction} from '../../actions/inbox';
import * as fromRoot from '../../reducers';
import {FilterModel, Task, TaskDefinition, TaskOrder} from '../../../shared/common/services/task.service';
import {CreateProcessComponent} from '../create-process-dialog/create-process.component';
import {Title} from '@angular/platform-browser';
import {ConfirmUnclaimTaskAction} from '../../actions/wizard.actions';
import {KeycloakTokenService, UserService} from '@taures/angular-commons';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-inbox-overview',
  templateUrl: 'inbox-overview.component.html',
  styleUrls: ['inbox-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InboxOverviewComponent implements OnInit, OnDestroy {
  tasks: Observable<Task[]>;
  filter: Observable<FilterModel>;
  paging: Observable<{ total: number, start: number, stop: number }>;
  pageSizeOptions = [5, 10, 25];
  isAllowedToCreateProcess = false;
  currentUserId: Observable<number>;
  taskDefinitionEnum = TaskDefinition;
  taskOrder = TaskOrder;
  alive = true;

  constructor(private store: Store<fromRoot.State>,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private titleService: Title,
              private keycloak: KeycloakTokenService,
              private userService: UserService) {
    this.titleService.setTitle('Posteingang - TauRes');
  }

  ngOnInit(): void {
    this.isAllowedToCreateProcess = this.keycloak.hasRole('consultant', environment.roleResourceId)
      || this.keycloak.hasRole("admin", environment.roleResourceId);
    this.filter = this.store.pipe(select(fromRoot.getFilter));
    this.route.data.pipe(
      takeWhile(() => this.alive),
      withLatestFrom(this.filter)
    )
      .subscribe(([data, storeFilter]: [{ filterModel: FilterModel }, FilterModel]) => {
        // restore filter state from store if no filter from url present
        this.filterChanged(data.filterModel || storeFilter);
      });
    this.tasks = this.store.pipe(select(fromRoot.getTasks));
    this.paging = this.store.pipe(select(fromRoot.getPaging));
    this.currentUserId = this.userService.getCurrentUser().pipe(map(user => user.personId));
    this.store.dispatch(new LoadTasksAction());
  }

  ngOnDestroy() {
    this.alive = false;
  }


  pageIndex(page: { total: number, start: number, stop: number }, filterModel: FilterModel) {
    if (!page || page.start === 0) {
      return 0;
    }
    return Math.floor(page.start / filterModel.limit);
  }

  filterChanged(updatedFilter?: FilterModel): void {
    this.store.dispatch(new LoadTasksAction({...updatedFilter, skip: undefined}));
  }

  onPagingChanged(event: PageEvent) {
    this.filter.pipe(take(1)).subscribe(filter => {
      filter.limit = event.pageSize;
      filter.skip = event.pageIndex * filter.limit;
      this.store.dispatch(new LoadTasksAction(filter));
    });
  }

  createTaskDialog() {
    this.dialog.open(CreateProcessComponent, {width: '448px'})
      .afterClosed()
      .subscribe(result => {
        if (!isNil(result)) {
          this.store.dispatch(new CreateNewProcessAction(result));
        }
      });
  }

  onClaimTask(task: Task) {
    this.store.dispatch(new ClaimTaskAction(task));
  }

  onUnclaimTask(task: Task) {
    this.store.dispatch(new ConfirmUnclaimTaskAction({taskId: task.id}));
  }

  onTransferedCustomer() {
    this.filter.pipe(take(1)).subscribe(filter => {
      this.store.dispatch(new LoadTasksAction(filter));
    });
  }

  changeOrder(event: MatSelectChange) {
    this.filter.pipe(take(1)).subscribe(filter => {
      filter.orderBy = event.value;
      this.filterChanged(filter);
    });
  }
}
