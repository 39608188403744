<div class="flex gap-3 w-full justify-content-between">
  <div class="flex flex-grow-1 flex-column gap-3">
    <mat-checkbox *ngIf="isBackOfficeTask()" [formControl]="variables.get('customer.kunde.konzeptdoku')">Konzeptdokumentation</mat-checkbox>
    <div [formGroup]="variables" class="flex flex-column gap-3">
      <div [formGroup]="customer">
        <div formArrayName="ueberschuss" class="flex flex-wrap gap-3 flex-grow-1">
          <app-single-surplus (deleteContact)="removeSurplus(i)"
                              *ngFor="let surplus of surpluses.controls | notDeleted; let i=index"
                              [formGroupName]="i"
                              [group]="surplus"
                              class="item flex w-27rem">
          </app-single-surplus>
        </div>
      </div>
      <button (click)="addSurplus()" style="color: white" mat-fab matTooltip="Überschuss hinzufügen" type="button">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <app-document-preview-form (documentChanged)="documentChanged()"
                             [documentFormGroup]="previewDocument"
                             class="flex flex-shrink-0 w-6" style="min-height: 80vh">
  </app-document-preview-form>
</div>
