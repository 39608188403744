import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Tariff, TariffService} from '../../../../services/tariff.service';
import {TauresValidators} from '../../../../taures-validators';
import {Address, Contract, Status} from '@taures/angular-commons';
import {PlzLookupService} from '../../../../services/plz-lookup.service';
import {COUNTRY_CODES, GERMANY_COUNTRY_CODE} from '../../../../services/country-codes';


@Component({
  selector: 'app-single-addresse-full',
  templateUrl: 'single-addresse-full.component.html',
  styleUrls: ['single-addresse-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleAddresseFullComponent implements OnDestroy, OnInit, OnChanges {
  @Output() deleteContact: EventEmitter<any> = new EventEmitter();
  @Output() setAsPrime: EventEmitter<any> = new EventEmitter();
  @Output() setAsMeldeAnschrift: EventEmitter<any> = new EventEmitter();
  @Input() addressCount: number;
  @Input() contracts: Contract[];
  @Input() originalAddress: Address;
  sortedCountries: { code: string, name: string }[] = COUNTRY_CODES;
  @ViewChild('countryAutocomplete', { static: true }) countryAutocomplete: MatAutocomplete;
  public placeNames: Observable<string[]>;
  tariffs: Tariff[];

  private destroy: Subject<boolean> = new Subject();

  constructor(private changeDetector: ChangeDetectorRef,
              private plzLookup: PlzLookupService,
              private tariffService: TariffService) {
  }

  private group: UntypedFormGroup;

  get control() {
    return this.group;
  }

  @Input()
  set control(value: UntypedFormGroup) {
    this.group = value;
    if (this.group.get('status') && this.isFromServer) {
      this.group.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => this.group.get('status').setValue(Status.MODIFIED, {emitEvent: false}));
    }
    this.group.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => this.changeDetector.detectChanges());
    this.group.get('land').valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => this.changeLand());
    this.group.get('plz').valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => this.getPlaceNames());
  }

  get colorClass(): string {
    return this.isPrimary ? 'header taures-primary-blue' : 'header taures-secondary-blue';
  }

  get isFromServer(): boolean {
    return !!this.control.controls.id.value;
  }

  get isPrimary(): boolean {
    return !!this.control.controls.postanschrift.value;
  }

  public static initGroup(fb: UntypedFormBuilder, address: Address, addressLength: number, originalAddress: Address, validation: boolean = true) {
    const adressecoValidators = [];
    const strasseValidators = [];
    const nummerValidators = [];
    const plzValidators = [];
    const stadtValidators = [];
    const landValidators = [];
    const gueltigAbValidators = [];
    const gueltigBisValidators = [];
    const aenderungsauftragValidators = [];
    const addressGroupValidators = [];
    if (!address.id) {
      strasseValidators.push(Validators.required);
      nummerValidators.push(Validators.required);
      plzValidators.push(Validators.required);
      stadtValidators.push(Validators.required);
      landValidators.push(Validators.required);
      gueltigAbValidators.push(Validators.required);
      gueltigBisValidators.push(Validators.required);
    }
    // either new address or new postanschrift
    if ((!address.id && addressLength > 1) || (address.id && originalAddress && !originalAddress.postanschrift && address.postanschrift)) {
      aenderungsauftragValidators.push(Validators.required);
      addressGroupValidators.push(TauresValidators.aenderungsauftragVertragRequired);
    }

    address.land = !address.id && !address.land ? GERMANY_COUNTRY_CODE : address.land;

    return fb.group({
      id: [address.id],
      status: [address.status],
      adresseco: [{value: address.adresseco, disabled: !!address.id}, validation ? adressecoValidators : []],
      strasse: [{value: address.strasse, disabled: !!address.id}, validation ? strasseValidators : []],
      nummer: [{value: address.nummer, disabled: !!address.id}, validation ? nummerValidators : []],
      plz: [{value: address.plz, disabled: !!address.id}, validation ? plzValidators : []],
      stadt: [{value: address.stadt, disabled: !!address.id}, validation ? stadtValidators : []],
      land: [{value: address.land, disabled: !!address.id}, validation ? landValidators : []],
      meldeanschrift: [{value: address.meldeanschrift, disabled: false}],
      geschaeftsanschrift: [{value: address.geschaeftsanschrift, disabled: !!address.id}],
      postanschrift: [{value: address.postanschrift, disabled: false}],
      aenderungsauftrag: [{value: address.aenderungsauftrag, disabled: false}, aenderungsauftragValidators],
      aenderungsauftragVertraege: [{value: address.aenderungsauftragVertraege, disabled: false}],
      bemerkung: [{value: address.bemerkung, disabled: false}],
      gueltigAb: [{value: address.gueltigAb, disabled: !!address.id}, validation ? gueltigAbValidators : []],
      gueltigBis: address.gueltigBis,
      zusatzdaten: address.zusatzdaten
    }, {validators: addressGroupValidators});
  }

  ngOnInit(): void {
    this.tariffs = this.tariffService.tariffs;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contracts && changes.contracts.currentValue !== null && changes.contracts.currentValue.length === 0) {
      this.control.get('aenderungsauftrag').setValue('NO');
    }
  }

  getPlaceNames() {
    const plz = this.group.get('plz');
    const country = this.group.get('land');
    if (plz.valid) {
      this.placeNames = this.plzLookup.getPlaceNames(plz.value, country.value);
      this.placeNames.pipe(takeUntil(this.destroy)).subscribe(placeNames => {
        if (placeNames.length === 1) {
          this.group.get('stadt').setValue(placeNames[0]);
        }
      });
    } else {
      this.placeNames = new Observable<string[]>(observer => {
        observer.next([]);
      });
    }
  }

  changeLand() {
    if (this.control.get('land').value === GERMANY_COUNTRY_CODE) {
      this.control.get('plz').setValidators(
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]*$/)
        ]
      );
    } else {
      this.control.get('plz').setValidators([Validators.required]);
    }
    this.control.get('plz').updateValueAndValidity();
    this.getPlaceNames();
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  displayCountry(code: string): string {
    const country = COUNTRY_CODES.find(c => c.code === code);
    return country ? country.name : '';
  }

  deleteCard() {
    this.deleteContact.emit(this.control);
  }

  setCardAsPrimary() {
    this.control.get('gueltigAb').setValue(new Date());
    this.control.get('aenderungsauftrag').setValidators(Validators.required);
    this.control.setValidators(TauresValidators.aenderungsauftragVertragRequired);
    this.setAsPrime.emit(this.control);
  }

  meldeAnschriftChanged() {
    this.control.get('gueltigAb').setValue(new Date());
    this.setAsMeldeAnschrift.emit(this.control);
  }

  private filterCountry(value): { code: string, name: string }[] {
    return COUNTRY_CODES.filter(option =>
      option.name.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }

  showAenderungsauftrag() {
    return this.contracts && this.contracts.length > 0 && ((this.control.get('id').value === null && this.addressCount > 1) ||
        (this.originalAddress && !this.originalAddress.postanschrift && this.control.get('postanschrift').value));
  }

  showAenderungsauftragVertraege() {
    return this.showAenderungsauftrag() && this.control.get('aenderungsauftrag').value === 'YES';
  }

  setAenderungsauftragVertraege(contractIdList: number[]) {
    this.control.get('aenderungsauftragVertraege').setValue(contractIdList);
  }
}
