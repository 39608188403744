import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Observable, of, Subject} from 'rxjs';
import {isNil} from 'lodash-es';


import * as fromRoot from '../../reducers';
import {FilterModel} from '../../../shared/common/services/task.service';
import {takeUntil} from 'rxjs/operators';
import {Category, TariffService} from '../../services/tariff.service';
import {KeycloakTokenService, UserService} from '@taures/angular-commons';
import {GroupsService, UserGroup} from '../../../groups/services/groups.service';
import {AppOptionsService, TaskType} from '../../services/app-options.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit, OnDestroy {
  filterForm: UntypedFormGroup;
  taskStates = [{label: 'In Bearbeitung', value: true},
    {label: 'Nicht in Bearbeitung', value: false}];

  taskTypes: TaskType[];

  @Output()
  changeFilter: EventEmitter<FilterModel> = new EventEmitter();

  groups: Observable<UserGroup[]>;
  isBackOffice = this.keycloak.hasRole('backoffice', environment.roleResourceId);
  isAdmin = this.keycloak.hasRole('admin', environment.roleResourceId);
  contractCategories: Observable<Category[]>;
  destroy = new Subject<void>();

  constructor(readonly fb: UntypedFormBuilder,
              readonly store: Store<fromRoot.State>,
              readonly tariffService: TariffService,
              readonly appOptions: AppOptionsService,
              readonly groupService: GroupsService,
              readonly userService: UserService,
              readonly keycloak: KeycloakTokenService) {
    this.contractCategories = of(
      this.tariffService.tariffs
        .filter(t => t.vertragart)
        .reduce((prev: Category[], tariff) => {
          if (!prev.find(s => s.id === tariff.vertragart.sparte.id)) {
            prev.push(tariff.vertragart.sparte);
          }
          return prev;
        }, [])
        .sort((a, b) => a.bezeichnung.localeCompare(b.bezeichnung))
    );
    this.groups = this.groupService.getGroups();
    this.taskTypes = this.appOptions.taskTypes;
  }

  private filterModel: FilterModel;

  @Input()
  set filter(value: FilterModel | null) {
    this.filterModel = value;
    this.applyValues();
  }

  ngOnInit(): void {
    this.createForm();
    this.applyValues();
    this.filterForm.get('candidateUser').valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(
        () => this.onCandidateUserChange()
      );
    this.filterForm.get('candidateGroup').valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(
        () => this.onCandidateGroupChange()
      );
    this.filterForm.get('sparte').valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
          if (value) {
            this.filterForm.get('type').setValue('FOLLOW_UP_BACKOFFICE');
          }
        }
      );
    this.filterForm.get('type').valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
          if (value !== 'FOLLOW_UP_BACKOFFICE') {
            this.filterForm.get('sparte').setValue(null);
          }
        }
      );
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  onSubmit() {
    this.changeFilter.emit({...this.filterModel, ...this.filterForm.value});
  }

  onReset() {
    this.filterForm.reset({orderBy: this.filterModel.orderBy});
    this.onSubmit();
  }

  compareWithContractCategory(o1: number, o2: string | number) {
    return o1 === Number(o2);
  }

  onCandidateUserChange() {
    if (isNil(this.filterForm.get('candidateUser').value) || this.filterForm.get('candidateUser').value === '') {
      if (this.filterForm.get('candidateGroup').disabled) {
        this.filterForm.get('candidateGroup').enable();
      }
    } else if (this.filterForm.get('candidateGroup').enabled) {
      this.filterForm.get('candidateGroup').disable();
    }
  }

  onCandidateGroupChange() {
    if (isNil(this.filterForm.get('candidateGroup').value) || this.filterForm.get('candidateGroup').value === '') {
      if (this.filterForm.get('candidateUser').disabled) {
        this.filterForm.get('candidateUser').enable();
      }
    } else if (this.filterForm.get('candidateUser').enabled) {
      this.filterForm.get('candidateUser').disable();
    }
  }

  private applyValues() {
    if (this.filterModel && this.filterForm) {
      this.filterForm.reset({...this.filterModel});
    }
  }

  private createForm() {
    this.filterForm = this.fb.group({
      type: null,
      createdBefore: null,
      createdAfter: null,
      initiator: null,
      assigned: null,
      candidateGroup: null,
      candidateUser: null,
      customerId: null,
      assignee: null,
      sparte: null
    });
  }
}
