import {Action} from '@ngrx/store';
import {CreateProcess, FilterModel, Task, TaskResponse} from '../../shared/common/services/task.service';

export const LOAD_TASKS = '[Inbox] LOAD_TASKS';
export const LOADING_TASKS = '[Inbox] LOADING_TASKS';
export const LOAD_TASKS_SUCCESS = '[Inbox] LOAD_TASKS_SUCCESS';
export const LOAD_TASKS_FAILURE = '[Inbox] LOAD_TASKS_FAILURE';

export const LOAD_TASK = '[Inbox] LOAD_TASK';
export const LOAD_TASK_SUCCESS = '[Inbox] LOAD_TASK_SUCCESS';
export const LOAD_TASK_FAILURE = '[Inbox] LOAD_TASK_FAILURE';

export const CREATE_NEW_PROCESS = '[Inbox] CREATE_NEW_PROCESS';
export const CREATE_NEW_PROCESS_FAILURE = '[Inbox] CREATE_NEW_PROCESS_FAILURE';

export const CLAIM_TASK = '[Inbox] CLAIM_TASK';
export const CLAIM_TASK_FAILURE = '[Inbox] CLAIM_TASK_FAILURE';

export const DELETE_TASK = '[Inbox] DELETE_TASK';
export const DELETE_TASK_SUCCESS = '[Inbox] DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = '[Inbox] DELETE_TASK_FAILURE';

export const CONFIRM_DELETE_TASK = '[Inbox] CONFIRM_DELETE_TASK';
export const OPEN_CONFIRM_DIALOG = '[Inbox] OPEN_CONFIRM_DIALOG';

export const RESET_CONTRACT = '[INBOX] RESET_CONTRACT';
export const CONFIRM_RESET_CONTRACT = '[INBOX] CONFIRM_RESET_CONTRACT';
export const RESET_CONTRACT_SUCCESS = '[Inbox] RESET_CONTRACT_SUCCESS';
export const RESET_CONTRACT_FAILURE = '[Inbox] RESET_CONTRACT_FAILURE';

export class LoadTaskAction implements Action {
  readonly type = LOAD_TASK;

  constructor(readonly payload: string) {
  }
}

export class LoadTaskSuccessAction implements Action {
  readonly type = LOAD_TASK_SUCCESS;

  constructor(readonly payload: Task) {
  }
}

export class LoadTaskFailureAction implements Action {
  readonly type = LOAD_TASK_FAILURE;

  constructor() {
  }
}

export class LoadTasksAction implements Action {
  readonly type = LOAD_TASKS;

  constructor(readonly payload?: FilterModel) {
  }
}

export class LoadingTasksAction implements Action {
  readonly type = LOADING_TASKS;

  constructor(readonly payload?: FilterModel) {
  }
}

export class LoadTasksSuccessAction implements Action {
  readonly type = LOAD_TASKS_SUCCESS;

  constructor(readonly payload: TaskResponse) {
  }
}

export class LoadTasksFailureAction implements Action {
  readonly type = LOAD_TASKS_FAILURE;

  constructor() {
  }
}

export class CreateNewProcessAction implements Action {
  readonly type = CREATE_NEW_PROCESS;

  constructor(readonly payload: CreateProcess) {
  }
}

export class CreateNewProcessFailureAction implements Action {
  readonly type = CREATE_NEW_PROCESS_FAILURE;

  constructor() {
  }
}

export class ClaimTaskAction implements Action {
  readonly type = CLAIM_TASK;

  constructor(readonly payload: Task) {
  }
}

export class ClaimTaskFailureAction implements Action {
  readonly type = CLAIM_TASK_FAILURE;

  constructor() {
  }
}

export class ConfirmDeleteTaskAction implements Action {
  readonly type = CONFIRM_DELETE_TASK;

  constructor(readonly payload: Task) {

  }
}

export class DeleteTaskAction implements Action {
  readonly type = DELETE_TASK;

  constructor(readonly payload: Task) {

  }
}

export class DeleteTaskSuccessAction implements Action {
  readonly type = DELETE_TASK_SUCCESS;

  constructor(readonly payload: Task) {

  }
}

export class DeleteTaskFailureAction implements Action {
  readonly type = DELETE_TASK_FAILURE;

  constructor() {

  }
}

export class OpenConfirmDialogAction implements Action {
  readonly type = OPEN_CONFIRM_DIALOG;

  constructor(public payload: {
    cancel?: Action,
    confirm: Action | Action[],
    text: string,
    title: string
  }) {
  }
}

export class ConfirmResetContractAction implements Action {
  readonly type = CONFIRM_RESET_CONTRACT;
  constructor(readonly payload: {taskId: string, contractId: number, variables: {}}) {}
}

export class ResetContractAction implements Action {
  readonly type = RESET_CONTRACT;
  constructor(readonly payload: {taskId: string, contractId: number, variables: {}}) {}
}

export class ResetContractSuccessAction implements Action {
  readonly type = RESET_CONTRACT_SUCCESS;

  constructor() {
  }
}

export class ResetContractFailureAction implements Action {
  readonly type = RESET_CONTRACT_FAILURE;

  constructor() {

  }
}

export type Actions = LoadTasksAction |
  LoadTasksSuccessAction |
  LoadTasksFailureAction |
  CreateNewProcessAction |
  ClaimTaskAction |
  LoadTaskAction |
  LoadTasksAction |
  LoadingTasksAction |
  LoadTaskSuccessAction |
  DeleteTaskAction |
  DeleteTaskSuccessAction |
  OpenConfirmDialogAction |
  ConfirmResetContractAction |
  ResetContractAction;
