<app-data-comparison-card [title]="contactTypeLabel"
                          [crmEntity]="isFromServer"
                          [primary]="isPrimary">

  <div class="flex flex-column gap-2"
       [formGroup]="group"
       tr-data-comparison-card-content>

    <mat-form-field>
      <mat-select formControlName="typ">
        <mat-option *ngFor="let c of validTypes" [value]="c.id"> {{c.bezeichnung}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{contactTypeLabel}}</mat-label>
      <input matInput type="text" formControlName="eintrag" appTrim required>
    </mat-form-field>

  </div>

  <div class="flex gap-2 justify-content-end align-items-center" tr-data-comparison-card-buttons>
    <button type="button" mat-icon-button (click)="setCardAsPrimary()" matTooltip="Zur primären Kontaktart machen"
            [disabled]="isPrimary || disableButtons()">
      <mat-icon>grade</mat-icon>
    </button>
    <button type="button" mat-icon-button (click)="deleteCard()" matTooltip="Eintrag löschen" [disabled]="disableButtons()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

</app-data-comparison-card>
