import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export enum CancelTaskChoice {
  DISCARD_UNCLAIM,
  SAVE_UNCLAIM,
  DISCARD_KEEP,
  SAVE_KEEP,
  DISCARD_DELETE = 4
}

@Component({
  selector: 'app-cancel-task-wizard',
  templateUrl: 'cancel-task-wizard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelTaskWizardComponent {
  cancelChoices = CancelTaskChoice;
  valid = false;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: {valid: boolean},
              private dialogRef: MatDialogRef<CancelTaskWizardComponent>) {
    this.valid = data.valid;
  }

  onClose(choice: CancelTaskChoice, comment: string): void {
    this.dialogRef.close({comment, choice});
  }
}
