<div [formGroup]="variables" class="flex flex-column flex-grow-1">
  <div [formGroup]="customer" class="flex mb-3">
    <div formArrayName="identifikationsmerkmale" class="flex flex-wrap gap-3 flex-grow-1">
      <div *ngFor="let identity of identifikationsmerkmale.controls | notDeleted; let i=index">
        <app-single-identity [control]="identity"
                             [formGroupName]="i"
                             class="identity-width"
                             (deleteIdentity)="removeIdentity(i)"
                             [identityTypes]="identityTypes | async">
        </app-single-identity>
      </div>
    </div>
  </div>
  <button class="text-white" type="button" mat-fab (click)="addIdentity()" matTooltip="Neues Identifikationsmerkmal hinzufügen">
    <mat-icon>add</mat-icon>
  </button>
</div>

