import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {isNil} from 'lodash-es';
import {BrokerContractConsent} from './broker-contract.service';
import {cache} from '../../shared/common/cache.operator';
import {environment} from '../../environments/environment';
import {TaskDefinition} from '../../shared/common/services/task.service';
import {Address, Contact, Contract, Person} from '@taures/angular-commons';

/**
 * @hidden
 */
export const EMAIL_GROUP = ['EMAIL'];
/**
 * @hidden
 */
export const PHONE_GROUP = ['MOBIL', 'FESTNETZ', 'FAX'];


/**
 * @hidden
 */
export function toCustomerConsent(consent: BrokerContractConsent): string {
  switch (consent) {
    case BrokerContractConsent.NO:
      return 'NEIN';
    case BrokerContractConsent.YES:
      return 'JA';
    case BrokerContractConsent.RESTRICTED:
      return 'EINGESCHRAENKT';
  }
  return null;
}

/**
 * @hidden
 */
const CUSTOMER_URL = 't-it-s/rest/crm/v1.0/person/';

/**
 * @hidden
 */
@Injectable()
export class CustomerService {

  constructor(private http: HttpClient) {
  }

  createCustomer(data: {
    anrede: string,
    firstName: string,
    lastName: string,
    birthdate: Date,
    addresses?: Address[],
    vp?: number,
    betreuer?: number
  }): Observable<Person> {
    let date = null;
    if (data.birthdate) {
      date = moment(data.birthdate).format('YYYY-MM-DD');
    }
    let geschlecht = null;
    switch (data.anrede) {
      case 'Frau':
        geschlecht = 'w';
        break;
      case 'Firma':
        geschlecht = 'f';
        break;
      case 'Herr':
        geschlecht = 'm';
        break;
    }

    return this.http.post<Person>(CUSTOMER_URL, {
      anrede: data.anrede,
      vorname: data.firstName,
      nachname: data.lastName,
      geschlecht,
      geburtsdatum: date,
      adressen: data.addresses,
      kunde: {
        vp: data.vp,
        betreuer: data.betreuer
      }
    });
  }

  createEmployer(data: {
    name: string,
    adressen: Address[],
    kommunikationsdaten: Contact[]
  }): Observable<Person> {

    const newAdresses: any[] = [];
    data.adressen.forEach(adresse => {
      const newAddress: any = {...adresse};
      if (adresse.gueltigAb) {
        newAddress.gueltigAb = moment(adresse.gueltigAb).format('YYYY-MM-DD');
      }
      newAdresses.push(newAddress);
    });

    return this.http.post<Person>(CUSTOMER_URL, {
      vorname: '',
      nachname: data.name,
      anrede: 'Firma',
      adressen: newAdresses,
      kommunikationsdaten: data.kommunikationsdaten
        .filter(c => !isNil(c.eintrag)),
      companiesOnly: true
    });
  }

  loadAenderungsauftraege(customerId: number): Observable<Contract[]> {
    return this.http.get<Contract[]>(`/t-it-s/rest/crm/v1.0/person/${customerId}/vertraege?inaktiv=false&geloescht=false&` +
      `abgangAusBestand=false&abgeworben=false&fremdvertrag=false`).pipe(
      cache(`aenderungsauftraege:${customerId}`)
    );
  }

  loadContracts(customerId: number): Observable<Contract[]> {
    return this.http.get<Contract[]>(`/t-it-s/rest/crm/v1.0/person/${customerId}/vertraege`).pipe(
      cache(`alleVertraege:${customerId}`)
    );
  }


  openInCrm(customerId: number, formKey: string) {
    let url = environment.crmBaseUrl;
    if (formKey === TaskDefinition.FollowUpAgent) {
      url = url + '/kern/vp/Kontakt.xhtml?id=' + customerId + '&cn=' + this.getHashCode('#1#2#3#' + customerId);
    } else if (formKey === TaskDefinition.FollowUpBackOffice) {
      url = url + '/kern/personen/Kontaktdetail.xhtml?id=' + customerId;
    }
    window.open(url, '_blank');
  }

  getHashCode(value: string) {
    let hash = 0;
    if (hash === 0 && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        hash = 31 * hash + value.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        hash &= hash;
      }
    }
    return hash;
  }
}
