import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  BrokerContract,
  BrokerContractItem,
  BrokerContractService,
  BrokerContractTypes
} from '../../../services/broker-contract.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {DocumentFormComponent} from '../../document-form/document-form.component';
import {TaskDefinition, Variables} from '../../../../shared/common/services/task.service';
import {BrokerContractDocumentFormComponent} from './broker-contract-document-form/broker-contract-document-form.component';
import {isNil} from 'lodash-es';
import {TauresValidators} from '../../../taures-validators';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {AppOptionsService, BrokerContractOption} from '../../../services/app-options.service';
import {CustomerDocument, Person} from '@taures/angular-commons';
import {toCustomerConsent} from '../../../services/customer.service';

export const FILE_TYP_BROKER_CONTRACT = 'mvt';
export const FILE_TYP_BROKER_CONTRACT_SUPPLEMENT = 'anl';

@Component({
  selector: 'app-broker-contract-comparison',
  templateUrl: 'broker-contract.component.html',
  styleUrls: ['broker-contract.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrokerContractComponent implements OnInit {
  readonly TABLE_HEADERS: string[] = ['vertragsgegenstand', 'gueltigAb', 'gueltigBis', 'Bemerkung', 'delete'];

  @Input()
  taskDefinition: TaskDefinition;
  @Input()
  variables: UntypedFormGroup;
  @Input()
  customer: Person;
  brokerContractItems: UntypedFormArray;
  brokerContractOptions: BrokerContractOption[];
  brokerContractDocument: UntypedFormGroup;
  brokerContractTableSource: MatTableDataSource<AbstractControl>;
  brokerContractVersions: string[];
  brokerContractProxyVersions: string[];
  einwilligungKontaktaufnahmeOptions: string[];
  brokerContractTypeKeys: any;
  @ViewChild(MatTable, {static: true})
  brokerContractTable: MatTable<any>;
  private loadSubscription: Subscription;
  private customerDocuments: UntypedFormArray;

  constructor(private changeDetector: ChangeDetectorRef,
              private fb: UntypedFormBuilder,
              private store: Store<fromRoot.State>,
              private brokerContractService: BrokerContractService,
              readonly options: AppOptionsService) {
    this.brokerContractOptions = this.options.brokerContractOptions;
    this.brokerContractVersions = this.options.brokerContractVersion.sort((a, b) => b.localeCompare(a));
    this.brokerContractProxyVersions = this.options.maklervollmachtVersion.sort((a, b) => b.localeCompare(a));
    this.einwilligungKontaktaufnahmeOptions = Object.keys(this.options.einwilligungKontaktaufnahme);
    this.brokerContractTypeKeys = Object.keys(BrokerContractTypes);
  }

  public static buildGroup(fb: UntypedFormBuilder, taskDefinition: TaskDefinition, variables: Variables,
                           brokerContractOptions: BrokerContractOption[]) {
    const brokerContractItems = fb.array([], Validators.compose([TauresValidators.duplicateField('maklerthema')]));
    const customerDocuments = fb.array([]);
    const customer = variables.customer as Person;
    const isBackOfficeTask = taskDefinition === TaskDefinition.FollowUpBackOffice;

    if (variables && variables.brokerContractItems) {
      variables.brokerContractItems
        .sort(BrokerContractComponent.compareBrokerContractItemFn(brokerContractOptions))
        .forEach(contractItem => {
            brokerContractItems.push(
              BrokerContractComponent.initGroup(fb,
                contractItem,
                isBackOfficeTask)
            );
          }
        );
    }

    if (customer.dokumente) {
      customer.dokumente.forEach((doc: CustomerDocument) => {
        // in order to not loose all the documents we need, them here, too - but without validation
        customerDocuments.push(DocumentFormComponent.buildGroup(fb, doc, false));
      });
    }

    return fb.group({
      customer: fb.group({
        id: customer.id,
        dokumente: customerDocuments,
        einwilligungKontaktTelefon: {value: customer.einwilligungKontaktTelefon, disabled: !isBackOfficeTask},
        anmerkungTelefon: {value: customer.anmerkungTelefon, disabled: !isBackOfficeTask},
        einwilligungKontaktEmail: {value: customer.einwilligungKontaktEmail, disabled: !isBackOfficeTask},
        anmerkungEmail: {value: customer.anmerkungEmail, disabled: !isBackOfficeTask},
        einwilligungKontaktMobil: {value: customer.einwilligungKontaktMobil, disabled: !isBackOfficeTask},
        anmerkungMobil: {value: customer.anmerkungMobil, disabled: !isBackOfficeTask},
        kunde: fb.group({
          maklervertragDatum: {value: customer.kunde.maklervertragDatum, disabled: !isBackOfficeTask},
          maklervertragVersion: fb.group({
            version: {
              value: customer.kunde.maklervertragVersion ? customer.kunde.maklervertragVersion.version : null,
              disabled: !isBackOfficeTask
            }
          }),
          maklervollmachtVersion: fb.group({
            version: {
              value: customer.kunde.maklervollmachtVersion ? customer.kunde.maklervollmachtVersion.version : null,
              disabled: !isBackOfficeTask
            }
          }),
          servicevertragDatum: {value: customer.kunde.servicevertragDatum, disabled: !isBackOfficeTask},
          darlehensvermittlungDatum: {value: customer.kunde.darlehensvermittlungDatum, disabled: !isBackOfficeTask},
          makvollmBem: {value: customer.kunde.makvollmBem, disabled: !isBackOfficeTask}
        })
      }),
      brokerContractItems,
      isBackOfficeCheckRequested: [{
        value: !!variables.isBackOfficeCheckRequested,
        disabled: isBackOfficeTask
      }, []]
    });
  }

  public static initGroup(fb: UntypedFormBuilder,
                          contractItem: BrokerContractItem,
                          isBackOfficeTask: boolean) {

    const changeableFromBackOffice = contractItem.manuallyAdded || isBackOfficeTask;

    return fb.group({
      manuallyAdded: contractItem.manuallyAdded,
      datum: [{value: contractItem.datum, disabled: !changeableFromBackOffice}, [Validators.required]],
      gekuendigt: [{value: contractItem.gekuendigt, disabled: !changeableFromBackOffice}, []],
      maklerthema: [{value: contractItem.maklerthema, disabled: !changeableFromBackOffice},
        [Validators.required, TauresValidators.isNumber]],
      bemerkung: contractItem.bemerkung
    });
  }

  private static compareBrokerContractItemFn(brokerContractOptions: BrokerContractOption[]):
    (a: BrokerContractItem, b: BrokerContractItem) => number {
    return (a: BrokerContractItem, b: BrokerContractItem) => {
      const optionA = brokerContractOptions.find(o => o.id === a.maklerthema);
      const optionB = brokerContractOptions.find(o => o.id === b.maklerthema);
      if (!optionA || !optionB) {
        return 0;
      }
      return optionA.sortidx - optionB.sortidx;
    };
  }

  ngOnInit(): void {
    this.brokerContractItems = this.variables.get('brokerContractItems') as UntypedFormArray;
    this.brokerContractTableSource = new MatTableDataSource<AbstractControl>(this.brokerContractItems.controls);

    const customer = this.variables.controls.customer as UntypedFormGroup;
    this.customerDocuments = customer.get('dokumente') as UntypedFormArray;
    this.brokerContractDocument = this.customerDocuments.controls.find(
      doc => (doc.value.typ === FILE_TYP_BROKER_CONTRACT || doc.value.typ === FILE_TYP_BROKER_CONTRACT_SUPPLEMENT)
        && (isNil(doc.value.id) || doc.value.currentProcess)
    ) as UntypedFormGroup;

    // create an empty new document if there is none
    if (!this.brokerContractDocument) {
      this.brokerContractDocument = BrokerContractDocumentFormComponent.buildGroup(this.fb,
        {filename: null, fileId: null, typ: FILE_TYP_BROKER_CONTRACT, currentProcess: true}
      );
    }
    if (this.brokerContractItems.length === 0) {
      this.loadCustomerBrokerContractItems(customer.get('id').value);
    }
  }

  addContractItem() {
    const control = BrokerContractComponent.initGroup(
      this.fb,
      {maklerthema: null, manuallyAdded: true},
      this.isBackOfficeTask());
    this.brokerContractItems.push(control);
    this.brokerContractTableSource.data = this.brokerContractItems.controls;
  }

  removeContractItem(i: number) {
    this.brokerContractItems.removeAt(i);
    this.brokerContractTableSource.data = this.brokerContractItems.controls;
  }

  onBrokerContractChanged(contract: BrokerContract) {
    this.clearItems();
    // check if we are working on a new broker contract
    const brokerContractIndex = this.customerDocuments.controls
      .findIndex(d => (d.value.typ === FILE_TYP_BROKER_CONTRACT || d.value.typ === FILE_TYP_BROKER_CONTRACT_SUPPLEMENT)
        && (isNil(d.value.id) || d.value.currentProcess));
    if (!contract || !contract.items.length) {
      // if we are replacing a new uploaded contract, reload the old data from crm
      // this contract has no items, reset all values
      this.setKundeValuesFromCustomer();
      this.loadCustomerBrokerContractItems(this.customer.id);
      // since this document is handwritten, request a check
      this.variables.get('isBackOfficeCheckRequested').setValue(true);
      this.customerDocuments.removeAt(brokerContractIndex);
    } else {
      this.updateContractItems(contract.items);
    }
    if (contract) {
      this.variables.get('customer.kunde.servicevertragDatum').setValue(contract.serviceContractDate);
      this.variables.get('customer.kunde.darlehensvermittlungDatum').setValue(contract.creditBrokerageAgreementDate);
      this.brokerContractDocument.get('typ').setValue(contract.supplement ? 'anl' : 'mvt');
      if (!contract.supplement) {
        this.variables.get('customer.kunde.maklervertragDatum').setValue(contract.signatureDate || contract.created);
        this.variables.get('customer.kunde.maklervertragVersion.version').setValue(contract.version);
      }
      if (!contract.supplement || contract.consentChanged) {
        this.variables.get('customer.kunde.maklervertragDatum').setValue(contract.signatureDate || contract.created);
        this.variables.get('customer.kunde.maklervertragVersion.version').setValue(contract.version);
        this.variables.get('customer.einwilligungKontaktTelefon').setValue(toCustomerConsent(contract.fixedLine.consent));
        this.variables.get('customer.anmerkungTelefon').setValue(contract.fixedLine.restrictTo);
        this.variables.get('customer.einwilligungKontaktEmail').setValue(toCustomerConsent(contract.email.consent));
        this.variables.get('customer.anmerkungEmail').setValue(contract.email.restrictTo);
        this.variables.get('customer.einwilligungKontaktMobil').setValue(toCustomerConsent(contract.mobile.consent));
        this.variables.get('customer.anmerkungMobil').setValue(contract.mobile.restrictTo);
      }
    }
    // add the broker contract document, if not already there
    if (brokerContractIndex === -1) {
      this.customerDocuments.push(this.brokerContractDocument);
    }
  }

  isBackOfficeTask(): boolean {
    return this.taskDefinition === TaskDefinition.FollowUpBackOffice;
  }

  public einwilligungKontaktaufnahmeLabel(key: string): string {
    return Object.values(this.options.einwilligungKontaktaufnahme).find(value => this.options.einwilligungKontaktaufnahme[key] === value);
  }

  public brokerContractTypeLabel(key: string): string {
    return Object.values(BrokerContractTypes).find(value => BrokerContractTypes[key] === value);
  }

  public showKontaktKommentar(communicationType: string) {
    return this.variables.get('customer').get(communicationType).value === 'EINGESCHRAENKT';
  }

  private updateContractItems(newItemList: BrokerContractItem[]) {
    // remove all the old items, first
    this.clearItems();
    // now add the new ones
    newItemList.sort(BrokerContractComponent.compareBrokerContractItemFn(this.options.brokerContractOptions)).forEach(contractItem => {
      this.brokerContractItems.push(
        BrokerContractComponent.initGroup(this.fb,
          contractItem,
          this.isBackOfficeTask()
        ));
    });
    this.brokerContractTableSource.data = this.brokerContractItems.controls;
    this.changeDetector.markForCheck();
  }

  private loadCustomerBrokerContractItems(customerId: number) {
    if (this.loadSubscription) {
      this.loadSubscription.unsubscribe();
    }
    this.loadSubscription = this.brokerContractService
      .getBrokerContractItems(customerId)
      .subscribe(items => this.updateContractItems(items));
  }

  private setKundeValuesFromCustomer() {
    if (this.customer.kunde) {
      this.variables.get('customer.kunde').setValue({
        servicevertragDatum: this.customer.kunde.servicevertragDatum,
        darlehensvermittlungDatum: this.customer.kunde.darlehensvermittlungDatum,
        maklervertragDatum: this.customer.kunde.maklervertragDatum,
        maklervertragVersion: {
          version: this.customer.kunde.maklervertragVersion ? this.customer.kunde.maklervertragVersion.version : null
        },
        maklervollmachtVersion: {
          version: this.customer.kunde.maklervollmachtVersion ? this.customer.kunde.maklervollmachtVersion.version : null
        },
        makvollmBem: this.customer.kunde.makvollmBem
      });
    }
    this.variables.get('customer.einwilligungKontaktTelefon').setValue(this.customer.einwilligungKontaktTelefon);
    this.variables.get('customer.anmerkungTelefon').setValue(this.customer.anmerkungTelefon);
    this.variables.get('customer.einwilligungKontaktEmail').setValue(this.customer.einwilligungKontaktEmail);
    this.variables.get('customer.anmerkungEmail').setValue(this.customer.anmerkungEmail);
    this.variables.get('customer.einwilligungKontaktMobil').setValue(this.customer.einwilligungKontaktMobil);
    this.variables.get('customer.anmerkungMobil').setValue(this.customer.anmerkungMobil);
  }

  private clearItems() {
    while (0 !== this.brokerContractItems.length) {
      this.brokerContractItems.removeAt(0);
    }
    this.changeDetector.markForCheck();
  }
}
