import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReworkFormComponent} from '../../task-reworks/rework-form/rework-form.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {PdfPreviewComponent} from '../../../pdf-preview/pdf-preview.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FileDocument} from '@taures/angular-commons';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentPreviewComponent implements OnDestroy {
  @ViewChild(PdfPreviewComponent, {static: true})
  previewComponent: PdfPreviewComponent;
  rework: UntypedFormGroup;
  reworks = [];
  index = this.data.documents.findIndex(c => c.get('fileId').value === this.data.file.fileId);
  size = this.data.documents.length;

  private destroy = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: { file: FileDocument, documents: UntypedFormGroup[] },
              public dialogRef: MatDialogRef<DocumentPreviewComponent>,
              private snackbar: MatSnackBar,
              private changeDetectorRef: ChangeDetectorRef,
              private fb: UntypedFormBuilder) {
    this.rework = ReworkFormComponent.initGroup(this.fb, {objekttyp: 'K', status: '170', infoAnBetreuer: true});
  }

  get filename() {
    return this.file.filename;
  }

  get file(): FileDocument {
    return this.data.documents[this.index].value;
  }

  get formGroup(): UntypedFormGroup {
    return this.data.documents[this.index] as UntypedFormGroup;
  }

  get hasNextPage(): boolean {
    return this.index < this.size - 1;
  }

  get hasPreviousPage(): boolean {
    return this.index > 0;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  close(): void {
    this.dialogRef.close(this.reworks);
  }

  next(): void {
    if (!this.hasNextPage) {
      return;
    }
    this.index++;
    this.changeDetectorRef.markForCheck();
  }

  previous(): void {
    if (!this.hasPreviousPage) {
      return;
    }
    this.index--;
    this.changeDetectorRef.markForCheck();
  }

  addRework(): void {
    if (this.rework.valid) {
      this.reworks.push(this.rework.value);
      this.rework.reset({objekttyp: 'K'});
      this.snackbar.open('Nachbearbeitung angelegt', null, {duration: 1500});
    }
  }

  download(): void {
    this.previewComponent.download();
  }
}
