<div class="flex p-2 taures-secondary-blue">
  Nachbearbeitung
</div>
<div class="flex flex-column p-4 overflow-hidden" [formGroup]="reworkForm">
  <div class="flex">
    <p class="w-9rem m-0">Erstellungsdatum</p>
    <span>{{reworkForm.get('createDate').value | date: 'dd.MM.yyyy'}}</span>
  </div>
  <mat-radio-group formControlName="objekttyp" class="flex gap-4 align-items-center">
    <label>Bezug:</label>
    <mat-radio-button [value]="'K'">
      Kunde
    </mat-radio-button>
    <mat-radio-button [value]="'V'">
      Vertrag
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field *ngIf="reworkForm.get('objekttyp').value === 'V'">
    <mat-label>Vertrag</mat-label>
    <mat-select formControlName="objekt" panelClass="expandable-overlay-panel" required>
      <mat-option *ngFor="let contract of contracts" [value]="contract.id">
        <app-contract-documents-description [contract]="contract" [showBranch]="false"
                                            [tariffs]="tariffs"></app-contract-documents-description>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Kategorie</mat-label>
    <mat-select formControlName="gvKategorie"
                required>
      <mat-option *ngFor="let action of actionCategoryTypes; let i=index" [value]="action.dbvalue">
        <span>{{ action.uivalue}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Geschäftsvorfall</mat-label>
    <mat-select formControlName="geschaeftsvorfall"
                required>
      <mat-option *ngFor="let bProc of getBuisnessProcesses(reworkForm.get('gvKategorie').value);" [value]="bProc.dbvalue">
        <span>{{ bProc.uivalue}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select formControlName="status"
                required>
      <mat-option *ngFor="let key of actionStatusTypesKeys;" [value]="key">
        <span>{{ actionStatusTypes[key] }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="flex flex-column flex-wrap gap-1 m-2">
    <div class="flex" *ngIf="reworkForm.get('durchfuehrungSoll').value">
      <p class="w-9rem m-0 font-medium">Fälligkeitsdatum</p>
      <span>{{reworkForm.get('durchfuehrungSoll').value | date: 'dd.MM.yyyy'}}</span>
    </div>

    <div class="flex" *ngIf="reworkForm.get('erinnerungsdatum').value">
      <p class="w-9rem m-0 font-medium">Erinnerungsdatum</p>
      <span>{{reworkForm.get('erinnerungsdatum').value | date: 'dd.MM.yyyy'}}</span>
    </div>
  </div>

  <mat-form-field>
    <mat-label>Kommentar</mat-label>
    <textarea matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10"
              formControlName="beschreibung"
              appTrim>
    </textarea>
  </mat-form-field>

  <div class="flex flex-column gap-1" *ngIf="reworkForm.get('kommentare').value?.length">
    Kommentare:
    <div *ngFor="let comment of reworkForm.get('kommentare').value" class="flex flex-column gap-1 p-1 comment">
      <i>{{comment.createdate | date: 'dd.MM.yyyy'}} - {{comment.verfasser | personName:false | async}}</i>
      <div>{{comment.kommentar}}<div>
      </div>
      </div>
    </div>
  </div>
</div>
