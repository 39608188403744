import {Tariff} from './services/tariff.service';


export class Tariffs {

  static tariff(tariffs: Tariff[], id: number): Tariff {
    if (tariffs && tariffs.length > 0) {
      return tariffs.find(e => e.id === id);
    } else {
      return null;
    }
  }

  static insurerName(tariffs: Tariff[], id: number): string {
    const tariff = Tariffs.tariff(tariffs, id);
    return tariff ? tariff.gesellschaft.kurzname : null;
  }

  static sparteName(tariffs: Tariff[], id: number): string {
    const tariff = Tariffs.tariff(tariffs, id);
    return tariff ? tariff.vertragart.sparte.bezeichnung : null;
  }
}

