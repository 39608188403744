import {Action} from '@ngrx/store';

export const UPDATE_CANDIDATE_IDS = '[Task] UPDATE_CANDIDATE_IDS';

export class UpdateCandidatesIdsAction implements Action {
  readonly type = UPDATE_CANDIDATE_IDS;
  constructor(readonly payload: { taskID: string , userIDs: Array<number> , groupIDs: Array<string> , comment?: string} ) {
  }
}

