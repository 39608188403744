import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-softfair-field',
  templateUrl: 'softfair-field.component.html',
  styleUrls: ['softfair-field.component.scss']
})
export class SoftfairFieldComponent {

  @Input() titel: string;
  @Input() disabled: boolean;
  @Input() softfairDataValue: any;
  @Input() resultFormControl: UntypedFormControl;
  private displayableValue: string;

  get displayValue(): string {
    return !!this.displayableValue ? this.displayableValue : (!!this.softfairDataValue ? this.softfairDataValue : '');
  }

  @Input()
  set displayValue(value: string) {
    this.displayableValue = value;
  }

  assignSoftfairValue() {
    if (!!this.softfairDataValue && this.resultFormControl) {
      this.resultFormControl.setValue(this.softfairDataValue);
    }
  }
}
