import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InboxOverviewComponent} from './components/inbox-overview/inbox-overview.component';
import {FilterModelResolver} from './services/task/filter-model-resolver.service';
import {TaskWizardGuardService} from './services/task/task-wizard-guard.service';
import {TaskWizardContainerComponent} from './components/task-wizard-container/task-wizard-container.component';
import {CanDeactivateGuard} from './services/can-deactivate-guard.service';
import {PageNotFoundComponent} from '../shared/common';
import {AzaStartComponent} from './components/aza-start/aza-start.component';

const appRoutes: Routes = [
  {
    path: '',
    component: InboxOverviewComponent,
    resolve: {
      filterModel: FilterModelResolver
    }
  },
  {
    path: 'wizard/:step/:taskId',
    component: TaskWizardContainerComponent,
    canActivate: [TaskWizardGuardService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'start-aza/:customerId/:step',
    component: AzaStartComponent
  },
  {
    path: 'groups',
    loadChildren: () => import('./../groups/groups.module').then(m => m.GroupsModule)
  },
  {
    path: '**', component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
