<div *ngIf="customer | async as customerLocal" class="p-2">
  <ng-container *ngIf="task | async as taskLocal">

    <div class="flex gap-4" *ngIf="currentStep | async as currentStepLocal">
      <app-task-steps-control *ngIf="getStepsOfTask(taskLocal).length > 1"
                              #taskStepsControl
                              [task]="taskLocal"
                              [customer]="customerLocal"
                              [currentStep]="currentStepLocal"
                              (openStep)="onGotoStep($event, taskLocal)">
      </app-task-steps-control>

      <form [formGroup]="formGroup" novalidate class="flex flex-column flex-grow-1">

        <div class="tr-task-wizard-container-card flex flex-column gap-3">
          <div class="tr-task-wizard-container-card-title flex gap-3 justify-content-between">
            <h1>{{currentStepLocal.title}}</h1>
            <div class="flex gap-3 align-items-center" >
              <span class="task-customer-header">
                {{customerLocal.nachname}}, {{customerLocal.vorname}} ({{customerLocal.geburtsdatum | date: 'dd.MM.yyyy'}})
              </span>
              <button mat-raised-button type="button" (click)="openInCrm(customerLocal.id, taskLocal.formKey)">
                Im CRM öffnen
              </button>
              <button mat-raised-button type="button" (click)="openReworkDialog(taskLocal, currentStepLocal)"
                      *ngIf="currentStepLocal.slug !== 'task_rework' && taskLocal.formKey === 'follow-up-back-office'">
                Nachbearbeitung anlegen
              </button>
            </div>
          </div>

          <ng-container [ngSwitch]="currentStepLocal.slug">
            <app-basic-data-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_BASIC"
                                       [variables]="variables"
                                       [customer]="customerLocal" class="flex-grow-1">
            </app-basic-data-comparison>
            <app-customer-contact-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_CONTACT"
                                             [variables]="variables"
                                             class="flex-grow-1">
            </app-customer-contact-comparison>
            <app-customer-contact-address-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_ADDRESSES"
                                                     [variables]="variables"
                                                     [originalCustomer]="customerLocal"
                                                     class="flex-grow-1">
            </app-customer-contact-address-comparison>
            <app-customer-bank-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_BANK"
                                          [variables]="variables"
                                          [customerId]="customerLocal.id"
                                          [originalCustomer]="customerLocal"
                                          class="flex-grow-1">
            </app-customer-bank-comparison>
            <app-customer-contract-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_CONTRACT"
                                              [variables]="variables"
                                              [taskDefinition]="taskLocal.formKey"
                                              class="flex-grow-1">
            </app-customer-contract-comparison>
            <app-customer-identity-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_IDENTITY"
                                              [variables]="variables"
                                              class="flex-grow-1">
            </app-customer-identity-comparison>
            <app-surplus-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_SURPLUS"
                                    [variables]="variables"
                                    [taskDefinition]="taskLocal.formKey"
                                    class="flex-grow-1">
            </app-surplus-comparison>
            <app-documents-overview *ngSwitchCase="stepsAvailable.DOCUMENTS_OVERVIEW"
                                    [variables]="variables"
                                    [crmCustomer]="customerLocal"
                                    [taskDefinition]="taskLocal.formKey"
                                    class="flex-grow-1">
            </app-documents-overview>
            <app-task-reworks-comparison *ngSwitchCase="stepsAvailable.TASK_REWORK_OVERVIEW"
                                         [variables]="variables"
                                         class="flex-grow-1">
            </app-task-reworks-comparison>
            <app-broker-contract-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_BROKER_CONTRACT"
                                            [variables]="variables"
                                            [customer]="customerLocal"
                                            [taskDefinition]="taskLocal.formKey"
                                            class="flex-grow-1">
            </app-broker-contract-comparison>
            <app-customer-relationships-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_RELATIONSHIPS"
                                                   [variables]="variables"
                                                   class="flex-grow-1">
            </app-customer-relationships-comparison>
            <app-customer-employments-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_EMPLOYMENTS"
                                                 [variables]="variables"
                                                 class="flex-grow-1">
            </app-customer-employments-comparison>
            <app-tax-infos *ngSwitchCase="stepsAvailable.CUSTOMER_TAX_INFOS"
                           [variables]="variables"
                           [customer]="customerLocal"
                           class="flex-grow-1">
            </app-tax-infos>
            <app-customer-marital-status-comparison *ngSwitchCase="stepsAvailable.CUSTOMER_MARITAL_STATUS"
                                                    [variables]="variables"
                                                    class="flex-grow-1">
            </app-customer-marital-status-comparison>

            <app-customer-contract-single *ngSwitchCase="stepsAvailable.CUSTOMER_CONTRACT_SINGLE"
                                          [variables]="variables"
                                          [taskDefinition]="taskLocal.formKey"
                                          (resetSubContract)="onResetSubContract($event, taskLocal)"
                                          class="flex-grow-1">
            </app-customer-contract-single>
            <b *ngSwitchDefault>Unknown step {{currentStepLocal}}</b>
          </ng-container>
        </div>
        <div class="mat-app-background mat-elevation-z2 tr-task-wizard-container-buttons flex gap-2">
          <button mat-button type="button" class="button" (click)="onCancel(taskLocal)">Abbrechen</button>
          <button mat-button type="button" class="button" [disabled]="!hasBack(taskLocal, currentStepLocal)"
                  (click)="onBack(taskLocal)">Zurück
          </button>
          <button mat-raised-button (click)="onNext(taskLocal, currentStepLocal, customerLocal)" color="primary"
                  class="button complete">{{isComplete(taskLocal, currentStepLocal) ?
            'Abschließen' :
            'Weiter'}}
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</div>
