import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SingleSurplusComponent} from './single-surplus/single-surplus.component';
import {TaskDefinition, Variables} from '../../../../shared/common/services/task.service';
import * as moment from 'moment';
import {CustomerDocument, Person, Status} from '@taures/angular-commons';
import {DocumentFormComponent} from '../../document-form/document-form.component';
import {isNil} from 'lodash-es';

@Component({
  selector: 'app-surplus-comparison',
  templateUrl: 'surplus.component.html',
  styleUrls: ['surplus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurplusComponent {
  customer: UntypedFormGroup;
  surpluses: UntypedFormArray;
  previewDocument: UntypedFormGroup;
  private variablesGroup: UntypedFormGroup;
  private previewDocumentIndex = -1;
  @Input()
  taskDefinition: TaskDefinition;

  constructor(private fb: UntypedFormBuilder) {
  }

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    this.customer = (this.variables.controls.customer as UntypedFormGroup);
    this.surpluses = (this.customer.controls.ueberschuss as UntypedFormArray);
    this.setupPreviewDocument();

    this.sortSurplusesByDate();
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables) {
    const surplusesControl = fb.array([]);
    const customer = variables.customer as Person;
    const customerDocuments = fb.array([]);

    if (customer && customer.ueberschuss) {
      customer.ueberschuss.forEach(s => {
        surplusesControl.push(SingleSurplusComponent.buildGroup(fb, s, s.status !== Status.DELETED));
      });
    }

    if (customer.dokumente) {
      customer.dokumente.forEach((doc: CustomerDocument) => {
        // in order to not loose all the documents we need, them here, too - but without validation
        customerDocuments.push(DocumentFormComponent.buildGroup(fb, doc, false));
      });
    }

    return fb.group({
      customer: fb.group(
        {
          ueberschuss: surplusesControl,
          dokumente: customerDocuments,
          kunde: fb.group({
            konzeptdoku:  customer.kunde.konzeptdoku
            })
        }
      )
    });
  }

  sortSurplusesByDate() {
    this.surpluses.controls.sort((a, b) => moment(a.get('datum').value).isBefore(moment(b.get('datum').value)) ? 1 : -1);
  }

  addSurplus() {
    const control = SingleSurplusComponent.buildGroup(this.fb, {datum: new Date()});
    this.surpluses.push(control);
  }

  removeSurplus(index: number) {
    const entity = this.surpluses.at(index);
    if (entity.get('id').value) {
      entity.patchValue({status: Status.DELETED}, {emitEvent: false});
    } else {
      this.surpluses.removeAt(index);
    }
  }

  documentChanged() {
    const customerDocuments = (this.customer.get('dokumente') as UntypedFormArray);
    if (this.previewDocumentIndex === -1) {
      customerDocuments.push(this.previewDocument);
    }
    if (this.previewDocument.value.id === null && this.previewDocument.value.fileId === null) {
      customerDocuments.removeAt(this.previewDocumentIndex);
      this.setupPreviewDocument();
    }
  }

  private setupPreviewDocument(): void {
    const customerDocuments = this.customer.get('dokumente') as UntypedFormArray;
    this.previewDocumentIndex = -1;
    let newKdlDocIndex = -1;
    let stockKdlDocIndex = -1;
    let lastStockId = -1;
    for (let i = 0; i < customerDocuments.controls.length; i++) {
      const doc = customerDocuments.controls[i].value;
      if (doc.typ === 'kdl') {
        if (isNil(doc.id)) {
          newKdlDocIndex = i;
        } else if (doc.id > lastStockId) {
          lastStockId = doc.id;
          stockKdlDocIndex = i;
        }
      }
    }
    if (newKdlDocIndex > -1) {
      this.previewDocumentIndex = newKdlDocIndex;
    } else {
      this.previewDocumentIndex = stockKdlDocIndex;
    }

    // create an empty new document if there is none
    if (this.previewDocumentIndex === -1) {
      this.previewDocument = DocumentFormComponent.buildGroup(this.fb,
        {filename: null, fileId: null, typ: 'kdl', currentProcess: true}
      );
    } else {
      this.previewDocument = customerDocuments.at(this.previewDocumentIndex) as UntypedFormGroup;
    }
  }
  isBackOfficeTask(): boolean {
    return this.taskDefinition === TaskDefinition.FollowUpBackOffice;
  }
}
