<div class="flex gap-4 justify-content-start align-items-center {{rowClass}}">
  <div class="w-{{rowWidths[0]}} {{titleClasses}}">{{title}}</div>
  <div *ngIf="rowWidths[1] > 0" class="flex justify-content-end align-items-center w-{{rowWidths[1]}} list-item">
    <div>{{softfairValueFormatted || softfairValue}}</div>
    <button type="button" mat-icon-button (click)="assignSoftfairValue()" *ngIf="!!softfairValue && hasSoftfairCustomer">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
  <div class="flex w-{{rowWidths[2]}}">
    <ng-content></ng-content>
  </div>

  <div class="flex justify-content-start align-items-center w-{{rowWidths[3]}} list-item" *ngIf="!!serverValue && !resultFormControl.disabled; else emptyColumn">
    <button type="button" mat-icon-button (click)="assignServerValue()">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <div>{{serverValueFormatted || serverValue}}</div>
  </div>
  <ng-template #emptyColumn>
    <div class="w-{{rowWidths[3]}}"></div>
  </ng-template>
</div>
