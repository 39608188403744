import {Component, Input} from '@angular/core';
import {Task} from '../../../shared/common/services/task.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Person, PersonService} from '@taures/angular-commons';

@Component({
  selector: 'app-follow-up-agent-task-details',
  templateUrl: './follow-up-agent-task-details.component.html',
  styleUrls: ['./follow-up-agent-task-details.component.scss']
})
export class FollowUpAgentTaskDetailsComponent {

  numberOfContracts: Observable<number>;

  constructor(readonly personService: PersonService) {
  }

  private currentTask: Task;

  get task() {
    return this.currentTask;
  }

  @Input()
  set task(value: Task | null) {
    this.currentTask = value;
    if (this.task) {
      if (this.task.variables) {
        // only request the customer if we not already have her in the variables data
        const customer = this.task.variables.customer as Person;
        let customerObservable: Observable<Person>;
        if (customer) {
          customerObservable = of(customer);
        } else {
          const customerId = this.task.variables.customerId;
          if (customerId) {
            customerObservable = this.personService.getPerson(customerId);
          }
        }
        this.numberOfContracts = customerObservable.pipe(map(c => c.vertraege.filter(v => v.id < 0).length));
      }
    }
  }
}
