import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Bank} from '../../services/iban.service';

@Component({
  selector: 'app-bank-chooser',
  templateUrl: './bank-chooser.component.html',
  styleUrls: ['./bank-chooser.component.scss']
})
export class BankChooserComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Bank[], private matDialogRef: MatDialogRef<BankChooserComponent>) {
  }

  get banks(): Bank[] {
    return this.data;
  }

  close(bank?: Bank): void {
    this.matDialogRef.close(bank);
  }
}
