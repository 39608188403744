<mat-expansion-panel class="multi-select-list-panel">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Verträge
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="flex flex-column gap-2 max-h-14rem overflow-y-auto" *ngIf="tariffs">
    <mat-checkbox *ngFor="let contract of localModel; let i=index" (change)="updateLocalModel()"
                  [(ngModel)]="contract.checked" class="multi-select-list-item" [matTooltip]="contract.contract.id">
      {{getInsurerName(contract.contract.tarif)}} - {{getSparte(contract.contract.tarif)}} - {{contract.contract.vertrnummer}}
    </mat-checkbox>
  </div>
</mat-expansion-panel>
