import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  Optional,
  Self,
  ViewEncapsulation
} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlValueAccessor, UntypedFormControl, NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';
import {DecimalPipe} from '@angular/common';
import {isNil, round} from 'lodash-es';

function parseToNumber(value: string, precision: number) {
  if (!value) {
    return;
  }
  return round(parseFloat(
    value.replace(/\./g, '')
      .replace(',', '.')
  ), precision);
}

@Component({
  selector: 'app-decimal-input',
  templateUrl: './decimal-input.component.html',
  styleUrls: ['./decimal-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: MatFormFieldControl, useExisting: DecimalInputComponent}],
})
export class DecimalInputComponent implements MatFormFieldControl<number>, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  disabled = false;
  focused = false;
  control = new UntypedFormControl();
  stateChanges = new Subject<void>();
  @HostBinding() id = `tr-decimal-input-${DecimalInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  controlType = 'tr-decimal-input';

  private numberValue: number;
  private placeholderValue: string;
  private isRequired = false;
  private digitInfoValue = '1.2-2';

  // noinspection UnterminatedStatementJS
  onChange: any = () => {
    // default function
  }
  // noinspection UnterminatedStatementJS
  onTouched: any = () => {
    // default function
  }

  constructor(private fm: FocusMonitor,
              private elRef: ElementRef,
              @Inject(LOCALE_ID) private locale: string,
              @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) { this.ngControl.valueAccessor = this; }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.onTouched();
      const newValue = parseToNumber(this.control.value, this.precision);
      if (newValue !== this.numberValue) {
        this.numberValue = newValue;
        this.control.setValue(this.format(this.numberValue));
        this.onChange(this.numberValue);
      }
      this.stateChanges.next();
    });
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this.placeholderValue;
  }

  set placeholder(plh) {
    this.placeholderValue = plh;
    this.stateChanges.next();
  }

  @Input()
  set digitInfo(inp: string) {
    this.digitInfoValue = inp;
    this.control.setValue(this.format(this.numberValue));
    this.stateChanges.next();
  }

  get precision() {
    const length = this.digitInfoValue.length;
    return Number(this.digitInfoValue[length - 1]);
  }

  @Input()
  get required() {
    return this.isRequired;
  }

  set required(req) {
    this.isRequired = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get value(): number | null {
    return this.numberValue;
  }

  set value(v: number | null) {
    let value = v;
    if (typeof v === 'string') {
      value = parseToNumber(v, this.precision);
    } else if (typeof v === 'number') {
      value = round(v, 2);
    }
    this.numberValue = value;
    this.control.setValue(this.format(value));
    this.stateChanges.next();
  }

  get empty() {
    return !this.control.value;
  }

  get errorState() {
    return !this.ngControl.disabled ? !this.ngControl.valid : false;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: number): void {
    if (isNil(value) || isNaN(value)) {
      return;
    }
    if (this.numberValue === value) {
      return;
    }
    this.value = value;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  private format(value: number) {
    if (isNil(value) || isNaN(value)) {
      return;
    }
    const  moneyPipe = new DecimalPipe(this.locale);
    return moneyPipe.transform(value, this.digitInfoValue);
  }
}
