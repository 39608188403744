import {Action} from '@ngrx/store';
import {Task} from '../../shared/common/services/task.service';
import {Step} from '../step-definitions';
import {Person} from '@taures/angular-commons';

export const UPDATE_TASK_VARIABLES = '[Wizard] UPDATE_TASK_VARIABLES';
export const UPDATE_TASK_VARIABLES_FAILURE = '[Wizard] UPDATE_TASK_VARIABLES_FAILURE';
export const CONFIRM_UNCLAIM_TASK = '[Wizard] CONFIRM_UNCLAIM_TASK';
export const UNCLAIM_TASK = '[Wizard] UNCLAIM_TASK';
export const UNCLAIM_TASK_FAILURE = '[Wizard] UNCLAIM_TASK_FAILURE';
export const UNCLAIM_TASK_SUCCESS = '[Wizard] UNCLAIM_TASK_SUCCESS';
export const COMMENT_TASK = '[Wizard] COMMENT_TASK';
export const COMMENT_TASK_FAILURE = '[Wizard] COMMENT_TASK_FAILURE';

export const NEXT_STEP = '[Wizard] NEXT_STEP';
export const PREVIOUS_STEP = '[Wizard] PREVIOUS_STEP';
export const START_WIZARD = '[Wizard] START_WIZARD';
export const START_WIZARD_SUCCESS = '[Wizard] START_WIZARD_SUCCESS';
export const START_WIZARD_FAILURE = '[Wizard] START_WIZARD_FAILURE';
export const COMPLETE_WIZARD = '[Wizard] COMPLETE_WIZARD';
export const COMPLETE_WIZARD_FAILURE = '[Wizard] COMPLETE_WIZARD_FAILURE';
export const COMPLETE_WIZARD_SUCCESS = '[Wizard] COMPLETE_WIZARD_SUCCESS';
export const GOTO_STEP = '[Wizard] GOTO_STEP';
export const UPDATE_WIZARD_TASK = '[Wizard] UPDATE_WIZARD_TASK';

export class UpdateTaskVariablesAction implements Action {
  readonly type = UPDATE_TASK_VARIABLES;

  constructor(readonly payload: { comment?: string, variables: {}, taskId: string }) {
  }
}

export class UpdateWizardTaskAction implements Action {
  readonly type = UPDATE_WIZARD_TASK;

  constructor(readonly payload: {}) {
  }
}

export class UpdateTaskVariablesFailureAction implements Action {
  readonly type = UPDATE_TASK_VARIABLES_FAILURE;

  constructor() {
  }
}

export class ConfirmUnclaimTaskAction implements Action {
  readonly type = CONFIRM_UNCLAIM_TASK;

  constructor(readonly payload: { comment?: string, variables?: {}, taskId: string}) {
  }
}

export class UnclaimTaskAction implements Action {
  readonly type = UNCLAIM_TASK;

  constructor(readonly payload: { comment?: string, variables?: {}, taskId: string }) {
  }
}

export class UnclaimTaskFailureAction implements Action {
  readonly type = UNCLAIM_TASK_FAILURE;

  constructor() {
  }
}

export class UnclaimTaskSuccessAction implements Action {
  readonly type = UNCLAIM_TASK_SUCCESS;

  constructor() {
  }
}

export class CommentTaskAction implements Action {
  readonly type = COMMENT_TASK;

  constructor(readonly payload: { comment: string, taskId: string }) {
  }
}

export class CommentTaskFailureAction implements Action {
  readonly type = COMMENT_TASK_FAILURE;

  constructor() {
  }
}

export class NextStepAction implements Action {
  readonly type = NEXT_STEP;

  constructor(readonly payload: {variables: {}, customer: Person}) {
  }
}

export class PreviousStepAction implements Action {
  readonly type = PREVIOUS_STEP;

  constructor(readonly payload: {}) {
  }
}

export class StartWizardAction implements Action {
  readonly type = START_WIZARD;

  constructor(readonly payload: { task: Task, step?: Step }) {
  }
}

export class StartWizardSuccesAction implements Action {
  readonly type = START_WIZARD_SUCCESS;

  constructor(readonly payload: { task: Task, step?: Step, customer?: Person }) {
  }
}

export class StartWizardFailureAction implements Action {
  readonly type = START_WIZARD_FAILURE;

  constructor() {
  }
}

export class CompleteWizardAction implements Action {
  readonly type = COMPLETE_WIZARD;

  constructor(readonly payload: {}) {
  }
}

export class CompleteWizardFailureAction implements Action {
  readonly type = COMPLETE_WIZARD_FAILURE;

  constructor(readonly payload: any) {
  }
}

export class CompleteWizardSuccessAction implements Action {
  readonly type = COMPLETE_WIZARD_SUCCESS;

  constructor(readonly payload: Task) {
  }
}

export class GotoStepAction implements Action {
  readonly type = GOTO_STEP;

  constructor(readonly payload: { variables?: {}, step: Step }) {
  }
}

export type Actions =
  | UpdateTaskVariablesAction
  | ConfirmUnclaimTaskAction
  | UnclaimTaskAction
  | CommentTaskAction
  | StartWizardAction
  | StartWizardSuccesAction
  | StartWizardFailureAction
  | NextStepAction
  | GotoStepAction
  | PreviousStepAction
  | CompleteWizardAction
  | UpdateWizardTaskAction
  | CompleteWizardSuccessAction;
