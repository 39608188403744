import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {TaskComment, TaskEvent} from '../../shared/common/services/task.service';
import {map, switchMap} from 'rxjs/operators';
import {Person, PersonService} from '@taures/angular-commons';
import {GroupsService} from '../../groups/services/groups.service';


export interface TaskHistoryItem {
  coment?: TaskComment;
  event?: TaskEvent;
}

export function userDisplayName(person: Person): string {
  return (!!person) ? person.vorname + ' ' + person.nachname : 'System';
}

@Injectable()
export class HistoryItemService {
  constructor(readonly personService: PersonService,
              readonly groupService: GroupsService) {
  }

  historyItemDate(historyItem: TaskHistoryItem): Date {
    if (!historyItem) {
      return null;
    }

    if (historyItem.coment) {
      return historyItem.coment.time;
    }
    if (historyItem.event) {
      return historyItem.event.time;
    }
    return null;
  }

  getPersonIdFromHistoryItem(historyItem: TaskHistoryItem): number {
    if (!historyItem) {
      return null;
    }

    if (historyItem.coment && historyItem.coment.personId) {
      return Number(historyItem.coment.personId);
    }
    if (historyItem.event && historyItem.event.personId) {
      return Number(historyItem.event.personId);
    }
    return null;
  }

  historyItemMessage(historyItem: TaskHistoryItem): Observable<string> {
    if (!historyItem) {
      return null;
    }

    if (historyItem.coment) {
      return of(historyItem.coment.message);
    }
    if (historyItem.event) {
      return this.taskEventMessage(historyItem.event);
    }
    return null;
  }

  taskEventMessage(event: TaskEvent): Observable<string> {
    if (!event) {
      return null;
    }

    return this.personService.getPerson(event.personId).pipe(
      switchMap(user => this.resolveTaskEventMessage(event, user))
    );

  }

  private resolveTaskEventMessage(event: TaskEvent, user: Person): Observable<string> {
    let result = '';
    switch (event.operationType) {
      case 'Claim':
        if (event.values && event.values.length > 0) {
          result = userDisplayName(user) + ' hat die Aufgabe angenommen.';
        } else {
          result = userDisplayName(user) + ' hat die Aufgabe zurückgegeben.';
        }
        break;

      case 'AddGroupLink':
      case 'DeleteGroupLink':

        // because the operations needed to display groups is so large, we bundle adding and deleting groups
        let singleGroupString = ' hat die Aufgabe an Gruppe ';
        let pluralGroupString = ' hat die Aufgabe an die Gruppen ';
        let groupStringEnd = ' zugewiesen.';
        if (event.operationType === 'DeleteGroupLink') {
          singleGroupString = ' hat die Zuweisung für die Gruppe ';
          pluralGroupString = ' hat die Zuweisung für die Gruppen ';
          groupStringEnd = ' entfernt.';
        }

        return this.groupService.getGroups().pipe(
          map(userGroups => {
            if (event.values && event.values.length === 1) {
              const group = userGroups.find(e => e.id.toString() === event.values[0].toString());
              return userDisplayName(user) + singleGroupString + group.bezeichnung + groupStringEnd;
            } else {
              let groupsString = '';
              event.values.forEach((val, index) => {
                const group = userGroups.find(e => e.id.toString() === val.toString());

                if (index !== 0) {
                  if (index === event.values.length - 1) {
                    groupsString = groupsString + ' und ';
                  } else {
                    groupsString = groupsString + ', ';
                  }
                }

                groupsString = groupsString + group.bezeichnung;
              });
              return userDisplayName(user) + pluralGroupString + groupsString + groupStringEnd;
            }
          })
        );

      case 'AddUserLink':
      case 'DeleteUserLink':

        // because the operations needed to display other Users is so large, we bundle adding and deleting users
        let singleUserString = ' hat die Aufgabe an den Benutzer ';
        let pluralUserString = ' hat die Aufgabe an die Benutzer ';
        let userStringEnd = ' zugewiesen.';
        if (event.operationType === 'DeleteUserLink') {
          singleUserString = ' hat die Zuweisung für Benutzer ';
          pluralUserString = ' hat die Zuweisung für die Benutzer ';
          userStringEnd = ' entfernt.';
        }

        if (event.values && event.values.length === 1) {
          return this.personService.getPerson(event.values[0]).pipe(
            map(otherUser => userDisplayName(user) + singleUserString +
              userDisplayName(otherUser) +
              userStringEnd
            )
          );
        } else {
          // get users
          return combineLatest(event.values.map(otherUserID => this.personService.getPerson(otherUserID)))
            .pipe(
              map((otherUsers) => {
                const otherUsersString = otherUsers.sort((a, b) => {
                  if (a.nachname.toLowerCase() > b.nachname.toLowerCase()) {
                    return 1;
                  }
                  if (a.nachname.toLowerCase() < b.nachname.toLowerCase()) {
                    return -1;
                  }
                  return 0;
                })
                  .slice(0, otherUsers.length - 1)
                  .map((u) => userDisplayName(u))
                  .join(', ') + ' und ' + userDisplayName(otherUsers[otherUsers.length - 1]);

                return userDisplayName(user) + pluralUserString + otherUsersString + userStringEnd;
              })
            );
        }

      case 'SetVariable':
        result = userDisplayName(user) + ' hat die Aufgabe gespeichert.';
        break;
    }

    return of(result);
  }

}
