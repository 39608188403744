import {ChangeDetectionStrategy, Component, Injectable, OnDestroy, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
  CommentTaskAction,
  CompleteWizardAction,
  GotoStepAction,
  NextStepAction,
  PreviousStepAction,
  UnclaimTaskAction,
  UpdateTaskVariablesAction,
  UpdateWizardTaskAction
} from '../../actions/wizard.actions';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {cloneDeep, isArray, isNil, mergeWith} from 'lodash-es';
import {CancelTaskChoice, CancelTaskWizardComponent} from '../cancel-task-wizard/cancel-task-wizard.component';
import {canBack, stepsOfTask, willComplete} from '../../reducers/wizard';
import {Task} from '../../../shared/common/services/task.service';
import {Step, StepSlug} from '../../step-definitions';
import {Observable, Subject} from 'rxjs';
import {CanComponentDeactivate} from '../../services/can-deactivate-guard.service';
import {ConfirmBackComponent} from '../confirm-back/confirm-back.component';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {ReworkDialogComponent} from '../data-comparison/task-reworks/rework-dialog/rework-dialog.component';
import {Title} from '@angular/platform-browser';
import {TaskStepsControlComponent} from '../task-steps-control/task-steps-control.component';
import {WizardFormService} from '../../services/wizard-form.service';
import {ConfirmDeleteTaskAction, ConfirmResetContractAction} from '../../actions/inbox';
import {Contract, Person} from '@taures/angular-commons';
import {CustomerService} from '../../services/customer.service';


/** Error when invalid control is dirty, touched, or submitted. */
@Injectable()
export class ShowOnInvalidErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control && control.invalid;
  }
}

@Component({
  selector: 'app-task-wizard-container',
  templateUrl: 'task-wizard-container.component.html',
  styleUrls: ['task-wizard-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnInvalidErrorStateMatcher},
  ]
})
export class TaskWizardContainerComponent implements OnDestroy, CanComponentDeactivate {
  currentStep: Observable<Step>;
  stepsAvailable = StepSlug;
  formGroup: UntypedFormGroup;
  customer: Observable<Person>;
  task: Observable<Task>;
  highlightInvalid = new Subject<boolean>();
  @ViewChild('taskStepsControl')
  taskStepsControl: TaskStepsControlComponent;
  private isCompleted = false;
  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<fromRoot.State>,
              private fb: UntypedFormBuilder,
              private dialog: MatDialog,
              private titleService: Title,
              private customerService: CustomerService,
              private snackBar: MatSnackBar,
              private formService: WizardFormService) {
    this.task = this.store
      .pipe(
        select(fromRoot.getWizardTask),
        tap(t => this.setTitle(t))
      );
    this.currentStep = this.store.pipe(select(fromRoot.getCurrentStep));
    this.customer = this.store.pipe(select(fromRoot.getWizardCustomer));

    // create and update the form every time the wizard state changes
    this.store.pipe(
      select(fromRoot.getWizardState),
      takeUntil(this.destroy),
      filter(state => !!state.task)
    ).subscribe(state => {
      this.createForm(state.currentStep, state.task, state.customer)
    });
  }

  get variables() {
    return this.formGroup ? this.formGroup.get('variables') : null;
  }

  hasBack(task: Task, step: Step) {
    return canBack(task, step);
  }

  isComplete(task: Task, step: Step) {
    return willComplete(task, step);
  }

  openReworkDialog(task: Task, currentStep: Step) {
    this.dialog.open(ReworkDialogComponent, {
      width: '600px',
      data: {
        contract: currentStep.slug === 'customer-contract-single'
      }
    })
      .afterClosed()
      .subscribe(event => {
        if (event) {
          const taskVariables = this.getTaskVariables(task);
          const reworks = taskVariables.reworks || [];
          taskVariables.reworks = [...reworks, event];
          this.store.dispatch(new UpdateWizardTaskAction(taskVariables));
        }
      });
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  canDeactivate(): boolean | Observable<boolean> {
    // ask for confirmation when the formgroup is changed and the task is not completed
    return this.formGroup.pristine || this.isCompleted ? true : this.dialog.open(ConfirmBackComponent)
      .afterClosed().pipe(map(result => !!result));
  }

  onCancel(task: Task) {
    this.dialog.open(CancelTaskWizardComponent, {data: {valid: this.formGroup.valid}})
      .afterClosed()
      .subscribe(result => {
        if (typeof result === 'object' && !isNil(result)) {
          switch (result.choice) {
            case CancelTaskChoice.DISCARD_UNCLAIM:
              this.unclaim(task, result.comment);
              break;
            case CancelTaskChoice.SAVE_UNCLAIM:
              this.saveUnclaim(task, result.comment);
              break;
            case CancelTaskChoice.SAVE_KEEP:
              this.saveKeep(task, result.comment);
              break;
            case CancelTaskChoice.DISCARD_KEEP:
              this.keep(task, result.comment);
              break;
            case CancelTaskChoice.DISCARD_DELETE:
              this.removeTask(task);
              break;
          }
        }
      });
  }

  onBack(task: Task) {
    this.highlightInvalid.next(false);
    this.store.dispatch(new PreviousStepAction(this.getTaskVariables(task)));
  }

  onNext(task: Task, currentStep: Step, customer: Person) {
    const taskVariables = this.getTaskVariables(task);
    if (this.isComplete(task, currentStep)) {
      // check the validity of all userGroups again
      const isValid = stepsOfTask(task).reduce((prev, st) =>
        prev && this.formService.buildStepGroup(st, task.formKey, taskVariables, customer).valid, true);
      if (isValid) {
        this.highlightInvalid.next(false);
        this.isCompleted = true;
        this.store.dispatch(new CompleteWizardAction(taskVariables));
      } else {
        if (this.taskStepsControl) {
          this.taskStepsControl.highlightInvalid();
        }
        this.snackBar.open('Bitte korrigieren Sie die fehlerhaften Eingaben.', null, {duration: 3000});
      }
    } else {
      this.highlightInvalid.next(false);
      this.store.dispatch(new NextStepAction({variables: taskVariables, customer}));
    }
  }

  onGotoStep(step: Step, task: Task) {
    this.highlightInvalid.next(false);
    this.store.dispatch(new GotoStepAction({variables: this.getTaskVariables(task), step}));
  }

  onResetSubContract(contractId: number, task: Task) {
    this.store.dispatch(new ConfirmResetContractAction({
      taskId: task.id,
      variables: this.getTaskVariables(task),
      contractId
    }));
  }

  getStepsOfTask(task: Task) {
    return stepsOfTask(task);
  }

  openInCrm(customerId: number, formKey: string) {
    this.customerService.openInCrm(customerId, formKey);
  }

  private keep(task: Task, comment: string) {
    this.store.dispatch(new CommentTaskAction({taskId: task.id, comment}));
  }

  private saveKeep(task: Task, comment: string) {
    this.store.dispatch(new UpdateTaskVariablesAction({
      taskId: task.id,
      variables: this.getTaskVariables(task),
      comment
    }));
  }

  private unclaim(task: Task, comment: string) {
    this.store.dispatch(new UnclaimTaskAction({taskId: task.id, comment}));
  }

  private saveUnclaim(task: Task, comment: string) {
    this.store.dispatch(new UnclaimTaskAction({
      taskId: task.id,
      comment,
      variables: this.getTaskVariables(task)
    }));
  }

  private removeTask(task: Task) {
    this.store.dispatch(new ConfirmDeleteTaskAction(task));
  }

  private setTitle(task: Task) {
    const customer = task.variables.customer;
    const name = customer && customer.nachname && customer.vorname ? `${customer.nachname}, ${customer.vorname} -` : '';
    this.titleService.setTitle(`${task.name} - ${name} Taures`);
  }

  private createForm(step: Step, task: Task, customer: Person) {
    const variableGroup = this.formService.buildStepGroup(step, task.formKey, task.variables, customer);
    this.formGroup = this.fb.group(
      {
        variables: variableGroup,
        version: task.variables.version
      });
    this.formGroup.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true
    });
  }

  private getTaskVariables(task: Task) {
    const variables = cloneDeep(task.variables);
    const formModel = this.formGroup.getRawValue();

    mergeWith(variables, formModel.variables, (objValue, srcValue, key) => {
        if (isArray(objValue) && key !== 'vertraege') {
          return srcValue;
        }
        if (key === 'vertraege') {
          // only keep the old contracts - the new one will always come from aza
          return mergeWith((objValue as Contract[]).filter(c => c.id > 0), srcValue);
        }
        return undefined;
      }
    );
    return variables;
  }
}
