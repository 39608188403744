import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface BrokerContractItem {
  datum?: Date;
  gekuendigt?: Date;
  bemerkung?: string;
  maklerthema: number;
  manuallyAdded: boolean;
}

export enum BrokerContractConsent { NO = 'NO', YES = 'YES', RESTRICTED = 'RESTRICTED' }

export interface ContactPermission {
  consent: BrokerContractConsent;
  restrictTo?: string;
}

export interface BrokerContract {
  version: string;
  created: Date;
  serviceContractDate: Date;
  creditBrokerageAgreementDate: Date;
  items: BrokerContractItem[];
  fixedLine: ContactPermission;
  mobile: ContactPermission;
  email: ContactPermission;
  supplement?: boolean;
  consentChanged?: boolean;
  signatureDate?: Date;
  filename?: string;
}

export const BrokerContractTypes  = {
  mvt: 'Maklervertrag',
  anl: 'Anlage zum Maklervertrag'
};


@Injectable()
export class BrokerContractService {
  constructor(private http: HttpClient) {
  }


  analyzePDF(pdf: File): Observable<BrokerContract> {
    return this.http.post<any>('/document-generator/api/document', pdf)
      .pipe(
        map(data => {
          if (!data) {
            return null;
          }
          return ({
              ...data,
              created: new Date(data.created),
              items: data.items.filter(item => item.selected)
                .map(json => ({
                  maklerthema: json.id,
                  datum: json.validFrom ? new Date(json.validFrom) : null,
                  gekuendigt: json.validTo ? new Date(json.validTo) : null,
                  bemerkung: json.note,
                  manuallyAdded: false
                }))
            });
        }));
  }

  getBrokerContractItems(customerId: number): Observable<BrokerContractItem[]> {
    return this.http.get<any[]>(`t-it-s/rest/crm/v1.0/person/${customerId}/brokerContractItems`)
      .pipe(map(items => items
        .filter(json => !!json.datum)
        .map(json => ({
          maklerthema: json.maklerthema.id,
          datum: new Date(json.datum),
          gekuendigt: json.gekuendigt ? new Date(json.gekuendigt) : null,
          bemerkung: json.bemerkung,
          manuallyAdded: false
        }))));
  }
}
