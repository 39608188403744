import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Tariff} from '../../../../services/tariff.service';
import {formatDate} from '@angular/common';
import {Contract} from '@taures/angular-commons';

@Component({
  selector: 'app-single-contract-small',
  templateUrl: 'single-contract-small.component.html',
  styleUrls: ['single-contract-small.component.scss']
})
export class SingleContractSmallComponent implements OnInit {
  @Output() editContract: EventEmitter<void> = new EventEmitter();
  @Output() createBpEvent: EventEmitter<any> = new EventEmitter();
  @Input() tariffs: Tariff[];
  @Input() control: UntypedFormGroup;
  @Input() title: string;
  @Input() buendelpolicen: Contract[];
  @Input() showBuendelpoliceField = true;
  @Input() isHauptvertrag: boolean;

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  get insurerName() {
    const t = this.tariff;
    return t ? t.gesellschaft.kurzname : this.control.controls.gesellschaftsname.value;
  }

  get tariffName() {
    const t = this.tariff;
    return t ? t.tarifbez : this.control.controls.tarifBezeichnung.value;
  }

  get tariffType() {
    const t = this.tariff;
    return t ? t.vertragart.bezeichnung : this.control.controls.sparte.value;
  }

  get tariff() {
    const tariffId = this.control.controls.tarif.value;
    return this.tariffs ? this.tariffs.find(t => t.id === tariffId) : null;
  }

  ngOnInit(): void {
    this.control.get('buendelpolice').valueChanges.subscribe(id => {
      if (id === null) {
        this.control.get('hauptvertrag').enable();
      } else {
        this.control.get('hauptvertrag').disable();
      }
    });
  }

  edit() {
    this.editContract.emit();
  }

  inBuendelpolice(): boolean {
    return this.control.get('buendelpolice').value;
  }

  createBuendelpolice(): void {
    this.createBpEvent.emit(this.control.value);
  }

  getBpLabel(bpContract) {
    const tarif = this.tariffs ? this.tariffs.find(t => t.id === bpContract.tarif) : null;
    return tarif ? tarif.tarifbez + ' vom ' + formatDate(bpContract.antragsdatum, 'dd.MM.yyyy', this.locale) :
      'Keine Bezeichnung vorhanden';
  }

  showSelectBuendelpolice() {
    return this.showBuendelpoliceField && !this.isHauptvertrag && !this.control.get('hauptvertrag').value;
  }

  showCreateBuendelpolice() {
    return !this.inBuendelpolice() && this.control.valid && !this.isHauptvertrag && !this.control.get('hauptvertrag').value;
  }

  getFilteredBuendelpolicen() {
    const currentTarif = this.tariffs ? this.tariffs.find(t => t.id === this.control.get('tarif').value) : null;
    return this.buendelpolicen.filter(buendelpolice => {
      const bpTarif = this.tariffs ? this.tariffs.find(t => t.id === buendelpolice.tarif) : null;
      return bpTarif && currentTarif && bpTarif.gesellschaft.id === currentTarif.gesellschaft.id;
    });
  }
}
