<h1 mat-dialog-title>Details</h1>
<mat-dialog-content class="flex flex-column gap-2 pb-2">

  <div *ngIf="this.task.variables['initiator'] | personName: false| async as initiatorName; else initiatorLoading">
    <b>Initiator:</b> {{initiatorName}}
  </div>
  <ng-template #initiatorLoading>
    <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
  </ng-template>

  <ng-container *ngIf="!!task.assignee">
    <div *ngIf="task.assignee | personName: false| async as assigneeName; else assigneeLoading">
      <b>Aktueller Bearbeiter:</b> {{assigneeName}}
    </div>
    <ng-template #assigneeLoading>
      <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
    </ng-template>
  </ng-container>

  <div *ngIf="customerName | async as cn; else loading">
    <b>Kunde:</b> {{cn}}
  </div>
  <ng-template #loading>
    <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
  </ng-template>

  <div *ngIf="{contracts: newContracts | async, tariffs: tariffs | async} as contractsTariffs" class="flex flex-column gap-2">
    <b>Verträge:</b>
    <mat-card *ngFor="let contract of contractsTariffs.contracts" class="p-3">
      <div class="flex flex-column gap-2">
        <div><b>Gesellschaft:</b> {{getInsurerName(contract, contractsTariffs.tariffs) }}</div>
        <div><b>Sparte:</b> {{ getSparteName(contract, contractsTariffs.tariffs)}}</div>
        <div><b>Beginn:</b> {{(contract.beginlaufzeit | date: 'dd.MM.yyyy')}}</div>
      </div>
    </mat-card>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button type="button" color="primary" (click)="closeDialog()">Schließen</button>
</mat-dialog-actions>
