<div class="mat-elevation-z1 border-4px overflow-hidden flex-grow-1">
  <div class="tr-inbox-task-card-header p-3 taures-secondary-blue flex justify-content-between">
    <div>{{task.name}}</div>
    <div>{{task.created | date: 'dd.MM.yyyy HH:mm' }}</div>
  </div>
  <div class="p-4 flex flex-wrap">
    <div class="w-4 flex flex-column gap-2">
      <div class="flex flex-column" *ngIf="this.task.variables['initiator'] | personName: false | async as initiatorName; else initiatorLoading">
        <span><b>Initiator:</b></span>
        <span>{{initiatorName}}</span>
      </div>
      <ng-template #initiatorLoading>
        <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
      </ng-template>

      <div class="flex flex-column" *ngIf="customerName | async as cn; else customerLoading">
        <span><b>Kunde:</b></span>
        <span>{{cn}}</span>
      </div>
      <ng-template #customerLoading>
        <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
      </ng-template>
    </div>

    <div class="w-4 flex flex-column gap-2">
      <ng-content select="[tr-task-type-details]"></ng-content>
    </div>

    <div class="w-4 flex flex-column gap-2">
      <ng-container *ngIf="!!task.assignee">
        <div class="flex flex-column" *ngIf="task.assignee | personName: false| async as assigneeName; else assigneeLoading">
          <span><b>Aktueller Bearbeiter:</b></span>
          <span>{{assigneeName}}</span>
        </div>
        <ng-template #assigneeLoading>
          <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
        </ng-template>
      </ng-container>

      <div class="flex flex-column" *ngIf="(candidateUsers | async)?.length > 0">
        <span><b>Zugewiesene Benutzer:</b></span>
        <span>{{formatUsersList(candidateUsers | async)}}</span>
      </div>

      <div class="flex flex-column" *ngIf="(candidateGroups | async )?.length > 0">
        <span><b>Zugewiesene Gruppen:</b></span>
        <span>{{formatGroupsList(candidateGroups | async)}}</span>
      </div>

    </div>
  </div>
  <div class="m-2 ml-0 flex flex-wrap gap-1 align-items-center justify-content-end">
    <button *ngIf="canDelete()" mat-icon-button type="button" matTooltip="Aufgabe löschen" (click)="onDeleteTask()">
      <mat-icon>delete</mat-icon>
    </button>

    <button mat-icon-button type="button" (click)="onViewComment()" matTooltip="Verlauf anzeigen" >
      <mat-icon [matBadge]="task.commentCount" [matBadgeHidden]="!task.commentCount">forum</mat-icon>
    </button>
    <button mat-button type="button" *ngIf="task.formKey === taskDefinitionEnum.FollowUpAgent" (click)="openTaskDetails()">Details</button>
    <button mat-button type="button" *ngIf="canBeTransfered()" (click)="openTransferCustomer()">Kunde übertragen</button>
    <button mat-button type="button" (click)="openAssignTask()">Zuweisen</button>
    <button *ngIf="!task.assignee" mat-raised-button type="button" color="primary" (click)="onClaim()">Annehmen</button>
    <button *ngIf="task.assignee && (isAdmin  || isAssignedToCurrentUser)" mat-button type="button" (click)="onUnclaim()">Zurückgeben</button>
    <button *ngIf="isAssignedToCurrentUser" mat-raised-button type="button" color="primary" (click)="onEdit()">Bearbeiten</button>
  </div>

</div>
