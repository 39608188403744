<div class="flex flex-column gap-2">
  <h3 style="font-weight: bold">Dokumente</h3>
  <div [formGroup]="documentsArray" class="flex flex-column gap-2">
    <div *ngFor="let document of documentsArray.controls; let i=index" class="flex flex-column mat-elevation-z1 p-4 mr-2 border-4px">
      <app-document-form [group]="document" [documentTypes]="documentTypes"></app-document-form>
      <div class="flex justify-content-end align-items-center">
        <button type="button" mat-icon-button (click)="onRemove(i)" matTooltip="Dokument löschen">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <app-documents-dropzone [documentArray]="documentsArray"></app-documents-dropzone>
</div>
