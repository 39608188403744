<div class="pt-3" [formGroup]="variables">

  <div *ngIf="hasDuplicatePerson()" class="invalid">
    Eine Person mit dem gleichen Namen und Geburtsdatum (ID {{duplicatePersonId}}) existiert bereits im System.
  </div>

  <div class="flex flex-column gap-2 pt-3" formGroupName="customer">

    <div class="flex gap-4" *ngIf="!hasSoftfairCustomer()">
      <div class="w-{{rowWidths[0]}} list-item-title"></div>
      <div class="w-{{rowWidths[2]}} list-item-title">Ergebnis</div>
      <div class="w-{{rowWidths[3]}} list-item-title-right">Bestand</div>
    </div>

    <div class="flex gap-4" *ngIf="hasSoftfairCustomer()">
      <div class="w-{{rowWidths[0]}} list-item-title"></div>
      <div class="w-{{rowWidths[1]}} list-item-title-softfair-left">KonzeptButler</div>
      <div class="w-{{rowWidths[2]}} list-item-title">Ergebnis</div>
      <div class="w-{{rowWidths[3]}} list-item-title-right">Bestand</div>
    </div>

    <app-basic-data-form-field
      [title]="'Anrede&nbsp;*'"
      [resultFormControl]="variables.get('customer.anrede')"
      [serverValue]="(customer)?.anrede"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <mat-select formControlName="anrede">
          <mat-option *ngFor="let option of salutations; let i=index;" [value]="option">{{salutations[i]}}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Titel'"
      [resultFormControl]="variables.get('customer.titel')"
      [serverValue]="(customer)?.titel"
      [softfairValue]="variables.get('softfairCustomer.titel')?.value"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <mat-select formControlName="titel">
          <mat-option *ngFor="let option of titles; let i=index;" [value]="option">{{titles[i]}}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Vorname&nbsp;*'"
      [resultFormControl]="variables.get('customer.vorname')"
      [serverValue]="(customer)?.vorname"
      [softfairValue]="variables.get('softfairCustomer.vorname')?.value"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <input matInput type="text" formControlName="vorname" appTrim>
      </mat-form-field>
    </app-basic-data-form-field>


    <app-basic-data-form-field
      [title]="'Nachname&nbsp;*'"
      [resultFormControl]="variables.get('customer.nachname')"
      [serverValue]="(customer)?.nachname"
      [softfairValue]="variables.get('softfairCustomer.nachname')?.value"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <input matInput type="text" formControlName="nachname" appTrim>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Geburtsname'"
      [resultFormControl]="variables.get('customer.geburtsname')"
      [serverValue]="(customer)?.geburtsname"
      [softfairValue]="variables.get('softfairCustomer.geburtsname')?.value"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <input matInput type="text" formControlName="geburtsname" appTrim>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Geburtsdatum&nbsp;*'"
      [resultFormControl]="variables.get('customer.geburtsdatum')"
      [serverValue]="(customer)?.geburtsdatum"
      [serverValueFormatted]="(customer)?.geburtsdatum | date: 'dd.MM.yyyy'"
      [softfairValue]="variables.get('softfairCustomer.geburtsdatum')?.value"
      [softfairValueFormatted]="hasSoftfairCustomer() ? (variables.get('softfairCustomer.geburtsdatum')?.value | date: 'dd.MM.yyyy') : null"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <input matInput [matDatepicker]="valuePicker" formControlName="geburtsdatum" [min]="minBirthday" [max]="maxBirthday">
        <mat-datepicker-toggle matSuffix [for]="valuePicker"></mat-datepicker-toggle>
        <mat-datepicker #valuePicker></mat-datepicker>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Geburtsort'"
      [resultFormControl]="variables.get('customer.geburtsort')"
      [serverValue]="(customer)?.geburtsort"
      [softfairValue]="variables.get('softfairCustomer.geburtsort')?.value"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <input matInput type="text" formControlName="geburtsort" appTrim>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Nationalität&nbsp;*'"
      [resultFormControl]="variables.get('customer.nationalitaet')"
      [serverValue]="(customer)?.nationalitaet"
      [serverValueFormatted]="countryOfCustomer(customer)"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <mat-select formControlName="nationalitaet">
          <mat-option *ngFor="let country of sortedCountries" [value]="country.code">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Sprache'"
      [resultFormControl]="variables.get('customer.sprache')"
      [serverValue]="(customer)?.sprache"
      [serverValueFormatted]="languageOfCustomer(customer)"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <mat-select formControlName="sprache">
          <mat-option *ngFor="let lang of sortedLanguages" [value]="lang.code">
            {{ lang.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-basic-data-form-field>

    <app-basic-data-form-field
      [title]="'Höchster Bildungsgrad'"
      [resultFormControl]="variables.get('customer.bildungsabschluss')"
      [serverValue]="(customer)?.bildungsabschluss"
      [serverValueFormatted]="educationOfCustomer((customer), (educationTypes | async))"
      [rowWidths]="rowWidths"
      [hasSoftfairCustomer]="hasSoftfairCustomer()">
      <mat-form-field class="flex-auto">
        <mat-select formControlName="bildungsabschluss">
          <mat-option *ngFor="let option of educationOptionsData(educationTypes | async); let i=index;" [value]="option">{{educationOptionsDisplay(educationTypes | async)[i]}}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-basic-data-form-field>
  </div>


</div>
