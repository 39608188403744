import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {Task, TaskDefinition} from '../../../shared/common/services/task.service';

@Component({
  selector: 'app-wizard-complete-success',
  templateUrl: './wizard-complete-success.component.html',
  styleUrls: ['./wizard-complete-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false
})
export class WizardCompleteSuccessComponent {

  taskDefinition = TaskDefinition;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Task, private matSnackBarRef: MatSnackBarRef<WizardCompleteSuccessComponent>) {
  }

  dismiss() {
    this.matSnackBarRef.dismiss();
  }
}
