import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';

import {HistoryItemService, TaskHistoryItem} from '../../services/history-item.service';
import {Task, TaskService} from '../../../shared/common/services/task.service';

type TaskType = Task;


@Component({
  selector: 'app-view-history',
  templateUrl: 'view-history.component.html',
  styleUrls: ['view-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewHistoryComponent {
  historyItems: Observable<TaskHistoryItem[]> = of([]);

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: TaskType,
              public dialogRef: MatDialogRef<ViewHistoryComponent>,
              private inboxService: TaskService,
              private historyService: HistoryItemService) {
    this.historyItems =
      forkJoin([this.inboxService.getComments(data.id),
        this.inboxService.getTaskEvents(data.id)])
        .pipe(map(([comments, events]) => {
          const historyList = [];
          events.forEach(task => historyList.push({event: task}));
          comments.forEach(comment => historyList.push({coment: comment}));
          return historyList.sort((a, b) => {
            if (this.historyService.historyItemDate(a) > this.historyService.historyItemDate(b)) {
              return -1;
            } else if (this.historyService.historyItemDate(a) < this.historyService.historyItemDate(b)) {
              return 1;
            } else {
              return 0;
            }
          });
        }));
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
