/* tslint:disable:no-console */
import {ConnectableObservable, Observable, OperatorFunction, ReplaySubject, throwError} from 'rxjs';
import {catchError, multicast} from 'rxjs/operators';
import {environment} from '../../environments/environment';

interface CacheEntry<T> {
  expiry: number;
  observable: Observable<T>;
}

const DEFAULT_MAX_AGE = 300000;
const globalCache: { [key: string]: CacheEntry<any>; } = {};

/**
 * Cache the value under the given key
 * @param key the cache key
 * @param maxAge the maximum age in millis. Use -1 to skip cache
 */
export function cache<T>(key: string, maxAge: number = DEFAULT_MAX_AGE): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return new Observable(observer => {
      const cached = globalCache[key];
      if (cached && cached.expiry >= Date.now()) {
        if (!environment.production) {
          console.log(`%cGetting from cache '${key}'`, 'color: green');
        }
        cached.observable.subscribe(observer);
      } else {
        if (!environment.production) {
          console.log(`%c Calling api for '${key}'`, 'color: purple');
          console.log(`%cNumber of cache entries ${Object.keys(globalCache).length}`, 'color: green');
        }
        const add = source.pipe(multicast(new ReplaySubject(1))) as ConnectableObservable<T>;
        globalCache[key] = {observable: add, expiry: Date.now() + maxAge};
        add.connect();
        add.pipe(
          catchError(err => {
            delete globalCache[key];
            return throwError(err);
          })
        ).subscribe(observer);
      }
    });
  };
}

