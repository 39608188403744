import {FilterModel, Task, TaskOrder} from '../../shared/common/services/task.service';
import {
  Actions,
  DELETE_TASK_SUCCESS,
  LOAD_TASK_SUCCESS,
  LOAD_TASKS, LOAD_TASKS_FAILURE,
  LOAD_TASKS_SUCCESS,
  LOADING_TASKS
} from '../actions/inbox';

export interface State {
  tasks: Array<Task>;
  filter: FilterModel | null;
  start: number;
  stop: number;
  total: number;
  loading: boolean;
}

export const initialState: State = {
  tasks: [],
  filter: {limit: 5, orderBy: TaskOrder.TASK_CREATE_TIME_ASC},
  start: 0,
  stop: 0,
  total: 0,
  loading: false
};

export function reducer(state = initialState, action: Actions) {
  switch (action.type) {
    case LOAD_TASKS:
      if (action.payload) {
        return {...state, filter: action.payload};
      }
      return state;
    case LOADING_TASKS:
      return {...state, loading: true};
    case LOAD_TASKS_SUCCESS:
      return {...state, ...action.payload, loading: false};
    case LOAD_TASKS_FAILURE:
      return {...state, loading: false};
    case LOAD_TASK_SUCCESS:
      let tasks;
      const taskIndex = state.tasks.findIndex(task => task.id === action.payload.id);
      if (taskIndex === -1) {
        tasks = [...state.tasks, action.payload];
      } else {
        tasks = state.tasks.map(task => {
          if (task.id === action.payload.id) {
            return action.payload;
          }
          return task;
        });
      }
      return {...state, tasks};
    case DELETE_TASK_SUCCESS:
      return {...state, tasks: state.tasks.filter(task => task.id !== action.payload.id)};
    default:
      return state;
  }
}

export const getTasks = (state: State) => state.tasks;
export const hasTasksLoading = (state: State) => state.loading;
export const getPaging = (state: State) => ({total: state.total, start: state.start, stop: state.stop});
export const getFilter = (state: State) => state.filter;
