<div [formGroup]="variables" class="flex flex-column gap-3">
  <div formArrayName="reworks" class="flex flex-wrap gap-3">

    <div class="flex flex-column mat-elevation-z1 mb-2 border-4px overflow-hidden rework-width" *ngFor="let rework of getNewReworks(); let i=index">
      <div class="flex gap-2 p-2 taures-secondary-blue">
        Nachbearbeitung
      </div>
      <div class="p-4 overflow-hidden">
        <app-rework-form [control]="rework"></app-rework-form>
        <div class="flex justify-content-end">
          <button type="button" mat-icon-button (click)="removeRework(rework)" matTooltip="Nachbearbeitung löschen">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <button type="button" style="color:white" mat-fab (click)="addRework()" matTooltip="Nachbearbeitung hinzufügen">
    <mat-icon>add</mat-icon>
  </button>

  <h3 class="mb-0">Alle Nachbearbeitungen für diesen Kunden:</h3>
  <div *ngIf="crmReworks | async as crmRW; else reworksLoading" class="flex flex-wrap gap-3">
    <app-rework-readonly class="w-23rem mat-elevation-z1 mb-2 border-4px overflow-hidden" *ngFor="let crmRework of crmRW"
                         (modifiedRework)="addReworkToVariables($event)"
                         [contracts]="contracts | async"
                         [tariffs]="tariffs | async"
                         [reworkForm]="crmRework"
                         [customerId]="this.variables.get('customer.id').value"></app-rework-readonly>
  </div>
  <ng-template #reworksLoading>
    <mat-progress-spinner mode="indeterminate" [diameter]="32" [strokeWidth]="2"></mat-progress-spinner>
  </ng-template>
</div>
