export const LANGUAGE_CODES = [{
  code: 'de',
  name: 'Deutsch'
}, {code: 'ab', name: 'Abchasisch'}, {code: 'aa', name: 'Afar'}, {
  code: 'af',
  name: 'Afrikaans'
}, {code: 'sq', name: 'Albanisch'}, {code: 'am', name: 'Amharisch'}, {
  code: 'ar',
  name: 'Arabisch'
}, {code: 'hy', name: 'Armenisch'}, {code: 'az', name: 'Aserbaidschanisch'}, {
  code: 'as',
  name: 'Assamesisch'
}, {code: 'ay', name: 'Aymara'}, {code: 'ba', name: 'Baschkirisch'}, {
  code: 'eu',
  name: 'Baskisch'
}, {code: 'be', name: 'Belorussisch'}, {code: 'bn', name: 'Bengalisch'}, {
  code: 'bh',
  name: 'Biharisch'
}, {code: 'bi', name: 'Bislamisch'}, {code: 'br', name: 'Bretonisch'}, {
  code: 'bg',
  name: 'Bulgarisch'
}, {code: 'my', name: 'Burmesisch'}, {code: 'zh', name: 'Chinesisch'}, {
  code: 'dz',
  name: 'Dzongkha, Bhutani'
}, {code: 'da', name: 'Dänisch'}, {
  code: 'en',
  name: 'Englisch'
}, {code: 'eo', name: 'Esperanto'}, {code: 'et', name: 'Estnisch'}, {
  code: 'fj',
  name: 'Fiji'
}, {code: 'fi', name: 'Finnisch'}, {code: 'fr', name: 'Französisch'}, {
  code: 'fy',
  name: 'Friesisch'
}, {code: 'fo', name: 'Färöisch'}, {code: 'gl', name: 'Galizisch'}, {
  code: 'ka',
  name: 'Georgisch'
}, {code: 'el', name: 'Griechisch'}, {code: 'gn', name: 'Guarani'}, {
  code: 'gu',
  name: 'Gujaratisch'
}, {code: 'ha', name: 'Haussa'}, {code: 'he', name: 'Hebräisch'}, {
  code: 'iw',
  name: 'Hebräisch (veraltet, nun: he)'
}, {code: 'hi', name: 'Hindi'}, {code: 'nl', name: 'Holländisch'}, {
  code: 'id',
  name: 'Indonesisch'
}, {code: 'ia', name: 'Interlingua'}, {code: 'ie', name: 'Interlingue'}, {
  code: 'iu',
  name: 'Inuktitut (Eskimo)'
}, {code: 'ik', name: 'Inupiak'}, {code: 'ga', name: 'Irisch'}, {
  code: 'is',
  name: 'Isländisch'
}, {code: 'it', name: 'Italienisch'}, {code: 'ja', name: 'Japanisch'}, {
  code: 'jv',
  name: 'Javanisch'
}, {code: 'yi', name: 'Jiddish'}, {code: 'ji', name: 'Jiddish (veraltet, nun: yi)'}, {
  code: 'yo',
  name: 'Joruba'
}, {code: 'kl', name: 'Kalaallisut (Grönländisch)'}, {code: 'km', name: 'Kambodschanisch'}, {
  code: 'kn',
  name: 'Kannada'
}, {code: 'kk', name: 'Kasachisch'}, {code: 'ks', name: 'Kaschmirisch'}, {
  code: 'ca',
  name: 'Katalanisch'
}, {code: 'rw', name: 'Kijarwanda'}, {code: 'ky', name: 'Kirgisisch'}, {
  code: 'rn',
  name: 'Kirundisch'
}, {code: 'ko', name: 'Koreanisch'}, {code: 'co', name: 'Korsisch'}, {
  code: 'hr',
  name: 'Kroatisch'
}, {code: 'ku', name: 'Kurdisch'}, {code: 'lo', name: 'Laotisch'}, {
  code: 'la',
  name: 'Lateinisch'
}, {code: 'lv', name: 'Lettisch'}, {code: 'ln', name: 'Lingala'}, {
  code: 'lt',
  name: 'Litauisch'
}, {code: 'mg', name: 'Malagasisch'}, {code: 'ml', name: 'Malajalam'}, {
  code: 'ms',
  name: 'Malaysisch'
}, {code: 'mt', name: 'Maltesisch'}, {code: 'mi', name: 'Maorisch'}, {
  code: 'mr',
  name: 'Marathi'
}, {code: 'mk', name: 'Mazedonisch'}, {code: 'mo', name: 'Moldavisch'}, {
  code: 'mn',
  name: 'Mongolisch'
}, {code: 'na', name: 'Nauruisch'}, {code: 'ne', name: 'Nepalesisch'}, {
  code: 'no',
  name: 'Norwegisch'
}, {code: 'oc', name: 'Okzitanisch'}, {code: 'or', name: 'Oriya'}, {
  code: 'om',
  name: 'Oromo'
}, {code: 'ps', name: 'Paschtu'}, {code: 'fa', name: 'Persisch'}, {
  code: 'pl',
  name: 'Polnisch'
}, {code: 'pt', name: 'Portugiesisch'}, {code: 'pa', name: 'Pundjabisch'}, {
  code: 'qu',
  name: 'Quechua'
}, {code: 'ro', name: 'Rumänisch'}, {code: 'ru', name: 'Russisch'}, {
  code: 'rm',
  name: 'Rätoromanisch'
}, {code: 'sm', name: 'Samoanisch'}, {code: 'sg', name: 'Sango'}, {
  code: 'sa',
  name: 'Sanskrit'
}, {code: 'sn', name: 'Schonisch'}, {code: 'gd', name: 'Schottisches Gälisch'}, {
  code: 'sv',
  name: 'Schwedisch'
}, {code: 'sr', name: 'Serbisch'}, {code: 'sh', name: 'Serbokroatisch (veraltet)'}, {
  code: 'st',
  name: 'Sesothisch'
}, {code: 'tn', name: 'Sezuan'}, {code: 'si', name: 'Singhalesisch'}, {
  code: 'sk',
  name: 'Slowakisch'
}, {code: 'sl', name: 'Slowenisch'}, {code: 'so', name: 'Somalisch'}, {
  code: 'es',
  name: 'Spanisch'
}, {code: 'sw', name: 'Suaheli'}, {code: 'su', name: 'Sudanesisch'}, {
  code: 'ss',
  name: 'Swasiländisch'
}, {code: 'tg', name: 'Tadschikisch'}, {code: 'tl', name: 'Tagalog'}, {
  code: 'ta',
  name: 'Tamilisch'
}, {code: 'tt', name: 'Tatarisch'}, {code: 'te', name: 'Tegulu'}, {code: 'th', name: 'Thai'}, {
  code: 'bo',
  name: 'Tibetanisch'
}, {code: 'ti', name: 'Tigrinja'}, {code: 'to', name: 'Tongaisch'}, {
  code: 'cs',
  name: 'Tschechisch'
}, {code: 'ts', name: 'Tsongaisch'}, {code: 'tk', name: 'Turkmenisch'}, {
  code: 'tw',
  name: 'Twi'
}, {code: 'tr', name: 'Türkisch'}, {code: 'ug', name: 'Uigur'}, {
  code: 'uk',
  name: 'Ukrainisch'
}, {code: 'hu', name: 'Ungarisch'}, {code: 'ur', name: 'Urdu'}, {
  code: 'uz',
  name: 'Usbekisch'
}, {code: 'vi', name: 'Vietnamesisch'}, {code: 'vo', name: 'Volapük'}, {
  code: 'cy',
  name: 'Walisisch'
}, {code: 'wo', name: 'Wolof'}, {code: 'xh', name: 'Xhosa'}, {code: 'za', name: 'Zhuang'}, {
  code: 'sd',
  name: 'Zinti'
}, {code: 'zu', name: 'Zulu'}];
