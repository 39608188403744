import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorComponent} from '../components/error/error.component';
import {isNil} from 'lodash-es';


/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(readonly snackBar: MatSnackBar) {
  }

  /**
   * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
   * @see HttpInterceptor
   * @param req the outgoing HTTP request
   * @param next a HTTP request handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        console.warn(err);
        if (err instanceof HttpErrorResponse) {
          const error = err as HttpErrorResponse;
          const isJson = this.isJson(error.error);
          if (error.status !== 404 && error.status !== 409 && error.status !== 401 && error.status !== 403
            && (!isJson || (isJson && JSON.parse(error.error).type !== 'BiProException'))) {
            this.snackBar.openFromComponent(ErrorComponent, {duration: 4500, data: error});
          }
        }
        return throwError(err);
      }));
  }

  isJson(error: any): boolean {
    let json: any;
    try {
      json = JSON.parse(error);
    } catch (e) {
      return false;
    }
    return !isNil(json);
  }
}

/**
 * Provider POJO for the interceptor
 */
export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
