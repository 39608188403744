<mat-expansion-panel class="w-full">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Abgelöste Verträge
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="flex flex-column gap-2 overflow-y-auto overflow-x-hidden max-h-14rem" *ngIf="tariffs">
    <mat-checkbox *ngFor="let contract of localModel; let i=index" (change)="updateLocalModel()"
                  [(ngModel)]="contract.checked" [matTooltip]="contract.contract.id">
      {{getInsurerName(contract.contract.tarif)}} - {{getSparte(contract.contract.tarif)}} - {{contract.contract.antragsdatum | date:
      'dd.MM.yyyy'}}
    </mat-checkbox>
  </div>
</mat-expansion-panel>
