import {InboxEffects} from './inbox';
import {WizardEffects} from './wizard.effects';
import {TaskEffects} from './task.effects';
import {ProgressSpinnerEffects} from './progress-spinner';
import {RouterEffects} from './router.effects';

export const effects = [
  InboxEffects,
  WizardEffects,
  TaskEffects,
  ProgressSpinnerEffects,
  RouterEffects];
