import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SingleAddresseFullComponent} from './single-adresse-full/single-addresse-full.component';

import {Variables} from '../../../../shared/common/services/task.service';
import {TauresValidators} from '../../../taures-validators';
import {Observable} from 'rxjs';
import {Address, Contract, Person, Status} from '@taures/angular-commons';
import {CustomerService} from '../../../services/customer.service';
import {GERMANY_COUNTRY_CODE} from '../../../services/country-codes';

@Component({
  selector: 'app-customer-contact-address-comparison',
  templateUrl: 'contact-address.component.html',
  styleUrls: ['contact-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactAddressComponent {
  customer: UntypedFormGroup;
  addresses: UntypedFormArray;
  @Input() originalCustomer: Person;
  private allVariables: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private customerService: CustomerService) {
  }

  get variables() {
    return this.allVariables;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.allVariables = value;
    this.customer = this.variables.controls.customer as UntypedFormGroup;
    this.addresses = this.customer.controls.adressen as UntypedFormArray;
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables, originalCustomer: Person) {
    const adressesControl = fb.array([], Validators.required);
    const customerVariables = variables.customer as Person;

    if (customerVariables && customerVariables.adressen) {
      const primary = customerVariables.adressen.find(address => address.postanschrift);
      const newAddresses = customerVariables.adressen.filter(address => !address.id).length;
      // sort by new first, then by primary
      customerVariables.adressen
        .filter(address => address !== primary)
        .forEach(address => {
          if (!!address.id) {
            adressesControl.push(SingleAddresseFullComponent.initGroup(fb, address, customerVariables.adressen.length,
              ContactAddressComponent.getOriginalAddress(originalCustomer, address), address.status !== Status.DELETED));
          } else {
            adressesControl.insert(0, SingleAddresseFullComponent.initGroup(fb, address, customerVariables.adressen.length,
              ContactAddressComponent.getOriginalAddress(originalCustomer, address), address.status !== Status.DELETED));
          }
        });
      if (primary) {
        const primaryIndex = !!primary.id ? newAddresses : 0;
        adressesControl.insert(primaryIndex,
          SingleAddresseFullComponent.initGroup(fb, primary, customerVariables.adressen.length,
            ContactAddressComponent.getOriginalAddress(originalCustomer, primary), primary.status !== Status.DELETED));
      }
    }

    return fb.group({
      customer: fb.group({
        adressen: adressesControl
      }, {validator: Validators.compose([TauresValidators.meldeanschriftRequired, TauresValidators.postanschriftRequired])})
    });
  }

  public static getOriginalAddress(originalCustomer: Person, address: Address) {
    if (address.id === null || !originalCustomer.adressen) {
      return null;
    }
    return originalCustomer.adressen.find(originalAddress => originalAddress.id === address.id);
  }

  getOriginalAddress(address: Address) {
    return ContactAddressComponent.getOriginalAddress(this.originalCustomer, address);
  }

  addAddress() {
    const control = SingleAddresseFullComponent.initGroup(this.fb, {
      geschaeftsanschrift: false,
      meldeanschrift: false,
      postanschrift: false,
      gueltigAb: new Date(),
      land: GERMANY_COUNTRY_CODE
    }, this.addresses.length + 1, {});
    this.addresses.insert(0, control);
    this.setMeldeAnschrift(0);
    this.setContactToPrime(0);
  }


  hasRegistrationAddress(): boolean {
    let j = 0;
    while (j < this.addresses.length) {
      const control = this.addresses.at(j);
      const status = control.get('status');
      if ((!status || status.value !== Status.DELETED) && control.get('meldeanschrift').value === true) {
        return true;
      }
      j++;
    }
    return false;
  }

  hasPrimaryAddress(): boolean {
    let j = 0;
    while (j < this.addresses.length) {
      const control = this.addresses.at(j);
      const status = control.get('status');
      if ((!status || status.value !== Status.DELETED) && control.get('postanschrift').value === true) {
        return true;
      }
      j++;
    }
    return false;
  }

  removeAddress(i: number) {
    const address = this.addresses.at(i);
    // set the address that is primary address to registration address, as well
    if (address.get('meldeanschrift').value) {
      let registrationAddressSet = false;
      let j = 0;
      while (j < this.addresses.length) {
        if (j !== i && this.addresses.at(j).get('postanschrift').value === true) {
          this.addresses.at(j).patchValue({meldeanschrift: true});
          registrationAddressSet = true;
        }
        j++;
      }
      if (!registrationAddressSet && this.addresses.length > 1) {
        j = 0;
        while (j < this.addresses.length) {
          const control = this.addresses.at(j);
          const status = control.get('status');
          if (j !== i && (!status || status.value !== Status.DELETED)) {
            control.patchValue({meldeanschrift: true});
            break;
          }
          j++;
        }
      }
    }

    if (address.get('postanschrift').value && this.addresses.length > 1) {
      // since 'postanschrift' is always at index 0 we can just use the next one
      this.addresses.at(1).patchValue({postanschrift: true});
    }

    if (address.get('id').value) {
      address.patchValue({status: Status.DELETED, meldeanschrift: false, postanschrift: false}, {emitEvent: false});
    } else {
      this.addresses.removeAt(i);
    }
  }

  setContactToPrime(i: number) {
    const newPrimeAdresse = this.addresses.at(i);
    let j = 0;
    while (j < this.addresses.length) {
      const g = this.addresses.at(j);
      if (g.get('postanschrift').value && g.get('id').value) {
        g.get('aenderungsauftrag').setValue(null);
      }
      g.get('postanschrift').setValue(false);
      j++;
    }
    newPrimeAdresse.get('postanschrift').setValue(true);
  }

  setMeldeAnschrift(i: number) {
    const group = this.addresses.at(i) as UntypedFormGroup;
    let j = 0;
    while (j < this.addresses.length) {
      this.addresses.at(j).patchValue({meldeanschrift: false});
      j++;
    }
    group.patchValue({meldeanschrift: true});
    this.setContactToPrime(i);
  }

  getActiveContracts(): Observable<Contract[]> {
    return this.customerService.loadAenderungsauftraege(this.originalCustomer.id);
  }
}

