<h1 mat-dialog-title>Arbeitgeber anlegen</h1>

<mat-dialog-content>
  <div class="flex flex-column gap-4">
    <div *ngFor="let customer of customers" class="mat-elevation-z1 p-2 flex justify-content-between align-items-center gap-3">
      <span>{{customer.nachname}}</span>
      <button *ngIf="customer.id" mat-raised-button color="accent" type="button" (click)="onSelect(customer)">
        Auswählen
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button type="button" (click)="closeDialog()">Abbrechen</button>
</mat-dialog-actions>
