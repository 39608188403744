import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const TARIFF_URL = 't-it-s/rest/vertrag/v1.0/tarife';

export interface Insurer {
  id: number;
  kurzname: string;
  name: string;
}

export interface Category {
  id: number;
  bezeichnung: string;
  versicherungssteuersatz: number;
}

export interface ContractType {
  id: number;
  bezeichnung: string;
  sparte: Category;
  beitragzahldauer?: boolean;
  laufzeitende?: boolean;
  identifikationsmerkmal?: boolean;
  buAntragVariante?: boolean;
  pkvAntragsqualitaet?: boolean;
}

export interface Tariff {
  id: number;
  gesellschaft: Insurer;
  vertragart: ContractType;
  tarifbez: string;
  favorit: boolean;
  bwsSkript: string;
}

export interface PaymentOption {
  label: string;
  key: string;
}

@Injectable()
export class TariffService {

  private loadedTariffs: Tariff[];

  constructor(private http: HttpClient) {
  }

  get tariffs() {
    return this.loadedTariffs;
  }


  loadTariffs(): Observable<Tariff[]> {
    return this.http.get<Tariff[]>(TARIFF_URL);
  }

  load(): Observable<void> {
    return this.loadTariffs().pipe(map(result => {
      this.loadedTariffs = result;
    }));
  }
}
