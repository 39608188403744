import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TauresValidators} from '../../../../taures-validators';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ContactType} from '../../../../services/app-options.service';
import {Contact, Status} from '@taures/angular-commons';
import {EMAIL_GROUP, PHONE_GROUP} from '../../../../services/customer.service';


@Component({
  selector: 'app-contact',
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.scss']
})
export class ContactComponent implements OnDestroy {
  @Output() deleteContact: EventEmitter<any> = new EventEmitter();
  @Output() setAsPrime: EventEmitter<any> = new EventEmitter();
  @Input()
  contactTypes: ContactType[];
  private destroy: Subject<boolean> = new Subject();

  private formGroup: UntypedFormGroup;

  get group(): UntypedFormGroup {
    return this.formGroup;
  }

  @Input()
  set group(value: UntypedFormGroup) {
    this.formGroup = value;
    if (this.formGroup.get('status') && this.formGroup.get('id').value) {
      this.formGroup.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => this.formGroup.get('status').setValue(Status.MODIFIED, {emitEvent: false}));
    }
  }

  get colorClass(): string {
    return this.isPrimary ? 'header taures-primary-blue' : 'header taures-secondary-blue';
  }

  get isFromServer(): boolean {
    return !!this.formGroup.controls.id.value;
  }

  get isPrimary(): boolean {
    return !!this.formGroup.controls.vorrang.value;
  }

  get contactTypeLabel(): string {
    return (this.isThisEmail()) ? 'E-Mail-Adresse' : 'Telefonnummer';
  }

  get validTypes(): ContactType[] {
    return (this.isThisEmail()) ? this.emailContactTypes() : this.telNumContactTypes();
  }

  public static buildGroup(fb: UntypedFormBuilder, contact: Contact, contactTypes: ContactType[],
                           validation: boolean = true) {
    let eintragValidators = [];

    const contactType = contactTypes.find(ct => ct.id === contact.typ);
    if (EMAIL_GROUP.indexOf(contactType.gruppe) !== -1) {
      eintragValidators = [Validators.required, Validators.email];
    }

    if (PHONE_GROUP.indexOf(contactType.gruppe) !== -1) {
      eintragValidators = [Validators.required, TauresValidators.phone];
    }

    return fb.group({
      id: contact.id,
      status: contact.status,
      eintrag: [contact.eintrag, validation ? eintragValidators : []],
      typ: [contact.typ, validation ? Validators.required : []],
      vorrang: contact.vorrang
    });
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  deleteCard() {
    this.deleteContact.emit();
  }

  setCardAsPrimary() {
    this.setAsPrime.emit();
  }

  private emailContactTypes(): ContactType[] {
    return this.contactTypes.filter(ct => EMAIL_GROUP.indexOf(ct.gruppe) !== -1);
  }

  private telNumContactTypes(): ContactType[] {
    return this.contactTypes.filter(ct => PHONE_GROUP.indexOf(ct.gruppe) !== -1);
  }

  private getContactType() {
    return this.contactTypes.find(ct => ct.id === this.formGroup.controls.typ.value);
  }

  private isThisEmail(): boolean {
    const ct = this.getContactType();
    return ct && EMAIL_GROUP.indexOf(ct.gruppe) !== -1;
  }

  disableButtons(): boolean {
    return this.formGroup.get('eintrag').disabled;
  }
}
