<h1 mat-dialog-title>Arbeitgeber anlegen</h1>

<mat-dialog-content>
  <form [formGroup]="form" (submit)="onSubmit()" novalidate>
    <div class="flex flex-column gap-4">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name" required>
      </mat-form-field>

      <h3 class="mb-0">Adressdaten</h3>
      <div class="flex gap-3" formGroupName="adresse">
        <mat-form-field class="flex-auto">
          <mat-label>Straße</mat-label>
          <input matInput placeholder="Straße" formControlName="strasse" required>
        </mat-form-field>
        <mat-form-field class="flex-auto">
          <mat-label>Hausnummer</mat-label>
          <input matInput placeholder="Hausnummer" formControlName="nummer" required>
        </mat-form-field>
      </div>

      <div class="flex gap-3" formGroupName="adresse">
        <mat-form-field class="flex-auto">
          <mat-label>PLZ</mat-label>
          <input matInput placeholder="PLZ" formControlName="plz" required>
        </mat-form-field>

        <mat-form-field class="flex-auto">
          <mat-label>Ort</mat-label>
          <input matInput formControlName="stadt" [matAutocomplete]="placeAutocomplete" placeholder="Ort" required>
          <mat-autocomplete #placeAutocomplete="matAutocomplete">
            <mat-option *ngFor="let option of placeNames | async" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="flex gap-3" formGroupName="adresse">
        <mat-form-field class="flex-auto">
          <mat-label>Land</mat-label>
          <mat-select formControlName="land" placeholder="Land" required>
            <mat-option *ngFor="let country of sortedCountries" [value]="country.code">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="flex-auto">
          <mat-label>C/O</mat-label>
          <input matInput placeholder="C/O" formControlName="adresseco">
        </mat-form-field>
      </div>

      <h3 class="mb-0">Kontaktdaten</h3>
      <mat-form-field>
        <mat-label>Telefonnummer</mat-label>
        <input matInput placeholder="Telefonnummer" formControlName="telefon">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email-Adresse</mat-label>
        <input matInput placeholder="Email-Adresse" formControlName="email">
      </mat-form-field>

    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="pt-2">
  <button mat-button type="button" (click)="closeDialog()">Abbrechen</button>
  <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid" (click)="onSubmit()">
    Anlegen
  </button>
</mat-dialog-actions>
