import {Injectable} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {cloneDeepWith} from 'lodash-es';
import {tap} from 'rxjs/operators';

const ISO_8601_PATTERN = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2})?(:\d{2})?(.\d+)?(Z|[+-]\d{2}(:\d{2})?)?$/;
const JSON_TYPE_REGEXP = /\bapplication\/json\b/;
/**
 * Converts all ISO-8601 string dates to javascript Date object
 */
@Injectable()
export class DateParseInterceptor implements HttpInterceptor {

  static isIso8601(value) {
    if (value === null || value === undefined || typeof value !== 'string') {
      return false;
    }

    return ISO_8601_PATTERN.test(value);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && JSON_TYPE_REGEXP.test(event.headers.get('Content-Type'))) {
          (event as any).body = cloneDeepWith(event.body, value => {
            if (DateParseInterceptor.isIso8601(value)) {
              return new Date(value);
            }
          });
        }
      }));
  }
}

/**
 * Provider POJO for the interceptor
 */
export const DateParseInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: DateParseInterceptor,
  multi: true,
};
