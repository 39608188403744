<div class="flex flex-column">
  <div class="flex gap-2 align-items-center">
    <mat-form-field class="flex-1">
      <input matInput [formControl]="filenameControl" [placeholder]="placeholder" [required]="required" readonly
             [matTooltip]="filename">
    </mat-form-field>
    <div>
      <button type="button" mat-icon-button matTooltip="Datei herunterladen" [disabled]="loading||!filePresent"
              *ngIf="showDownloadButton()" (click)="onDownload()" class="document-file-input-button">
        <mat-icon>file_download</mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Datei anzeigen" [disabled]="loading||!filePresent"
              *ngIf="showPreviewButton()" (click)="onPreview()" class="document-file-input-button">
        <mat-icon>open_in_new</mat-icon>
      </button>
      <a #downloadLink style="display: none" target="_blank"></a>
      <button type="button" mat-icon-button matTooltip="Neue Datei hochladen" (click)="onUpload()"
              [disabled]="loading||disabled||isCrmFile()" class="document-file-input-button">
        <mat-icon *ngIf="!loading">file_upload</mat-icon>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="24"
                              [strokeWidth]="2"></mat-progress-spinner>
      </button>
      <button type="button" mat-icon-button matTooltip="Datei entfernen" (click)="onRemove()"
              [disabled]="loading||!filePresent||disabled||isCrmFile()" class="document-file-input-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <input type="file" hidden (change)="onFileChange($event)" #fileInput [accept]="accept"/>
  </div>
  <div appDnd *ngIf="showDropzone && !loading && !disabled && !isCrmFile()" (fileDropped)="onFileDropped($event)"
       [accept]="accept">
    <span>Datei auf dieses Feld ziehen</span>
  </div>
</div>
