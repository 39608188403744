import {Injectable} from '@angular/core';
import {ProgressSpinnerDialogComponent} from './progress-spinner-dialog.component';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';

@Injectable()
export class ProgressSpinnerService {
  private dialogRef: OverlayRef;

  constructor(readonly overlay: Overlay) {
  }

  show() {
    setTimeout(() => {
      if (!this.dialogRef) {
        this.dialogRef = this.overlay.create({
          hasBackdrop: true,
          backdropClass: 'tr-progress-spinner-dialog-backdrop',
          panelClass: 'progress-spinner-dialog-panel',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
        });
        const overlayPortal = new ComponentPortal(ProgressSpinnerDialogComponent);
        this.dialogRef.attach(overlayPortal);
      }
    });
  }

  hide() {
    setTimeout(() => {
      if (this.dialogRef) {
        this.dialogRef.dispose();
        this.dialogRef = null;
      }
    });
  }
}
