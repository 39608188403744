import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ProgressSpinnerService} from '../components/progress-spinner-dialog/progress-spinner-service';
import {
  LOAD_TASK,
  LOAD_TASK_FAILURE,
  LOAD_TASK_SUCCESS,
  LOAD_TASKS_FAILURE,
  LOAD_TASKS_SUCCESS,
  LOADING_TASKS
} from '../actions/inbox';
import {tap} from 'rxjs/operators';
import {
  COMPLETE_WIZARD,
  COMPLETE_WIZARD_FAILURE,
  START_WIZARD,
  START_WIZARD_FAILURE,
  START_WIZARD_SUCCESS,
  UNCLAIM_TASK,
  UNCLAIM_TASK_FAILURE,
  UNCLAIM_TASK_SUCCESS,
  UPDATE_TASK_VARIABLES,
  UPDATE_TASK_VARIABLES_FAILURE
} from '../actions/wizard.actions';

@Injectable()
export class ProgressSpinnerEffects {
  showSpinner = createEffect(() => this.actions
    .pipe(
      ofType(LOADING_TASKS,
        LOAD_TASK,
        COMPLETE_WIZARD,
        UPDATE_TASK_VARIABLES,
        UNCLAIM_TASK,
        START_WIZARD),
      tap(() => this.progressService.show())
    ), {dispatch: false});

  hideSpinner = createEffect(() => this.actions
    .pipe(
      ofType(
        LOAD_TASKS_SUCCESS,
        LOAD_TASK_SUCCESS,
        UNCLAIM_TASK_SUCCESS,
        LOAD_TASKS_FAILURE,
        LOAD_TASK_FAILURE,
        UNCLAIM_TASK_FAILURE,
        COMPLETE_WIZARD_FAILURE,
        UPDATE_TASK_VARIABLES_FAILURE,
        START_WIZARD_SUCCESS,
        START_WIZARD_FAILURE
      ),
      tap(() => this.progressService.hide())
    ), {dispatch: false});

  constructor(private actions: Actions,
              private progressService: ProgressSpinnerService) {
  }
}
