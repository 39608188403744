<app-data-comparison-card title="Legitimationsdokument" [crmEntity]="isFromServer">

  <div class="flex flex-column gap-2" [formGroup]="control" tr-data-comparison-card-content>

    <mat-form-field>
      <mat-label>Typ</mat-label>
      <mat-select formControlName="ausweisart" required>
        <mat-option *ngFor="let i of identityTypes" [value]="i.id">
          <span>{{ i.bezeichnung }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nummer</mat-label>
      <input matInput formControlName="nummer" appTrim required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Gültig bis</mat-label>
      <input matInput [matDatepicker]="unitllPicker" formControlName="gueltigBis">
      <mat-datepicker-toggle matSuffix [for]="unitllPicker"></mat-datepicker-toggle>
      <mat-datepicker #unitllPicker></mat-datepicker>
    </mat-form-field>

    <div class="flex gap-2">
      <mat-form-field class="flex w-6">
        <mat-label>Ausstellungsdatum</mat-label>
        <input matInput [matDatepicker]="fromPicker" formControlName="ausstellungsdatum" required>
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="flex w-6">
        <mat-label>Ausstellende Behörde</mat-label>
        <input matInput formControlName="behoerde" appTrim required>
      </mat-form-field>

    </div>

    <app-document-file-input placeholder="Datei" formControlName="dokument" showDropzone="true"></app-document-file-input>

  </div>

  <div class="flex justify-content-end" tr-data-comparison-card-buttons>
    <button type="button" mat-icon-button (click)="deleteCard()" matTooltip="Identifikationsmerkmal löschen"
            [disabled]="isFromServer">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

</app-data-comparison-card>
