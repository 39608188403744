import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

export interface Document {
  id: string;
}

export interface DocumentAnalysisResult {
  id: string;
  version: string;
  created: Date;
}

@Injectable()
export class DocumentService {

  constructor(private http: HttpClient) {
  }

  uploadFile(file: File): Observable<Document> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Document>('/inbox/api/documents', formData)
      .pipe(
        catchError(() => of(null))
      );
  }

  downloadInboxFile(id: string): Observable<File> {
    return this.http.get(`/inbox/api/documents/${id}`, {observe: 'response', responseType: 'blob'})
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentType = response.headers.get('Content-Type');
          let fileName = 'file';

          const match = response.headers.get('Content-Disposition').match(/"(.+)"/);
          if (match && match.length > 1) {
            fileName = match[1];
          }
          return new File([response.body], fileName, {type: contentType});
        }));
  }

  downloadCrmFile(id: string): Observable<File> {
    return this.http.get(`/t-it-s/rest/crm/v1.0/dokumente/${id}`, {observe: 'response', responseType: 'blob'})
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentType = response.headers.get('Content-Type');
          let fileName = 'file';

          const match = response.headers.get('Content-Disposition').match(/"(.+)"/);
          if (match && match.length > 1) {
            fileName = match[1];
          }
          return new File([response.body], fileName, {type: contentType});
        }));
  }

  analyzePDF(pdf: File): Observable<DocumentAnalysisResult> {
    return this.http.post<any>('/document-generator/api/document', pdf)
      .pipe(
        map(data => {
          if (!data) {
            return null;
          }
          return ({
            ...data,
            created: new Date(data.created)
          });
        }),
        catchError(error => {
          if (error.status === 400) {
            return of(null);
          }
          throw error;
        })
  );
  }

  guessDocumentType(filename: string): Observable<string> {
    return this.http.get(`/inbox/api/document-type?filename=${filename}`, {responseType: 'text'})
      .pipe(catchError(err => of(null)));
  }

  uploadAndGuessType(files: FileList) {
    return forkJoin(Array.from(files)
      .map(file => this.uploadFile(file)
        .pipe(mergeMap(doc => this.guessDocumentType(file.name)
          .pipe(map(type => ({doc, type, file}))))
        ))
    );
  }
}
