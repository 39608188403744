<h2 mat-dialog-title>{{filename}}</h2>
<mat-dialog-content class="preview-content">
  <div class="flex gap-6">
    <app-pdf-preview [fileDocument]="file" class="w-6 flex-auto"></app-pdf-preview>
    <div class="w-6 flex-auto flex flex-column gap-3">
      <app-document-form [group]="formGroup" [preview]="true"></app-document-form>
      <mat-divider></mat-divider>
      <h3>Nachbearbeitung</h3>
      <app-rework-form [control]="rework"></app-rework-form>
      <button type="button" mat-raised-button color="primary" [disabled]="this.rework.invalid" (click)="addRework()">Anlegen</button>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-icon-button (click)="download()">
    <mat-icon>file_download</mat-icon>
  </button>
  <button type="button" mat-button (click)="previous()" [disabled]="!hasPreviousPage">Vorheriges Dokument</button>
  <button type="button" mat-button (click)="next()" [disabled]="!hasNextPage">Nächstes Dokument</button>
  <button type="button" mat-raised-button color="primary" (click)="close()">Schließen</button>
</mat-dialog-actions>
