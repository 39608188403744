<app-data-comparison-card title="Monatlicher Überschuss" [crmEntity]="isFromServer">

  <div class="flex flex-column gap-2" [formGroup]="group" tr-data-comparison-card-content>

    <mat-form-field class="flex">
      <mat-label>Seit</mat-label>
      <input matInput [matDatepicker]="timePicker" formControlName="datum" required>
      <mat-datepicker-toggle matSuffix [for]="timePicker"></mat-datepicker-toggle>
      <mat-datepicker #timePicker [disabled]="group.controls['datum'].disabled"></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="flex">
      <mat-label>Bruttoeinkommen</mat-label>
      <app-decimal-input formControlName="bruttoeinkommen"></app-decimal-input>
      <span matTextSuffix>€</span>
    </mat-form-field>

    <mat-form-field class="flex">
      <mat-label>Nettoeinkommen</mat-label>
      <app-decimal-input formControlName="nettoeinkommen"></app-decimal-input>
      <span matTextSuffix>€</span>
    </mat-form-field>

    <mat-form-field class="flex">
      <mat-label>Vermögen</mat-label>
      <app-decimal-input formControlName="vermoegen"></app-decimal-input>
      <span matTextSuffix>€</span>
    </mat-form-field>

    <mat-form-field class="flex">
      <mat-label>Verbindlichkeiten</mat-label>
      <app-decimal-input formControlName="verbindlichkeiten"></app-decimal-input>
      <span matTextSuffix>€</span>
    </mat-form-field>

    <mat-form-field class="flex">
      <mat-label>Überschuss exkl. Versicherungen</mat-label>
      <app-decimal-input formControlName="ueberschuss"></app-decimal-input>
      <span matTextSuffix>€</span>
    </mat-form-field>

    <mat-form-field class="flex">
      <mat-label>Bemerkung</mat-label>
      <textarea matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="10"
                formControlName="bemerkung">
      </textarea>
      <mat-hint *ngIf="group.get('bemerkung').valid">{{getBemerkungCounter()}}</mat-hint>
      <mat-error *ngIf="group.get('bemerkung').invalid">{{getBemerkungCounter()}}</mat-error>
    </mat-form-field>

  </div>

  <div class="flex justify-content-end" tr-data-comparison-card-buttons>
    <button type="button" mat-icon-button (click)="deleteCard()" matTooltip="Eintrag löschen" [disabled]="isFromServer">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

</app-data-comparison-card>
