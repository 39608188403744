import {COMPOSITION_BUFFER_MODE, DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Directive, ElementRef, HostListener, Inject, Optional, Renderer2} from '@angular/core';

@Directive( {
  selector : 'input[appTrim], textarea[appTrim]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TrimInputValueDirective, multi: true }]
} )
export class TrimInputValueDirective extends DefaultValueAccessor {

  constructor(
    renderer: Renderer2,
    elementRef: ElementRef,
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) compositionMode: boolean
  ) {
    super(renderer, elementRef, compositionMode);
  }

  @HostListener('input', ['$event.target.value'])
  ngOnChange = (val: string) => {
    this.onChange(val.trim());
  }

  @HostListener('blur', ['$event.target.value'])
  applyTrim(val: string) {
    this.writeValue(val.trim());
  }

  writeValue(value: any): void {
    if (typeof value === 'string') {
      value = value.trim();
    }

    super.writeValue(value);
  }

}
