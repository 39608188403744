import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface ContactType {
  id: number;
  bezeichnung: string;
  gruppe: string;
  order: number;
}

export interface IdentityType {
  id: number;
  bezeichnung: string;
}

export interface EmploymentType {
  id: number;
  bezeichnung: string;
  berufsgruppe: string;
}

export interface ActionCategory {
  uivalue: string;
  dbvalue: string;
  geschaeftsvorfaelle?: BusinessProcess[];
  geschaeftsvorfaelleNeuanlage?: BusinessProcess[];
}

export interface BusinessProcess {  // geschaeftsvorfaelle
  prio: number;
  uivalue: string;
  dbvalue: string;
}

export interface AppOptions {
  communicationTypes: ContactType[];
  identificationTypes: IdentityType[];
  relationshipTypes: { uivalue: string, dbvalue: string, uivalueVon: string }[];
  bildungsabschluss: { [key: string]: string };
  maritalStatusTypes: { [key: string]: string };
  categoryVpTypes: { [key: string]: string};
  antragart: { [key: string]: string };
  antragEmpfaenger: { [key: string]: string };
  buAntragVarianten: { [key: string]: string };
  pkvAntragsqualitaeten: { [key: string]: string };
  employmentTypes: EmploymentType[];
  aktionStatus: { [key: string]: string };
  actionCategories: ActionCategory[];
  documentTypes: { [key: string]: string };
  zahlweise: { uivalue: string, dbvalue: string }[];
  brokerContractVersion: string[];
  maklervollmachtVersion: string[];
  einwilligungKontaktaufnahme: { [key: string]: string };
  gueterstand: { [key: string]: string};
  steuerklasse: { [key: string]: string};
  berufsgruppen: { [key: string]: string };
}

export interface BrokerContractOption {
  id: number;
  thema: string;
  sortidx: number;
}

export interface TaskType {
  id: string;
  label: string;
}

@Injectable()
export class AppOptionsService {

  private appOptions: AppOptions = {} as any;

  constructor(private http: HttpClient) {
  }

  private brokerOptions: BrokerContractOption[];

  get brokerContractOptions() {
    return this.brokerOptions;
  }

  private types;

  get taskTypes() {
    return this.types;
  }

  get options() {
    return this.appOptions;
  }

  get documentTypes() {
    return this.options.documentTypes;
  }

  get contactTypes() {
    return this.options.communicationTypes;
  }

  get educationTypes() {
    return this.options.bildungsabschluss;
  }

  get identificationTypes() {
    return this.options.identificationTypes;
  }

  get antragartTypes() {
    return this.options.antragart;
  }

  get antragEmpfaengerTypes() {
    return this.options.antragEmpfaenger;
  }

  get buAntragVarianten() {
    return this.options.buAntragVarianten;
  }

  get pkvAntragsqualitaeten() {
    return this.options.pkvAntragsqualitaeten;
  }

  get paymentOptions() {
    return this.options.zahlweise;
  }

  get actionCategoryTypes() {
    return this.options.actionCategories;
  }

  get actionStatusTypes() {
    return this.options.aktionStatus;
  }

  get relationshipTypes() {
    return this.options.relationshipTypes;
  }

  get maritalStatusTypes() {
    return this.options.maritalStatusTypes;
  }

  get categoryVpTypes() {
    return this.options.categoryVpTypes;
  }

  get employmentTypes() {
    return this.options.employmentTypes;
  }

  get brokerContractVersion() {
    return this.options.brokerContractVersion;
  }

  get maklervollmachtVersion() {
    return this.options.maklervollmachtVersion;
  }

  get einwilligungKontaktaufnahme() {
    return this.options.einwilligungKontaktaufnahme;
  }

  get steuerklasse() {
    return this.options.steuerklasse;
  }

  get gueterstand() {
    return this.options.gueterstand;
  }

  getProfessions(filter: { [key: string]: string }): Observable<string[]> {
    let params = new HttpParams();
    for (const key of Object.keys(filter)) {
      params = params.set(key, filter[key]);
    }
    return this.http.get<any[]>('/t-it-s/rest/basis/v1.0/options/taetigkeiten', {params})
      .pipe(
        map(e => Object.values(e)));
  }

  load(): Observable<void> {
    return forkJoin([this.loadAllOptions(), this.loadBrokerContractOptions(), this.loadTaskTypes()])
      .pipe(map(([appOptions, brokerOptions, taskTypes]) => {
        this.setOptions(appOptions, brokerOptions, taskTypes);
      }));
  }

  setOptions(appOptions: AppOptions, brokerOptions?: BrokerContractOption[], taskTypes?: TaskType[]) {
    this.appOptions = {
      ...appOptions,
      communicationTypes: appOptions.communicationTypes.sort((a: ContactType, b: ContactType) => a.order - b.order),
    };
    if (brokerOptions) {
      this.brokerOptions = brokerOptions;
    }
    if (taskTypes) {
      this.types = taskTypes;
    }
  }

  private loadAllOptions(): Observable<AppOptions> {
    return this.http.get<AppOptions>(`/t-it-s/rest/basis/v1.0/options/all`);
  }

  private loadBrokerContractOptions(): Observable<BrokerContractOption[]> {
    return this.http.get<BrokerContractOption[]>('/t-it-s/rest/crm/v1.0/person/brokerContractItems')
      .pipe(map(items => items.sort((a, b) => a.sortidx - b.sortidx)));
  }

  private loadTaskTypes(): Observable<TaskType[]> {
    return this.http.get<{ [key: string]: string }>('/inbox/api/tasks/types')
      .pipe(
        map(types => Object.keys(types)
          .map(key => ({id: key, label: types[key]})))
      );
  }
}
