<div [formGroup]="control"
     class="mat-elevation-z2 overflow-hidden border-4px flex flex-column flex-grow-1">
  <div class="p-2" [class]="this.control.valid ? 'taures-secondary-blue' : 'taures-header-invalid'">
    {{title}}
  </div>
  <div class="p-4">
    <div class="flex flex-column gap-2">
      <div>
        <b>Gesellschaft:</b> {{insurerName}}
      </div>
      <div>
        <b>Tarif:</b> {{tariffName}}
      </div>
      <div>
        <b>Typ:</b> {{tariffType}}
      </div>
      <div>
        <b>Beginn:</b> {{control.getRawValue().beginlaufzeit | date: 'dd.MM.yyyy'}}
      </div>
    </div>

    <div class="flex justify-content-end">
      <button type="button" mat-button (click)="edit()">Bearbeiten</button>
    </div>

    <div class="flex gap-2 justify-content-end">
      <mat-form-field *ngIf="showSelectBuendelpolice()" class="flex-grow-1">
        <mat-select formControlName="buendelpolice" placeholder="Bündelpolice">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let bpContract of getFilteredBuendelpolicen()" [value]="bpContract.id">{{getBpLabel(bpContract)}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="showCreateBuendelpolice()"
              type="button" mat-button (click)="createBuendelpolice()">Bündelpolice erstellen</button>
    </div>
  </div>

</div>
