<div class="header">
  <h1 mat-dialog-title>Bank auswählen</h1>
</div>
<mat-dialog-content>
  <div class="flex flex-column gap-2 p-1">
    <button class="h-full" type="button" mat-raised-button *ngFor="let bank of banks" (click)="close(bank)">
      <div class="flex flex-column gap-1 my-2">
        <span><b>{{bank.name}}</b></span>
        <span>{{bank.bic}}</span>
        <span>{{bank.city}}</span>
      </div>
    </button>
  </div>
</mat-dialog-content>
