import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlValueAccessor, UntypedFormControl, NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {isNil} from 'lodash-es';

@Component({
  selector: 'app-iban',
  templateUrl: './iban.component.html',
  styleUrls: ['./iban.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: IbanComponent}]
})
export class IbanComponent implements MatFormFieldControl<string>, OnInit, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  control = new UntypedFormControl();
  disabled = false;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'tr-iban';
  @HostBinding() id = `tr-iban-${IbanComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  private unspacedIban: string;
  private placeholderValue: string;
  private isRequired = false;

  // noinspection UnterminatedStatementJS
  onChange: any = () => {
    // default function
  }
  // noinspection UnterminatedStatementJS
  onTouched: any = () => {
    // default function
  }

  constructor(private fm: FocusMonitor,
              private elRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get value(): string | null {
    return this.unspacedIban;
  }
  set value(v: string | null) {
    this.unspacedIban = v;
    this.control.setValue(this.format(v));
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this.placeholderValue;
  }
  set placeholder(plh) {
    this.placeholderValue = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this.isRequired;
  }
  set required(req) {
    this.isRequired = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get empty() {
    return !this.control.value;
  }

  get errorState() {
    return !this.ngControl.disabled ? !this.ngControl.valid : false;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  ngOnInit() {
    this.control.valueChanges.subscribe((iban) => {
      this.unspacedIban = iban.replace(/\s/g, '');
      this.control.setValue(this.format(this.unspacedIban), {emitEvent: false});
      this.onTouched();
      this.onChange(this.unspacedIban);
      this.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(value: string) {
    if (isNil(value) || typeof value !== 'string') {
      return;
    }
    if (this.unspacedIban === value) {
      return;
    }
    this.value = value;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private format(value: string) {
    if (isNil(value) || typeof value !== 'string') {
      return;
    }
    return value.split(' ').join('').replace(/(.{4})/g, '$1 ').trim();
  }

}
