import {NgModule} from '@angular/core';
import {ErrorInterceptorProvider} from './services/error.interceptor';
import {DateParseInterceptorProvider} from './services';
import {PageNotFoundComponent} from './components';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {EnforceSelectDirective} from './directives/enforce-select.directive';
import {ErrorComponent} from './components/error/error.component';
import {DecimalInputComponent} from './components/decimal-input/decimal-input.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ForbiddenComponent} from './components/forbidden/forbidden.component';
import {IbanComponent} from './components/iban/iban.component';
import {SimpleDialogComponent} from './components/simple-dialog/simple-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TaskService} from './services/task.service';
import {TrimInputValueDirective} from './directives/trim-input-value.directive';
import {DndDirective} from './directives/dnd.directive';
import {MatInputModule} from "@angular/material/input";
import {RouterLink} from "@angular/router";

@NgModule({
    imports: [AngularCommonModule, MatCardModule, MatButtonModule, ReactiveFormsModule, MatDialogModule, MatInputModule, RouterLink],
    declarations: [PageNotFoundComponent, EnforceSelectDirective, ErrorComponent, DecimalInputComponent,
      ForbiddenComponent, IbanComponent, SimpleDialogComponent,
        TrimInputValueDirective, DndDirective],
    exports: [PageNotFoundComponent, EnforceSelectDirective, DecimalInputComponent,
        ForbiddenComponent, IbanComponent, TrimInputValueDirective, DndDirective],
    providers: [
        DateParseInterceptorProvider,
        ErrorInterceptorProvider,
        TaskService
    ]
})
export class CommonModule {
}
