<div [formGroup]="group" class="flex flex-column">
  <mat-form-field class="flex" *ngIf="!preview">
    <mat-label>Typ</mat-label>
    <mat-select formControlName="typ">
      <mat-option>
        <ngx-mat-select-search [formControl]="typeFilterCtrl"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let documentTypeOption of filteredTypes | async" [value]="documentTypeOption.value">
        <span>{{ documentTypeOption.label }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-document-file-input class="flex" placeholder="Datei" [formControl]="file"
                           [previewPresenter]="previewPresenter"
                           required *ngIf="!preview"></app-document-file-input>
  <mat-form-field class="flex">
    <mat-label>Kommentar</mat-label>
    <textarea matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10"
              [value]="group.value.kommentar"
              formControlName="kommentar"
              appTrim>
    </textarea>
  </mat-form-field>
  <div class="gap-2" *ngIf="showVerschicktFields()">
    <mat-form-field class="flex">
      <input matInput [matDatepicker]="versendetAmPicker" formControlName="versendet"
             (dateChange)="onDateChange($event)" placeholder="verschickt am"
             [max]="today">
      <mat-datepicker-toggle matSuffix [for]="versendetAmPicker" tabIndex="-1"></mat-datepicker-toggle>
      <mat-datepicker #versendetAmPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="flex">
      <input matInput formControlName="versendetAn" [value]="group.value.versendetAn" placeholder="verschickt an"
             appTrim>
    </mat-form-field>
  </div>

</div>
