import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {CreateEmployerComponent} from '../../../create-employer-dialog/create-employer.component';
import {AppOptionsService, EmploymentType} from '../../../../services/app-options.service';
import {Employment, Person, Status, User} from '@taures/angular-commons';

@Component({
  selector: 'app-single-employment',
  templateUrl: 'single-employment.component.html',
  styleUrls: ['single-employment.component.scss']
})
export class SingleEmploymentComponent implements OnDestroy {
  @Output() deleteCard: EventEmitter<any> = new EventEmitter();
  @Output() setAsPrime: EventEmitter<any> = new EventEmitter();
  @Input() employmentOptions: EmploymentType[];
  filteredDescriptionOptions: Observable<string[]>;
  companyFilter = {companiesOnly: true};
  readonly MINIMUM_PROFESSION_SEARCH_STRING_LENGTH = 1;
  readonly displayCompanyName = SingleEmploymentComponent.displayCompanyNameFunc();

  private destroy: Subject<boolean> = new Subject();
  private group: UntypedFormGroup;

  constructor(private optionsService: AppOptionsService, readonly dialog: MatDialog) {
  }

  get control(): UntypedFormGroup {
    return this.group;
  }

  @Input()
  set control(value: UntypedFormGroup) {
    this.group = value;
    if (this.group.get('status') && this.isFromServer) {
      this.group.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.group.get('status').setValue(Status.MODIFIED, {emitEvent: false});
        });
    }
    this.group.get('bezeichnung').valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(searchString => {
        if (searchString.length >= this.MINIMUM_PROFESSION_SEARCH_STRING_LENGTH) {
          this.filteredDescriptionOptions = this.optionsService.getProfessions({search: searchString});
        }
      });
  }

  get isFromServer(): boolean {
    return !!this.group.controls.id.value;
  }

  get isPrimary(): boolean {
    return !!this.group.controls.primaer.value;
  }

  public static buildGroup(fb: UntypedFormBuilder, employment: Employment, validate: boolean = true) {
    return fb.group({
      berufstatus: [employment.berufstatus, validate ? [Validators.required] : []],
      arbeitgeber: employment.arbeitgeber,
      id: employment.id,
      status: employment.status,
      primaer: employment.primaer,
      bezeichnung: [employment.bezeichnung, validate ? [Validators.required] : []],
      gueltigAb: [employment.gueltigAb, validate ? [Validators.required] : []],
      gueltigBis: employment.gueltigBis,
      abteilung: employment.abteilung,
      personalnummer: employment.personalnummer
    });
  }

  static displayCompanyNameFunc() {
    return (c: User | Person) => !!c ? c.nachname : '';
  }


  removeCard() {
    this.deleteCard.emit(this.group);
  }

  setCardAsPrimary() {
    this.setAsPrime.emit(this.group);
  }

  createEmployer() {
    this.dialog.open(CreateEmployerComponent, {width: '600px'})
      .afterClosed()
      .subscribe(result => {
        if (result && result.id) {
          this.group.get('arbeitgeber').setValue(result.id);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
