import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SingleBankComponent} from './single-bank/single-bank.component';
import {TauresValidators} from '../../../taures-validators';
import {Variables} from '../../../../shared/common/services/task.service';
import {Observable} from 'rxjs';
import {BankDetails, Contract, Person, Status} from '@taures/angular-commons';
import {CustomerService} from '../../../services/customer.service';

@Component({
  selector: 'app-customer-bank-comparison',
  templateUrl: 'customer-bank.component.html',
  styleUrls: ['customer-bank.component.scss']
})
export class CustomerBankComponent {
  bankverbindungen: UntypedFormArray;
  @Input()
  customerId: number;
  @Input() originalCustomer: Person;
  private variablesGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private customerService: CustomerService) {
  }

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    this.bankverbindungen = this.variables.get('customer.bankverbindungen') as UntypedFormArray;
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables, originalCustomer: Person) {
    const banks = fb.array([],
      Validators.compose([TauresValidators.duplicateIbans]));
    const customer = variables.customer as Person;
    const validators = [TauresValidators.bankverbindungRequired, TauresValidators.hauptverbindungRequired];

    if (customer && customer.bankverbindungen) {
      const primary = customer.bankverbindungen.find(bank => bank.hauptverbindung);
      const newBanks = customer.bankverbindungen.filter(bank => !bank.id).length;
      // move primary to the top
      customer.bankverbindungen
        .filter(bank => bank !== primary)
        .forEach(bankVer => {
          if (!!bankVer.id) {
            banks.push(SingleBankComponent.buildGroup(fb, bankVer, customer.bankverbindungen.length,
              CustomerBankComponent.getOriginalBank(originalCustomer, bankVer), bankVer.status !== Status.DELETED));
          } else {
            banks.insert(0, SingleBankComponent.buildGroup(fb, bankVer, customer.bankverbindungen.length,
              CustomerBankComponent.getOriginalBank(originalCustomer, bankVer), bankVer.status !== Status.DELETED));
          }
        });
      if (primary) {
        const primaryIndex = !!primary.id ? newBanks : 0;
        banks.insert(primaryIndex, SingleBankComponent.buildGroup(fb, primary, customer.bankverbindungen.length,
          CustomerBankComponent.getOriginalBank(originalCustomer, primary), primary.status !== Status.DELETED));
      }
    }

    return fb.group({
      customer: fb.group({
        kunde: fb.group({
          bezahltPerRechnung: customer && customer.kunde ? customer.kunde.bezahltPerRechnung : false
        }),
        bankverbindungen: banks
      }, {validator: validators})
    });
  }

  public static getOriginalBank(originalCustomer: Person, bank: BankDetails) {
    if (bank.id === null || !originalCustomer.bankverbindungen) {
      return null;
    }
    return originalCustomer.bankverbindungen.find(originalBank => originalBank.id === bank.id);
  }

  getOriginalBank(bank: BankDetails) {
    return CustomerBankComponent.getOriginalBank(this.originalCustomer, bank);
  }

  addBank() {
    this.bankverbindungen.insert(0, SingleBankComponent.buildGroup(this.fb, {
      inhaber: [this.customerId],
      privat: true,
      hauptverbindung: !this.hasPrimaryBank(),
      gueltigAb: new Date()
    }, this.bankverbindungen.length + 1, {}));
  }

  hasPrimaryBank(): boolean {
    let j = 0;
    while (j < this.bankverbindungen.length) {
      const con = this.bankverbindungen.at(j);
      const status = con.get('status');
      if ((!status || status.value !== Status.DELETED) && con.get('hauptverbindung').value === true) {
        return true;
      }
      j++;
    }
    return false;
  }

  removeBank(i: number) {
    const bv = this.bankverbindungen.at(i);
    const isHauptverbindung = this.bankverbindungen.at(i).get('hauptverbindung').value;
    if (bv.get('id').value) {
      bv.patchValue({status: Status.DELETED, hauptverbindung: false}, {emitEvent: false});
    } else {
      this.bankverbindungen.removeAt(i);
    }
    if (isHauptverbindung && this.bankverbindungen.length > 0) {
      this.bankverbindungen.at(0).patchValue({hauptverbindung: true});
    }
  }

  setBankToPrime(i: number) {
    const control = this.bankverbindungen.at(i);
    let j = 0;
    while (j < this.bankverbindungen.length) {
      const currBank = this.bankverbindungen.at(j);
      if (currBank.get('hauptverbindung').value && currBank.get('id').value) {
        currBank.get('aenderungsauftrag').setValue(null);
      }
      currBank.patchValue({hauptverbindung: false});
      j++;
    }
    control.patchValue({hauptverbindung: true});
  }

  getActiveContracts(): Observable<Contract[]> {
    return this.customerService.loadAenderungsauftraege(this.customerId);
  }
}

