import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ReworkFormComponent} from '../rework-form/rework-form.component';

@Component({
  selector: 'app-rework-dialog',
  templateUrl: 'rework-dialog.component.html',
  styleUrls: ['rework-dialog.component.scss']
})
export class ReworkDialogComponent {
  rework: UntypedFormGroup;
  private emitForm: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: { contract: boolean },
              private dialogRef: MatDialogRef<ReworkDialogComponent>,
              private store: Store<fromRoot.State>,
              private fb: UntypedFormBuilder) {
    this.rework = ReworkFormComponent.initGroup(this.fb, {
      objekttyp: this.data.contract ? 'V' : 'K',
      status: '170',
      infoAnBetreuer: true
    });
    this.emitForm = false;
  }

  createRework() {
    if (this.rework.valid) {
      this.dialogRef.close(this.rework.getRawValue() as object);
    }
  }

  cancelDialog() {
    this.dialogRef.close();
  }
}
