import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface Bank {
  id: string;
  name: string;
  bic: string;
  blz: string;
  plz: string;
  city: string;
}


@Injectable()
export class IbanService {

  constructor(private http: HttpClient) {
  }

  getBanks(iban: string): Observable<Bank[]> {
    iban = iban.replace(/\s/g, '');
    if (!iban.startsWith('DE')) {
      return of([]);
    }
    return this.http.get<Bank[]>(`/banks?iban=${iban}`);
  }
}
