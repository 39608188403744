<div class="flex flex-column flex-grow-1"
     [formGroup]="control">

  <mat-radio-group formControlName="objekttyp" class="flex gap-6 align-items-center radio-button-size">
    <label>Bezug:</label>
    <mat-radio-button [value]="'K'">
      Kunde
    </mat-radio-button>
    <mat-radio-button *ngIf="hasContractId() | async" [value]="'V'">
      Vertrag
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field>
    <mat-label>Kategorie</mat-label>
    <mat-select formControlName="gvKategorie"
                required>
      <mat-option *ngFor="let action of actionCategoryTypes; let i=index" [value]="action.dbvalue">
        <span>{{ action.uivalue}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Geschäftsvorfall</mat-label>
    <mat-select formControlName="geschaeftsvorfall"
                required>
      <mat-option *ngFor="let bProc of getBuisnessProcesses(control.get('gvKategorie').value);" [value]="bProc.dbvalue">
        <span>{{ bProc.uivalue}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select formControlName="status"
                required>
      <mat-option *ngFor="let key of actionStatusTypesKeys;" [value]="key">
        <span>{{ actionStatusTypes[key] }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Fälligkeitsdatum</mat-label>
    <input matInput [matDatepicker]="unitllPicker" formControlName="durchfuehrungSoll" required>
    <mat-datepicker-toggle matSuffix [for]="unitllPicker"></mat-datepicker-toggle>
    <mat-datepicker #unitllPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Erinnerungsdatum</mat-label>
    <input matInput [matDatepicker]="fromPicker" formControlName="erinnerungsdatum">
    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
    <mat-datepicker #fromPicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Kommentar</mat-label>
    <textarea matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10"
              formControlName="beschreibung"
              appTrim
              required>
    </textarea>
  </mat-form-field>

  <mat-checkbox formControlName="infoAnBetreuer">Info an Betreuer</mat-checkbox>
</div>
