import {ChangeDetectionStrategy, Component, HostListener, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Action} from '@ngrx/store';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      cancel?: Action,
      confirm: Action,
      text: string,
      title: string
    },
    private matDialogRef: MatDialogRef<ConfirmDialogComponent>
  ) { }

  public cancel(): void {
    this.close(this.data.cancel);
  }

  public close(action?: Action): void {
    this.matDialogRef.close(action);
  }

  public confirm(): void {
    this.close(this.data.confirm);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close();
  }
}
