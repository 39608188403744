<div [formGroup]="variables" class="flex gap-6">
  <div class="flex flex-column gap-4 w-7 flex-auto">
    <mat-checkbox formControlName="isBackOfficeCheckRequested" class="pt-3">
      Sichtprüfung durch TauRes Backoffice
    </mat-checkbox>

    <div formGroupName="customer">
      <div formGroupName="kunde">
        <div [formGroup]="brokerContractDocument" class="flex">
          <mat-form-field class="flex-1">
            <mat-label>Dokumenttyp</mat-label>
            <mat-select formControlName="typ">
              <mat-option *ngFor="let type of brokerContractTypeKeys" [value]="type">
                {{ brokerContractTypeLabel(type) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex gap-3">
          <mat-form-field class="flex-1">
            <mat-label>Datum Maklervertrag</mat-label>
            <input matInput formControlName="maklervertragDatum" [matDatepicker]="makvollPicker">
            <mat-datepicker-toggle matSuffix [for]="makvollPicker"></mat-datepicker-toggle>
            <mat-datepicker #makvollPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="flex-1">
            <mat-label>Datum Servicevertrag</mat-label>
            <input matInput formControlName="servicevertragDatum" [matDatepicker]="servicevertragPicker">
            <mat-datepicker-toggle matSuffix [for]="servicevertragPicker"></mat-datepicker-toggle>
            <mat-datepicker #servicevertragPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex gap-3">
          <mat-form-field class="flex-1">
            <mat-label>Datum Darlehensvermittlung</mat-label>
            <input matInput formControlName="darlehensvermittlungDatum" [matDatepicker]="darlehensvermittlungPicker">
            <mat-datepicker-toggle matSuffix [for]="darlehensvermittlungPicker"></mat-datepicker-toggle>
            <mat-datepicker #darlehensvermittlungPicker></mat-datepicker>
          </mat-form-field>
          <div formGroupName="maklervertragVersion" class="flex flex-1">
            <mat-form-field class="flex-1">
              <mat-label>Version Maklervertrag</mat-label>
              <mat-select formControlName="version">
                <mat-option *ngFor="let version of brokerContractVersions" [value]="version">{{ version }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex gap-3">
          <div formGroupName="maklervollmachtVersion" class="flex flex-1">
            <mat-form-field class="flex-1">
              <mat-label>Version Maklervollmacht</mat-label>
              <mat-select formControlName="version">
                <mat-option *ngFor="let version of brokerContractProxyVersions" [value]="version">{{ version }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex-1"></div>
        </div>
        <div class="flex gap-3">
          <mat-form-field class="flex-1">
            <mat-label>Kommentar</mat-label>
            <input matInput type="text" formControlName="makvollmBem" appTrim>
          </mat-form-field>
        </div>
      </div>

      <div class="flex gap-3">
        <mat-form-field class="flex-1">
          <mat-label>Einwilligung Festnetz</mat-label>
          <mat-select formControlName="einwilligungKontaktTelefon">
            <mat-option *ngFor="let option of einwilligungKontaktaufnahmeOptions" [value]="option">
              {{ einwilligungKontaktaufnahmeLabel(option) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="showKontaktKommentar('einwilligungKontaktTelefon')" class="flex-1">
          <mat-label>Kommentar</mat-label>
          <input matInput type="text" formControlName="anmerkungTelefon" appTrim>
        </mat-form-field>
      </div>
      <div class="flex gap-3">
        <mat-form-field class="flex-1">
          <mat-label>Einwilligung Email</mat-label>
          <mat-select formControlName="einwilligungKontaktEmail">
            <mat-option *ngFor="let option of einwilligungKontaktaufnahmeOptions" [value]="option">
              {{ einwilligungKontaktaufnahmeLabel(option) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="showKontaktKommentar('einwilligungKontaktEmail')" class="flex-1">
          <mat-label>Kommentar</mat-label>
          <input matInput type="text" formControlName="anmerkungEmail" appTrim>
        </mat-form-field>
      </div>
      <div class="flex gap-3">
        <mat-form-field class="flex-1">
          <mat-label>Einwilligung Mobil</mat-label>
          <mat-select formControlName="einwilligungKontaktMobil">
            <mat-option *ngFor="let option of einwilligungKontaktaufnahmeOptions" [value]="option">
              {{ einwilligungKontaktaufnahmeLabel(option) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="showKontaktKommentar('einwilligungKontaktMobil')" class="flex-1">
          <mat-label>Kommentar</mat-label>
          <input matInput type="text" formControlName="anmerkungMobil" appTrim>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="brokerContractItems.errors && brokerContractItems.errors['duplicateField']" class="invalid">
      Jeder Vertragsgegenstand darf nur einmal vorkommen.
    </div>
    <div class="flex gap-3">
      <mat-table class="flex-1" [dataSource]="brokerContractTableSource">
        <ng-container matColumnDef="vertragsgegenstand">
          <mat-header-cell *matHeaderCellDef class="flex-1">Vertragsgegenstand</mat-header-cell>
          <mat-cell *matCellDef="let contractItem" class="flex-1">
            <app-broker-contract-option [options]="brokerContractOptions" [control]="contractItem"
                                        class="flex-1"></app-broker-contract-option>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="gueltigAb">
          <mat-header-cell *matHeaderCellDef class="w-11rem flex-none">gültig ab</mat-header-cell>
          <mat-cell *matCellDef="let contractItem" class="w-11rem flex-none align-items-end">
            <mat-form-field [formGroup]="contractItem">
              <input matInput formControlName="datum" [matDatepicker]="gueltigAbPicker">
              <mat-datepicker-toggle matSuffix [for]="gueltigAbPicker"></mat-datepicker-toggle>
              <mat-datepicker #gueltigAbPicker></mat-datepicker>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="gueltigBis">
          <mat-header-cell *matHeaderCellDef class="w-11rem flex-none">gültig bis</mat-header-cell>
          <mat-cell *matCellDef="let contractItem" class="w-11rem flex-none align-items-end">
            <mat-form-field [formGroup]="contractItem">
              <input matInput formControlName="gekuendigt" [matDatepicker]="gueltigBisPicker">
              <mat-datepicker-toggle matSuffix [for]="gueltigBisPicker"></mat-datepicker-toggle>
              <mat-datepicker #gueltigBisPicker></mat-datepicker>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Bemerkung">
          <mat-header-cell *matHeaderCellDef class="w-10rem flex-none">Bemerkung</mat-header-cell>
          <mat-cell *matCellDef="let contractItem" class="w-10rem flex-none align-items-end">
            <mat-form-field [formGroup]="contractItem">
              <input matInput formControlName="bemerkung" appTrim>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef class="w-4rem flex-none"></mat-header-cell>
          <mat-cell *matCellDef="let contractItem; let i = index" class="w-4rem flex-none justify-content-center">
            <button type="button" mat-icon-button class="mb-3"
                    [disabled]="!contractItem.get('manuallyAdded').value"
                    matTooltip="Vertragsgegenstand löschen" (click)="removeContractItem(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="TABLE_HEADERS" class="flex align-items-end gap-2"></mat-header-row>
        <mat-row *matRowDef="let row; columns: TABLE_HEADERS;" class="flex gap-2"></mat-row>
      </mat-table>
    </div>
    <button type="button" style="color:white" mat-fab (click)="addContractItem()"
            matTooltip="Vertragsgegenstand hinzufügen"
            *ngIf="isBackOfficeTask()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <app-broker-contract-document-form
    class="w-5 flex flex-auto"
    [brokerContractDocument]="brokerContractDocument"
    (brokerContractChanged)="onBrokerContractChanged($event)">
  </app-broker-contract-document-form>
</div>
