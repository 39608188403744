import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Status, Surplus} from '@taures/angular-commons';

@Component({
  selector: 'app-single-surplus',
  templateUrl: 'single-surplus.component.html',
  styleUrls: ['single-surplus.component.scss'],
})
export class SingleSurplusComponent implements OnDestroy {
  @Output() deleteContact: EventEmitter<any> = new EventEmitter();
  private destroy: Subject<boolean> = new Subject();

  private formGroup: UntypedFormGroup;

  get group() {
    return this.formGroup;
  }

  @Input()
  set group(value: UntypedFormGroup) {
    this.formGroup = value;
    if (this.formGroup.get('status') && this.isFromServer) {
      this.formGroup.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.formGroup.get('status').setValue(Status.MODIFIED, {emitEvent: false});
        });
    }
  }

  get isFromServer(): boolean {
    return !!this.group.controls.id.value;
  }

  public static buildGroup(fb: UntypedFormBuilder, surplus: Surplus, validate: boolean = true) {
    const ueberschussValidators = [];
    const nettoeinkommenValidators = [];
    const vermoegenValidators = [];
    const verbindlichkeitenValidators = [];
    const seitValidators = [];

    if (!surplus.id) {
      seitValidators.push(Validators.required);
      ueberschussValidators.push(Validators.required);
      nettoeinkommenValidators.push(Validators.required);
    }

    return fb.group({
      id: surplus.id,
      status: surplus.status,
      datum: [{value: surplus.datum, disabled: !!surplus.id}, validate ? seitValidators : []],
      ueberschuss: [{value: surplus.ueberschuss, disabled: !!surplus.id}, validate ? ueberschussValidators : []],
      bruttoeinkommen: [{value: surplus.bruttoeinkommen, disabled: !!surplus.id}],
      nettoeinkommen: [{
        value: surplus.nettoeinkommen,
        disabled: !!surplus.id
      }, validate ? nettoeinkommenValidators : []],
      vermoegen: [{value: surplus.vermoegen, disabled: !!surplus.id}, validate ? vermoegenValidators : []],
      verbindlichkeiten: [{
        value: surplus.verbindlichkeiten,
        disabled: !!surplus.id
      }, validate ? verbindlichkeitenValidators : []],
      bemerkung: [{value: surplus.bemerkung, disabled: !!surplus.id}, [Validators.maxLength(1000)]]
    });
  }

  deleteCard() {
    if (this.isFromServer) {
      this.group.get('status').setValue(Status.DELETED, {emitEvent: false});
    } else {
      this.deleteContact.emit(this.group);
    }
  }

  getBemerkungCounter() {
    const bemerkungValue = this.group.get('bemerkung').value;
    const currentLength = bemerkungValue ? bemerkungValue.length : 0;
    return currentLength + '/' + 1000;
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
