<h1 mat-dialog-title>
  Aufgabe abbrechen</h1>

<mat-dialog-content class="flex flex-column gap-4 pb-4">
  <mat-form-field class="w-full">
    <textarea #comment matInput cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="10" placeholder="Kommentar"
              appTrim></textarea>
  </mat-form-field>

  <button mat-raised-button type="button" (click)="onClose(cancelChoices.DISCARD_UNCLAIM, comment.value)">Änderung verwerfen und Aufgabe zurückgeben</button>
  <button mat-raised-button type="button" (click)="onClose(cancelChoices.SAVE_UNCLAIM, comment.value)">Änderung speichern und Aufgabe zurückgeben</button>
  <button mat-raised-button type="button" (click)="onClose(cancelChoices.DISCARD_KEEP, comment.value)">Änderung verwerfen und Aufgabe behalten</button>
  <button mat-raised-button type="button" color="primary" (click)="onClose(cancelChoices.SAVE_KEEP, comment.value)" >Änderung speichern und Aufgabe behalten</button>
  <button mat-raised-button type="button" color="warn" (click)="onClose(cancelChoices.DISCARD_DELETE, comment.value)">Änderung verwerfen und Aufgabe löschen</button>
</mat-dialog-content>
