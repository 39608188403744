<div [formGroup]="variables.get('customer')" class="flex flex-column gap-3 flex-grow-1">
  <mat-checkbox [formControl]="variables.get('customer.kunde.bezahltPerRechnung')">
    Der Kunde bezahlt per Rechnung, auf
    die mit <a
      href="https://www.gesetze-im-internet.de/vvg_2008/__37.html" target="_blank">§ 37</a> und <a
      href="https://www.gesetze-im-internet.de/vvg_2008/__38.html" target="_blank">§ 38</a> VVG verbundenen Gefahren
    wurde
    hingewiesen.
  </mat-checkbox>

  <div *ngIf="bankverbindungen.errors && bankverbindungen.errors['duplicateField']" class="invalid">
    Doppelte IBAN: <span *ngFor="let iban of bankverbindungen.errors['duplicateField']; let isLast=last">{{iban}}{{isLast ? '' : ', '}}</span>
  </div>
  <div *ngIf="variables.get('customer').errors && variables.get('customer').errors['bankverbindungRequired']" class="invalid">
    Es muss mindestens eine Bankverbindung angegeben werden.
  </div>
  <div *ngIf="variables.get('customer').errors && variables.get('customer').errors['hauptverbindungRequired']" class="invalid">
    Es muss eine Hauptbankverbindung angegeben werden.
  </div>


  <div formArrayName="bankverbindungen" class="flex flex-wrap gap-3">
    <div *ngFor="let bank of bankverbindungen.controls | notDeleted; let i=index">
      <app-single-bank [control]="bank"
                       [formGroupName]="i"
                       class="mb-3 bank-width"
                       (deleteBank)="removeBank(i)"
                       (setAsPrime)="setBankToPrime(i)"
                       [bankCount]="bankverbindungen.length"
                       [originalBank]="getOriginalBank(bank.value)"
                       [contracts]="getActiveContracts() | async">
      </app-single-bank>
    </div>
  </div>
  <button type="button" style="color:white" mat-fab (click)="addBank()" matTooltip="Neue Bankverbindung hinzufügen">
    <mat-icon>add</mat-icon>
  </button>
</div>

