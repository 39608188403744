import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {Person} from '@taures/angular-commons';

@Component({
  selector: 'app-create-process',
  templateUrl: 'create-process.component.html',
  styleUrls: ['create-process.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateProcessComponent {
  customerControl: UntypedFormControl;

  constructor(private dialogRef: MatDialogRef<CreateProcessComponent>,
              private dialog: MatDialog,
              private fb: UntypedFormBuilder) {
    this.customerControl = fb.control(null, Validators.required);
  }

  get dialogResult() {
    return {processDefinitionKey: 'aza', variables: {customerId: this.customerControl.value}};
  }

  onNewCustomer(customer: Person): void {
    this.customerControl.setValue(customer.id);
  }
}

