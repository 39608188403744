import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DocumentService} from '../../../../services/document.service';
import {Subscription} from 'rxjs';
import {BrokerContract, BrokerContractService} from '../../../../services/broker-contract.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContractDocument, CustomerDocument} from '@taures/angular-commons';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-broker-contract-document-form',
  templateUrl: 'broker-contract-document-form.component.html',
  styleUrls: ['broker-contract-document-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrokerContractDocumentFormComponent implements OnDestroy {

  file: UntypedFormControl = new UntypedFormControl();
  fileChangeSubscription: Subscription;
  analyzeSubscription: Subscription;
  downloadSubscription: Subscription;
  private pdfObjectUrl: string;
  dataURL: SafeResourceUrl;
  filenameOverride: string;

  @Output() brokerContractChanged: EventEmitter<BrokerContract> = new EventEmitter();

  constructor(private documentService: DocumentService,
              private changeDetector: ChangeDetectorRef,
              private brokerContractService: BrokerContractService,
              private snackbar: MatSnackBar,
              readonly sanitizer: DomSanitizer) {
  }

  private brokerContractDocumentGroup: UntypedFormGroup;

  get brokerContractDocument(): UntypedFormGroup {
    return this.brokerContractDocumentGroup;
  }

  @Input()
  set brokerContractDocument(value: UntypedFormGroup) {
    this.brokerContractDocumentGroup = value;

    // load initial pdf
    if (this.brokerContractDocument) {

      // copy the values and the validator in our private form control
      this.file.setValidators(value.get('fileId').validator);
      this.file.reset({fileId: value.get('fileId').value, filename: value.get('filename').value});

      if (!this.pdfObjectUrl) {
        this.downloadFile(this.file.value);
      }
      // listen for changes of the file
      this.fileChangeSubscription = this.file.valueChanges
        .subscribe(file => {
          this.filenameOverride = null;
          this.brokerContractDocument.patchValue({
              fileId: file ? file.fileId : null,
              filename: file ? file.filename : null
            },
            {emitEvent: false});
          this.downloadFile(file, true);

          if (!file) {
            this.brokerContractChanged.emit(null);
            this.pdfObjectUrl = null;
            this.dataURL = null;
          }
        });
    }
  }

  public static buildGroup(fb: UntypedFormBuilder, document: ContractDocument | CustomerDocument, withValidation: boolean = true) {
    const validators = withValidation ? [Validators.required] : [];
    return fb.group({
      id: document.id,
      typ: [document.typ, validators],
      kommentar: document.kommentar,
      fileId: [document.fileId, validators],
      filename: [document.filename, validators]
    });
  }

  ngOnDestroy() {
    if (this.fileChangeSubscription) {
      this.fileChangeSubscription.unsubscribe();
    }
    if (this.analyzeSubscription) {
      this.analyzeSubscription.unsubscribe();
    }
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
    if (this.pdfObjectUrl) {
      URL.revokeObjectURL(this.pdfObjectUrl);
    }
  }

  private downloadFile(file, analyze = false) {
    if (!file || !file.fileId) {
      return;
    }
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
    this.downloadSubscription = this.documentService
      .downloadInboxFile(file.fileId)
      .subscribe(loadedFile => {
        if (analyze) {
          this.analyzePDF(loadedFile);
        }
        this.pdfObjectUrl = URL.createObjectURL(loadedFile);
        this.dataURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfObjectUrl);
        this.changeDetector.markForCheck();
      });
  }

  private analyzePDF(pdf: File) {
    if (this.analyzeSubscription) {
      this.analyzeSubscription.unsubscribe();
    }
    this.analyzeSubscription = this.brokerContractService
      .analyzePDF(pdf)
      .subscribe(
        analyzeResponse => {
          if (analyzeResponse.filename) {
            this.brokerContractDocument.get('filename').setValue(analyzeResponse.filename);
            this.filenameOverride = analyzeResponse.filename;
          }
          this.brokerContractChanged.emit(analyzeResponse);
          if (!analyzeResponse) {
            this.snackbar
              .open(`Das Dokument konnte nicht verarbeitet werden. Es ist kein Datensatz zu dem QR-Code vorhanden.`,
                null,
                {duration: 5000});
          }
        }, error => {
          if (error.status === 400) {
            this.snackbar.open(`Das Dokument konnte nicht verarbeitet werden.
Es enthält keinen QR-Code oder der QR-Code wurde nicht erkannt.`, null, {duration: 5000});
          }
          if (error.status === 415) {
            this.snackbar.open(`Es können nur PDF Dokumente verarbeitet werden.`, null, {duration: 5000});
          }
          this.brokerContractChanged.emit(null);
        }
      );
  }
}
