<object *ngIf="dataURL" [attr.data]="dataURL" class="pdf-viewer">
</object>
<mat-progress-spinner *ngIf="loading"
                      mode="indeterminate"
                      [diameter]="48"
                      [strokeWidth]="2"
                      class="pdf-preview-spinner"></mat-progress-spinner>
<h2 *ngIf="noPreview">Für dieses Dokument ist keine Vorschau möglich.</h2>
<a #downloadLink style="display: none"></a>

<div *ngIf="!noPreview && isFirefox && dataURL" class="p-4 absolute text-center firefox-warning">Mit Firefox ist das Darstellen von Signaturen nicht möglich. Bitte nutze einen anderen
  Browser. (Chrome/Safari)
</div>



