<div [formGroup]="variables">
  <div class="flex flex-column gap-2" [formGroup]="customerGroup">
    <div *ngIf="contactDataArray.invalid && contactDataArray.errors && contactDataArray.errors['groupRequired']"
         class="invalid">
      Es muss mindestens eine Email-Adresse oder eine Telefonnummer (Festnetz oder Mobil) angegeben werden.
    </div>
    <div class="flex gap-7">
      <h3 class="w-30rem">E-Mail-Adressen</h3>
      <h3 class="w-30rem">Telefonnummern</h3>
    </div>
    <div formArrayName="kommunikationsdaten" class="flex gap-7">
      <div class="flex flex-column gap-3 w-30rem">
        <div class="flex flex-column gap-3">
          <app-contact
            *ngFor="let contactData of contactDataArray.controls | notDeleted | contactTypeGroup:contactTypes:emailGroup;"
            [group]="contactData"
            [contactTypes]="contactTypes"
            (deleteContact)="removeContact(contactData)"
            (setAsPrime)="setContactToPrime(contactData)"></app-contact>

        </div>

        <button type="button" style="color: white" mat-fab class="mat-fab-bottom-right2" (click)="addEmail()"
                matTooltip="E-Mail-Adresse hinzufügen">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="flex flex-column gap-3 w-30rem">
        <mat-error *ngIf="hasNoPhoneContact()">Hinweis: Um eine Nachbearbeitung zu vermeiden, erfassen Sie bitte eine Telefonnummer!</mat-error>
        <div class="flex flex-column gap-3">

          <app-contact
            *ngFor="let contactData of contactDataArray.controls | notDeleted | contactTypeGroup:contactTypes:phoneGroup;"
            [group]="contactData"
            [contactTypes]="contactTypes"
            (deleteContact)="removeContact(contactData)"
            (setAsPrime)="setContactToPrime(contactData)"></app-contact>
        </div>
        <button type="button" style="color:white" mat-fab class="mat-fab-bottom-right2" (click)="addTelNum()"
                matTooltip="Telefonnummer hinzufügen">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>


