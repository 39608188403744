<div class="flex flex-column w-25rem p-4 pb-1 mat-elevation-z1 border-4px overflow-hidden">
  <app-document-form [group]="documentGroup"
                     [documentTypes]="documentTypes"
                     [previewPresenter]="previewPresenter"
                     (documentChange)="documentChange.emit($event)"
  ></app-document-form>
  <div *ngIf="showSameTypeHint">
    Für diesen Dokumententyp gibt es {sameTypeCount, plural, =2 {ein weiteres Dokument} other {{{sameTypeCount - 1}} weitere Dokumente}} für diesen Kunden.
  </div>
  <div class="flex gap-2 justify-content-end align-items-center" *ngIf="!documentGroup.disabled">
    <label>Verschieben zu:</label>
    <mat-form-field floatPlaceholder="never" class="w-7rem">
      <mat-select (selectionChange)="moveDocument.emit($event)" panelClass="expandable-overlay-panel">
        <mat-option [value]="-1">Allgemein</mat-option>
        <mat-option [value]="-2">Konzept</mat-option>
        <mat-option *ngFor="let documents of moveTargetForms; let k=index" [value]="k">
          <app-contract-documents-description [contractForm]="moveTargetGroup.controls[k]"
                                              [tariffs]="tariffs"></app-contract-documents-description>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button type="button" mat-icon-button (click)="onRemoveDocument()" matTooltip="Dokument löschen">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
