<div [formGroup]="variables" class="flex flex-column flex-grow-1">
  <div [formGroup]="customer" class="flex flex-column gap-3">
    <div formGroupName="kunde" class="flex gap-3">
      <tr-user-autocomplete placeholder="Vertriebspartner" class="w-24rem"
                             *ngIf="canChangeVP()"
                             [mode]="'agent'"
                             formControlName="vp"
                             [additionalFilter]="vpFilter"
                             required>
      </tr-user-autocomplete>
      <tr-user-autocomplete placeholder="Betreuer" class="w-24rem"
                             [mode]="'agent'"
                             formControlName="betreuer"
                             [additionalFilter]="betreuerFilter"
                             required>
      </tr-user-autocomplete>
    </div>
    <div class="flex gap-3">
      <div formGroupName="haushalt">
        <mat-form-field class="w-24rem">
          <mat-label>Hauptansprechspartner für Finanzen im Haushalt</mat-label>
          <mat-select formControlName="oberhaupt" required="true">
            <mat-option *ngFor="let personId of getHaushaltsmitglieder()" [value]="personId">
              {{getPersonName(personId) | async}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div formGroupName="kunde">
        <mat-form-field class="w-24rem">
          <mat-label>Kategorie</mat-label>
          <mat-select formControlName="kategorievp">
            <mat-option *ngFor="let kategorie of getCategories() | keyvalue" [value]="kategorie.key">
              {{kategorie.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-wrap gap-3 mb-3">
      <div *ngFor="let relationship of relationships.controls | notDeleted; let i=index">
        <app-single-relationship
          *ngIf="showRelationship(relationship)"
          class="flex relationship-width"
          [customerId]="customerId"
          [customerAddresses]="customerAddresses"
          [control]="relationship"
          [relationshipTypes]="relationshipTypes"
          [isSameHousehold]="isSameHousehold(relationship.value)"
          (addToHousehold)="addHouseholdmember($event)"
          (removeFromHousehold)="removeHouseholdmember($event)"
          (deleteRelationship)="removeRelationship(i)">
        </app-single-relationship>
      </div>

    </div>
  </div>
  <button type="button" class="text-white" mat-fab (click)="addRelationship()" matTooltip="Neue Beziehung hinzufügen">
    <mat-icon>add</mat-icon>
  </button>
</div>
