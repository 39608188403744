<app-data-comparison-card title="Bankverbindung" [crmEntity]="isFromServer" [primary]="isPrimary">
  <div class="flex flex-column gap-2"
       [formGroup]="control"
       tr-data-comparison-card-content>

    <mat-checkbox class="flex" (change)="setCardAsPrimary()" [disabled]="isPrimary" [checked]="isPrimary">
      Hauptbankverbindung
    </mat-checkbox>

    <div class="flex gap-2">
      <mat-checkbox formControlName="privat" class="w-6">
        Privat
      </mat-checkbox>
      <mat-checkbox formControlName="geschaeftlich" class="w-6">
        Geschäftlich
      </mat-checkbox>
    </div>

    <div class="flex gap2">
      <div *ngIf="kontoInhaber.length > 0" class="flex flex-column">
        <div class="flex-auto">
          Kontoinhaber:
        </div>
        <mat-chip-set class="flex-auto">
          <mat-chip *ngFor="let inhaberId of kontoInhaber.value; let i=index" class="chip"
                    matTooltip="{{inhaberId | personName: true | async}} entfernen">
            {{inhaberId | personName: true | async}}
            <mat-icon matChipRemove (click)="removeInhaber(i)">cancel</mat-icon>
          </mat-chip>
        </mat-chip-set>
      </div>
      <div *ngIf="kontoInhaber.length === 0" class="flex-auto invalid">
        <i>Kein Kontoinhaber</i>
      </div>
    </div>

    <div class="flex gap-2">
      <tr-user-autocomplete placeholder="Kontoinhaber suchen"
                             class="flex-auto autocomplete-field"
                             [mode]="'customer'"
                             [formControl]="kontoInhaberIdControl">
      </tr-user-autocomplete>
      <app-create-customer (newCustomer)="onNewCustomer($event)"></app-create-customer>
    </div>

    <mat-form-field>
      <mat-label>IBAN</mat-label>
      <app-iban formControlName="iban" required></app-iban>
      <mat-error *ngIf="control.get('iban').hasError('iban')">Keine gültige IBAN</mat-error>
    </mat-form-field>

    <div class="flex gap-2">
      <mat-form-field class="flex-auto">
        <mat-label>BIC</mat-label>
        <input matInput formControlName="bic" appTrim>
        <mat-error *ngIf="control.get('bic').hasError('pattern')">Keine gültige BIC</mat-error>
      </mat-form-field>

      <mat-form-field class="flex-auto">
        <mat-label>Bank</mat-label>
        <input matInput formControlName="bank" appTrim>
      </mat-form-field>
    </div>

    <div class="flex gap-2" *ngIf="isFromServer">
      <mat-form-field class="flex-auto">
        <mat-label>Konto</mat-label>
        <input matInput formControlName="konto" appTrim>
        <mat-error *ngIf="control.get('konto').hasError('pattern')">Keine gültige Kontonummber</mat-error>
      </mat-form-field>

      <mat-form-field class="flex-auto" *ngIf="isFromServer">
        <mat-label>Bankleitzahl</mat-label>
        <input matInput formControlName="blz" appTrim>
        <mat-error *ngIf="control.get('blz').hasError('pattern')">Keine gültige Bankleitzahl</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field class="flex-auto" *ngIf="showAenderungsauftrag()">
      <mat-label>Änderungsauftrag erstellen</mat-label>
      <mat-select formControlName="aenderungsauftrag">
        <mat-option value="YES">Ja, für ausgewählte Verträge</mat-option>
        <mat-option value="ALL">Ja, für alle Verträge</mat-option>
        <mat-option value="NO">Nein</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="flex gap-3" *ngIf="showAenderungsauftragVertraege()">
      <app-multi-contract-select class="flex-auto"
                                 [tariffs]="tariffs"
                                 [allContracts]="contracts"
                                 [initialContracts]="control.get('aenderungsauftragVertraege').value"
                                 (resultList)="setAenderungsauftragVertraege($event)">
      </app-multi-contract-select>
    </div>
    <mat-error *ngIf="control.hasError('aenderungsauftragVertragRequired') && showAenderungsauftrag()">
      Es muss mindestens ein Vertrag ausgewählt werden.
    </mat-error>


    <mat-form-field>
      <mat-label>Bemerkung</mat-label>
      <textarea matInput
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="isFromServer ? 5 : 10"
                cdkAutosizeMaxRows="10"
                formControlName="bemerkung"
                class="bank-comments"
                appTrim>
      </textarea>
    </mat-form-field>

    <div class="flex gap-2">
      <mat-form-field class="flex-auto">
        <mat-label>Gültig ab</mat-label>
        <input matInput [matDatepicker]="fromPicker" formControlName="gueltigAb" required>
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker [disabled]="control.controls['gueltigAb'].disabled"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <mat-label>Gültig bis</mat-label>
        <input matInput [matDatepicker]="unitllPicker" formControlName="gueltigBis">
        <mat-datepicker-toggle matSuffix [for]="unitllPicker"></mat-datepicker-toggle>
        <mat-datepicker #unitllPicker [disabled]="control.controls['gueltigBis'].disabled"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="flex justify-content-end" tr-data-comparison-card-buttons>
    <button type="button" mat-icon-button (click)="deleteCard()" matTooltip="Bankverbindung löschen"
            [disabled]="isFromServer">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</app-data-comparison-card>
