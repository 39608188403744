<form [formGroup]="filterForm" (ngSubmit)="onSubmit()" novalidate>
  <mat-card>
    <mat-card-header>
      <h2 matCardTitle>Filter</h2>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-column gap-1 sm:gap-2">
        <mat-form-field *ngIf="taskTypes.length > 1">
          <mat-label>Prozessschritt</mat-label>
          <mat-select formControlName="type">
            <mat-option>Nicht Ausgewählt</mat-option>
            <mat-option *ngFor="let type of taskTypes" [value]="type.id">{{ type.label }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Ab Eingangsdatum</mat-label>
          <input matInput [matDatepicker]="createdAfterPicker" formControlName="createdAfter">
          <mat-datepicker-toggle matSuffix [for]="createdAfterPicker"></mat-datepicker-toggle>
          <mat-datepicker #createdAfterPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Bis Eingangsdatum</mat-label>
          <input matInput [matDatepicker]="createdBeforePicker" formControlName="createdBefore">
          <mat-datepicker-toggle matSuffix [for]="createdBeforePicker"></mat-datepicker-toggle>
          <mat-datepicker #createdBeforePicker></mat-datepicker>
        </mat-form-field>

        <tr-user-autocomplete [mode]="'user'" placeholder="Initiator"
                              formControlName="initiator"></tr-user-autocomplete>

        <tr-user-autocomplete [mode]="'user'"
                              placeholder="Zugewiesener Benutzer"
                              formControlName="candidateUser"
                              [matTooltip]="(filterForm.get('candidateUser').disabled) ? 'Es kann nicht gleichzeitig nach Gruppen und Benutzern gefiltert werden' : ''">
        </tr-user-autocomplete>

        <mat-form-field *ngIf="isBackOffice"
                        [matTooltip]="(filterForm.get('candidateGroup').disabled) ? 'Es kann nicht gleichzeitig nach Gruppen und Benutzern gefiltert werden' : ''">
          <mat-label>Zugewiesene Gruppe</mat-label>
          <mat-select formControlName="candidateGroup">
            <mat-option [value]="null">Nicht Ausgewählt</mat-option>
            <mat-option *ngFor="let group of groups | async" [value]="group.id">
              {{ group.bezeichnung }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Zustand</mat-label>
          <mat-select formControlName="assigned">
            <mat-option>Nicht Ausgewählt</mat-option>
            <mat-option *ngFor="let state of taskStates" [value]="state.value">{{ state.label }}</mat-option>
          </mat-select>
        </mat-form-field>

        <tr-user-autocomplete [mode]="'user'"
                              placeholder="Aktueller Bearbeiter"
                              formControlName="assignee">
        </tr-user-autocomplete>

        <tr-user-autocomplete [mode]="'customer'" placeholder="Kunde"
                              formControlName="customerId"></tr-user-autocomplete>

        <mat-form-field *ngIf="isBackOffice || isAdmin">
          <mat-label>Vertragssparte</mat-label>
          <mat-select formControlName="sparte" [compareWith]="compareWithContractCategory">
            <mat-option>Nicht Ausgewählt</mat-option>
            <mat-option *ngFor="let c of contractCategories | async" [value]="c.id">{{ c.bezeichnung }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions align="end" class="mb-2 mr-2 flex gap-2">
      <button mat-button type="button" (click)="onReset()">Zurücksetzen</button>
      <button mat-raised-button type="submit" color="primary">Übernehmen</button>
    </mat-card-actions>
  </mat-card>
</form>
