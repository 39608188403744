<div [formGroup]="variables" class="flex flex-column flex-grow-1">
  <div [formGroup]="customer" class="flex flex-wrap gap-3 mb-3">

    <div *ngFor="let maritalStatus of familienstandHistorie.controls | notDeleted; let i=index">
      <app-single-marital-status
        [control]="maritalStatus"
        class="marital-status-width"
        [maritalStatusOptions]="maritalStatiTypes | async"
        (removeCard)="removeCard(i)">
      </app-single-marital-status>
    </div>

  </div>
  <button type="button" mat-fab (click)="addCard()" style="color:white" matTooltip="Neuen Familienstand hinzufügen">
    <mat-icon>add</mat-icon>
  </button>
</div>
