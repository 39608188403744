<div [formGroup]="variables" class="flex flex-auto flex-column gap-4">
  <div formGroupName="customer">
    <div formArrayName="vertraege">
      <button *ngIf="newContracts.length < MAX_NEW_CONTRACT_AMOUNT" type="button"
              class="mb-3"
              color="primary"
              mat-stroked-button
              (click)="addContract()">
        <mat-icon style="line-height: 1.125em;">add</mat-icon>
        Vertrag hinzufügen
      </button>
      <mat-tab-group #tabGroup dynamicHeight="true" [mat-stretch-tabs]="false">

        <mat-tab label="Übersicht">
          <div class="flex flex-grow-1 flex-wrap gap-4 pt-3">
            <ng-container *ngFor="let contract of getFilteredContracts(); let i=index">
              <app-single-contract-small *ngIf="contract.value.id < 0" [control]="contract"
                                         [tariffs]="tariffs"
                                         [title]="contractLabel(i)"
                                         [buendelpolicen]="getBuendelpolicen()"
                                         [isHauptvertrag]="isHauptvertrag(contract)"
                                         (editContract)="openTab(i + 1)"
                                         (createBpEvent)="createBuendelpolice($event)"
                                         class="item mb-4 flex">
              </app-single-contract-small>
            </ng-container>
            <div *ngIf="contracts.invalid && contracts.errors && (contracts.errors['minlength'] || contracts.errors['required'])" class="invalid">
              Keine Verträge vorhanden.
            </div>
          </div>
        </mat-tab>

        <mat-tab *ngFor="let contract of getFilteredContracts(); let i=index" [formGroupName]="i">
            <ng-template mat-tab-label>
              <div class="flex gap-2 align-items-center">
                <div class="tab-label" [class]="contract.invalid ? 'invalid' : ''">{{contractLabel(i)}}</div>
                <button type="button" mat-icon-button
                        (click)="removeContract(contract.value.id)"
                        color="warn"
                        matTooltip="Vertrag wieder entfernen">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </ng-template>
            <app-single-contract-full class="p-4"
                                      [contractGroup]="contract"
                                      [tariffs]="tariffs"
                                      [identityTypes]="identityTypes | async"
                                      [antragartTypes]="antragartTypes | async"
                                      [antragEmpfaengerTypes]="antragEmpfaengerTypes | async"
                                      [buAntragVariantenTypes]="buAntragVariantenTypes | async"
                                      [pkvAntragsqualitaeten]="pkvAntragsqualitaeten | async"
                                      [paymentOptions]="paymentOptions | async"
                                      [taskDefinition]="taskDefinition"
                                      [customerGroup]="customer"
                                      [contracts]="contracts.value">
            </app-single-contract-full>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

