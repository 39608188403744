<div class="flex flex-column gap-4 sm:flex-row">
  <app-filter class="flex-auto sm:w-16rem sm:flex-none lg:w-25rem mb-1 ml-1"
              [filter]="filter | async"
              (changeFilter)="filterChanged($event)">
  </app-filter>

  <div class="flex flex-column flex-grow-1 h-full">
    <div class="flex flex-wrap flex-none justify-content-between align-items-center mat-elevation-z1 top pr-3 mr-4 mb-4 ml-1 border-4px">
      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="(paging | async)?.total"
        [pageIndex]="pageIndex(paging | async, filter | async)"
        [pageSize]="(filter | async)?.limit"
        (page)="onPagingChanged($event)"
        class="paginator-fix">
      </mat-paginator>

      <div class="flex flex-wrap align-items-center gap-2 sorter dense-2">
        <label class="sort-label">Sortierung:</label>
        <mat-form-field floatPlaceholder="never" class="sort-field">
          <mat-select (selectionChange)="changeOrder($event)" [value]="(filter | async)?.orderBy">
            <mat-option [value]="taskOrder.TASK_CREATE_TIME_ASC">&auml;lteste zuerst</mat-option>
            <mat-option [value]="taskOrder.TASK_CREATE_TIME_DESC">neueste zuerst</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-column flex-grow-1 gap-3 pr-4 pb-4 pl-1">
      <ng-template *ngIf="(tasks | async)?.length > 0; else templateForEmpty" ngFor let-task [ngForOf]="tasks | async">
        <app-inbox-task-card [task]="task" class="flex"
                             [currentUserId]="currentUserId | async"
                             (claimTask)="onClaimTask($event)"
                             (unclaimTask)="onUnclaimTask($event)"
                             (transferCustomer)="onTransferedCustomer()">
          <ng-container [ngSwitch]="task.formKey" tr-task-type-details>
            <app-follow-up-agent-task-details *ngSwitchCase="taskDefinitionEnum.FollowUpAgent" [task]="task">
            </app-follow-up-agent-task-details>

            <app-follow-up-backoffice-details *ngSwitchCase="taskDefinitionEnum.FollowUpBackOffice" [task]="task">
            </app-follow-up-backoffice-details>
          </ng-container>
        </app-inbox-task-card>
      </ng-template>
      <ng-template #templateForEmpty>
        <mat-card>
          <mat-card-content>Keine Aufgaben für die Filterkriterien vorhanden.</mat-card-content>
        </mat-card>
      </ng-template>
    </div>
  </div>
</div>

<button *ngIf="isAllowedToCreateProcess" type="button" mat-fab class="mat-fab-bottom-right2" (click)="createTaskDialog()"
        matTooltip="Neue AZA anlegen">
  <mat-icon>add</mat-icon>
</button>
