import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl, Validators} from '@angular/forms';
import {DocumentService} from '../../services/document.service';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PdfPreviewDialogComponent} from '../pdf-preview-dialog/pdf-preview-dialog.component';
import {FileDocument} from '@taures/angular-commons';

export interface FileDocumentPreviewPresenter {
  openPreview(file: FileDocument): void;
}

@Component({
  selector: 'app-document-file-input',
  templateUrl: 'document-file-input.component.html',
  styleUrls: ['document-file-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentFileInputComponent implements DoCheck, ControlValueAccessor, OnDestroy, FileDocumentPreviewPresenter {
  disabled = false;
  fileDocument: FileDocument;
  filenameControl = new UntypedFormControl();
  loading = false;

  @ViewChild('fileInput', {static: true})
  fileInput: ElementRef;

  @ViewChild('downloadLink', {static: true})
  downloadLink: ElementRef;

  @Input()
  placeholder: string;

  @Input()
  showAdditionalButtons = true;

  @Input()
  showDropzone = false;

  @Input()
  accept = '*';

  @Input()
  previewPresenter: FileDocumentPreviewPresenter;

  constructor(private documentService: DocumentService,
              private dialog: MatDialog,
              @Optional() @Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
    this.filenameControl.setAsyncValidators(this.ngControl.asyncValidator);
    this.filenameControl.setValidators(Validators.compose([this.ngControl.validator, Validators.maxLength(255)]));
  }

  private isRequired = false;
  private destroy = new Subject<void>();

  get required() {
    return this.isRequired;
  }

  @Input()
  set required(value: boolean) {
    this.isRequired = coerceBooleanProperty(value);
  }

  get filename() {
    return this.fileDocument ? this.fileDocument.filename : null;
  }

  get filePresent() {
    return !!this.fileDocument && (!!this.fileDocument.fileId || !!this.fileDocument.id);
  }

  @Input()
  set filenameOverride(value: string) {
    if (value) {
      this.filenameControl.setValue(value);
    }
  }

  // noinspection UnterminatedStatementJS
  onChange: any = () => {
    // default function
  }

  // noinspection UnterminatedStatementJS
  onTouched: any = () => {
    // default function
  }

  ngDoCheck() {
    this.filenameControl.markAsTouched();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: FileDocument): void {
    if (!value) {
      return;
    }
    if (value === this.fileDocument) {
      return;
    }
    this.fileDocument = value;
    this.filenameControl.setValue(this.filename);
    if (this.isCrmFile()) {
      this.filenameControl.disable();
    } else {
      this.filenameControl.enable();
    }
  }

  onFileChange(event: Event) {
    const fileList: FileList = (event.target as HTMLInputElement).files;
    this.changeFiles(fileList);
  }

  onFileDropped(fileList: FileList) {
    this.changeFiles(fileList);
  }

  onUpload(): void {
    this.onTouched();
    this.fileInput.nativeElement.click();
  }

  onDownload(): void {
    this.onTouched();
    const downloadObservable = this.isCrmFile() ? this.documentService.downloadCrmFile(this.fileDocument.id)
      : this.documentService.downloadInboxFile(this.fileDocument.fileId);
    downloadObservable
      .pipe(takeUntil(this.destroy))
      .subscribe(file => {
        const url = URL.createObjectURL(file);
        const anchor = this.downloadLink.nativeElement as HTMLAnchorElement;
        anchor.setAttribute('href', url);
        anchor.setAttribute('download', this.fileDocument.filename || file.name);
        anchor.click();
        URL.revokeObjectURL(url);
    });
  }

  onPreview(): void {
    this.onTouched();
    (this.previewPresenter || this).openPreview(this.fileDocument);
  }

  openPreview(fileDocument: FileDocument): void {
    this.dialog.open(PdfPreviewDialogComponent, {
      data: fileDocument, panelClass: 'pdf-preview-dialog'});
  }

  onRemove(): void {
    this.fileDocument = null;
    this.propagateChange();
  }

  private changeFiles(fileList: FileList) {
    if (fileList.length > 0) {
      this.loading = true;
      this.documentService.uploadFile(fileList[0])
        .pipe(takeUntil(this.destroy))
        .subscribe(doc => {
          if (doc) {
            this.fileDocument = {
              fileId: doc.id,
              filename: fileList[0].name
            };
          }
          this.propagateChange();

          // null the value otherwise we cannot upload the same fileDocument twice
          this.fileInput.nativeElement.value = null;
          this.loading = false;
      });
    }
  }

  private propagateChange(): void {
    this.filenameControl.setValue(this.filename);
    this.filenameControl.markAsTouched();
    this.onChange(this.fileDocument);
  }

  showPreviewButton() {
    return this.filename && this.filename.endsWith('pdf') && this.showAdditionalButtons;
  }

  showDownloadButton() {
    return (!this.filename  || !this.filename.endsWith('pdf')) && this.showAdditionalButtons;
  }

  isCrmFile() {
    return this.fileDocument && !this.fileDocument.fileId && Number(this.fileDocument.id) > 0;
  }
}
