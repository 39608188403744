import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {isNil} from 'lodash-es';

import {UpdateCandidatesIdsAction} from '../../actions/task.actions';
import * as fromRoot from '../../reducers';
import {Task} from '../../../shared/common/services/task.service';
import {KeycloakTokenService, PersonService} from '@taures/angular-commons';
import {GroupsService, UserGroup} from '../../../groups/services/groups.service';
import {environment} from '../../../environments/environment';

type TaskType = Task;

@Component({
  selector: 'app-task-assign',
  templateUrl: 'task-assign.component.html',
  styleUrls: ['task-assign.component.scss']
})
export class TaskAssignComponent implements OnDestroy, OnInit {

  assignedUserIDControl = new UntypedFormControl();
  assignedGroupIDControl = new UntypedFormControl();
  userFilter = {backofficeOnly: true}; // TODO: Remove filter - should be userOnly
  assignedPersonIds: number[] = [];
  allGroups: UserGroup[];
  filteredGroups: UserGroup[];
  assignedGroups: UserGroup[];
  isBackOffice = this.keycloak.hasRole('backoffice', environment.roleResourceId);

  private userSubscription: Subscription;
  private groupSubscription: Subscription;
  private taskPreAssignedGroupIDs: string[];
  private currentTask: Task;

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: TaskType,
              readonly dialogRef: MatDialogRef<TaskAssignComponent>,
              readonly store: Store<fromRoot.State>,
              readonly keycloak: KeycloakTokenService,
              readonly personService: PersonService,
              readonly groupService: GroupsService) {
    this.assignedGroups = [];
    this.taskPreAssignedGroupIDs = [];
    this.task = data;
  }

  get task() {
    return this.currentTask;
  }

  @Input()
  set task(value: Task | null) {
    this.currentTask = value;
    if (this.currentTask) {
      this.assignedPersonIds = this.task.candidateUserIds.slice();
      if (this.currentTask.variables) {
        const candidateGroupIds = this.task.candidateGroupIds;
        if (candidateGroupIds) {
          candidateGroupIds.forEach(id => this.taskPreAssignedGroupIDs.push(id));
        }
      }
    }
  }

  assignTask(comment: string) {
    const newCandidateGroupIDs = this.assignedGroups.map(e => e.id);
    this.store.dispatch(new UpdateCandidatesIdsAction({
      taskID: this.task.id,
      userIDs: this.assignedPersonIds,
      groupIDs: newCandidateGroupIDs,
      comment
    }));

    this.closeDialog();
  }

  addAssignedUser(newUserID: number) {
    if (!isNil(newUserID)) {
      this.assignedPersonIds.push(newUserID);
    }
  }

  resetUserAutocompleteView() {
    if (!isNil(this.assignedUserIDControl.value)) {
      this.assignedUserIDControl.reset(null);
    }
  }

  resetGroupAutocompleteView() {
    if (!isNil(this.assignedGroupIDControl.value)) {
      this.assignedGroupIDControl.setValue('');
    }
  }

  removeAssignedUser(index: number) {
    this.assignedPersonIds.splice(index, 1);
  }

  removeAssignedGroup(index: number) {
    this.assignedGroups.splice(index, 1);
  }

  ngOnInit() {
    this.userSubscription = this.assignedUserIDControl.valueChanges.subscribe(userId => {
      this.addAssignedUser(userId);
      this.resetUserAutocompleteView();
    });

    this.groupSubscription = this.groupService.getGroups()
      .subscribe(allGroups => {

        this.allGroups = allGroups;
        this.filteredGroups = allGroups;

        // add the groups we got from the task earlier
        if (this.taskPreAssignedGroupIDs) {
          this.taskPreAssignedGroupIDs.forEach(id => this.assignedGroups.push(this.allGroups.find(e => e.id === id)));
          this.taskPreAssignedGroupIDs = [];
        }

        this.groupSubscription = this.assignedGroupIDControl.valueChanges.subscribe(groupInp => {
          if (typeof groupInp === 'object') {
            if (!this.assignedGroups.find(e => e.id === groupInp.id)) {
              this.assignedGroups.push(groupInp);
            }
            this.resetGroupAutocompleteView();
            this.filteredGroups = this.allGroups;
          } else {
            this.filteredGroups = this.allGroups.filter(g => g.bezeichnung.toLowerCase().indexOf(groupInp.toLowerCase()) === 0);
          }
        });

      });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.groupSubscription.unsubscribe();
    this.dialogRef.close(this.task);
  }

  closeDialog() {
    this.dialogRef.close(this.task);
  }
}
