import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, UrlSegmentGroup} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-not-found-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card>
      <mat-card-title>404: Not Found</mat-card-title>
      <mat-card-content>
        <p>Hey! It looks like the url <code>/{{url | async}}</code> doesn't exist yet.</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" routerLink="/">Take Me Home</button>
      </mat-card-actions>
    </mat-card>
  `,
  styles: [`
    :host {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
  `]
})
export class PageNotFoundComponent implements OnInit {
  url: Observable<string>;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.url = this.route.url.pipe(map(segments => (new UrlSegmentGroup(segments, {})).toString()));
  }
}
