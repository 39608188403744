import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {Tariff, TariffService} from '../../../services/tariff.service';
import {SingleContractComponent} from '../customer-contract/single-contract-full/single-contract-full.component';
import {SingleIdentityComponent} from '../customer-identity/single-identity/single-identity.component';
import {TaskDefinition, Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService, IdentityType} from '../../../services/app-options.service';
import {Contract, Person} from '@taures/angular-commons';

const CONTRACT_ID_KEY = 'contractId';

@Component({
  selector: 'app-customer-contract-single',
  templateUrl: './customer-contract-single.component.html',
  styleUrls: ['./customer-contract-single.component.scss']
})
export class CustomerContractSingleComponent implements OnInit {
  @Input()
  taskDefinition: TaskDefinition;
  @Output() resetSubContract: EventEmitter<number> = new EventEmitter<number>();

  customerGroup: UntypedFormGroup;
  contractsArray: UntypedFormArray;
  tariffs: Observable<Tariff[]>;
  identityTypes: Observable<IdentityType[]>;
  antragartTypes: Observable<{ [key: string]: string }>;
  antragEmpfaengerTypes: Observable<{ [key: string]: string }>;
  buAntragVariantenTypes: Observable<{ [key: string]: string }>;
  pkvAntragsqualitaeten: Observable<{ [key: string]: string }>;
  paymentOptions: Observable<{ uivalue: string, dbvalue: string }[]>;

  @ViewChild('tabGroup') tabGroup;

  constructor(readonly tariffService: TariffService,
              readonly options: AppOptionsService) {
  }

  private variablesGroup: UntypedFormGroup;

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    this.customerGroup = (this.variablesGroup.controls.customer as UntypedFormGroup);
    this.contractsArray = (this.customerGroup.controls.vertraege as UntypedFormArray);
  }

  get contracts() {
    const contractId = this.variables.value[CONTRACT_ID_KEY];
    return this.contractsArray.controls
      .filter((c: UntypedFormGroup) => {
        const contract = c.getRawValue() as Contract;
        return contract.id === contractId || contract.hauptvertrag === contractId || contract.buendelpolice === contractId;
      });
  }

  get allContracts() {
    return this.contractsArray.value;
  }

  public static buildGroup(fb: UntypedFormBuilder, taskDefinition: TaskDefinition, variables: Variables, tariffs: Tariff[]) {
    const contractsFormArray = fb.array([]);
    const customer = variables.customer as Person;
    const contractId = variables[CONTRACT_ID_KEY];
    const identities = fb.array([]);
    const reworks = variables.reworks || [];
    if (customer && customer.vertraege) {
      customer.vertraege
        .forEach(contract => {
          let validate = contract.id === contractId;
          if (customer.vertraege.some(c => c.buendelpolice !== null && c.buendelpolice === contractId)) {
            validate = !(contract.id === contractId);
          }
          return contractsFormArray.push(SingleContractComponent.buildGroup(fb, contract, customer, taskDefinition,
            validate,
            reworks,
            false,
            true,
            null,
            tariffs.find(t => t.id === contract.tarif)));
        });
    }
    if (customer && customer.identifikationsmerkmale) {
      customer.identifikationsmerkmale.forEach(e => identities.push(SingleIdentityComponent.buildGroup(fb, e)));
    }
    return fb.group({
      contractId: variables[CONTRACT_ID_KEY],
      customer: fb.group({
        vertraege: contractsFormArray,
        identifikationsmerkmale: identities,
        geburtsdatum: customer.geburtsdatum
      })
    });
  }

  ngOnInit() {
    this.tariffs =  of(this.tariffService.tariffs);
    this.identityTypes = of(this.options.identificationTypes);
    this.antragartTypes = of(this.options.antragartTypes);
    this.antragEmpfaengerTypes = of(this.options.antragEmpfaengerTypes);
    this.buAntragVariantenTypes = of(this.options.buAntragVarianten);
    this.pkvAntragsqualitaeten = of(this.options.pkvAntragsqualitaeten);
    this.paymentOptions = of(this.options.paymentOptions);
  }

  openTab(index: number) {
    this.tabGroup.selectedIndex = index;
  }

  contractLabel(contract: AbstractControl, index: number) {
    if (contract.get('buendelpolice').value !== null) {
      return `Vertrag ${index + 1}`;
    }
    if (index === 0) {
      return 'Hauptvertrag';
    }
    return `Untervertrag ${index}`;
  }

  onResetContract(id: number) {
    this.resetSubContract.emit(id);
  }

  isBuendelpolice(contract: AbstractControl) {
    return this.contractsArray.controls.some(control => control.get('buendelpolice').value === contract.get('id').value);
  }

  getFilteredContracts() {
    return this.contracts.filter(contract => !this.isBuendelpolice(contract));
  }
}
