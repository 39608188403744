import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TauresValidators} from '../../../../taures-validators';
import {catchError, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {IbanService} from '../../../../services/iban.service';
import {MatDialog} from '@angular/material/dialog';
import {BankChooserComponent} from '../../../bank-chooser/bank-chooser.component';
import {isNil} from 'lodash-es';
import {Tariff, TariffService} from '../../../../services/tariff.service';
import {BankDetails, Contract, Person, PersonService, Status} from '@taures/angular-commons';

@Component({
  selector: 'app-single-bank',
  templateUrl: 'single-bank.component.html',
  styleUrls: ['single-bank.component.scss']
})
export class SingleBankComponent implements OnDestroy, OnInit, OnChanges {
  @Output() deleteBank: EventEmitter<void> = new EventEmitter();
  @Output() setAsPrime: EventEmitter<void> = new EventEmitter();
  @Input() bankCount: number;
  @Input() contracts: Contract[];
  @Input() originalBank: BankDetails;
  tariffs: Tariff[];
  kontoInhaber: UntypedFormArray;
  kontoInhaberIdControl: UntypedFormControl = new UntypedFormControl();
  private destroy: Subject<boolean> = new Subject();
  private group: UntypedFormGroup;

  constructor(private ibanService: IbanService, private dialog: MatDialog, readonly personService: PersonService,
              private tariffService: TariffService) {
  }

  get control(): UntypedFormGroup {
    return this.group;
  }

  @Input()
  set control(value: UntypedFormGroup) {
    this.group = value;
    if (this.group.get('status') && this.group.get('id').value) {
      this.group.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.group.get('status').setValue(Status.MODIFIED, {emitEvent: false});
        });
    }

    this.group.get('iban')
      .valueChanges
      .pipe(
        filter(() => this.group.get('iban').valid),
        switchMap(iban => this.ibanService.getBanks(iban)
          .pipe(catchError(() => of([])))
        ),
        filter(banks => banks.length > 0),
        switchMap(banks => {
          if (banks.length === 1) {
            return of(banks[0]);
          }
          return this.dialog.open(BankChooserComponent, {data: banks})
            .afterClosed().pipe(map(bank => bank || banks[0]));
        }),
        takeUntil(this.destroy)
      ).subscribe(bank => {
      this.group.get('bank').setValue(bank.name);
      this.group.get('bic').setValue(bank.bic);
      this.group.get('blz').setValue(bank.blz);
    });
  }

  get colorClass(): string {
    return this.isPrimary ? 'header taures-primary-blue' : 'header taures-secondary-blue';
  }

  get isFromServer(): boolean {
    return !!this.group.controls.id.value;
  }

  get isPrimary(): boolean {
    return !!this.group.controls.hauptverbindung.value;
  }

  public static buildGroup(fb: UntypedFormBuilder, bankDetails: BankDetails, bankLength: number, originalBank: BankDetails,
                           validation: boolean = true) {
    const ibanValidators = [TauresValidators.iban];
    const gueltigAbValidators = [];
    const aenderungsauftragValidators = [];
    const bankGroupValidators = [];
    if (!bankDetails.id) {
      ibanValidators.push(Validators.required);
      gueltigAbValidators.push(Validators.required);
    }
    // either new bank or new hauptverbindung
    if ((!bankDetails.id && bankLength > 1) ||
      (bankDetails.id && originalBank && !originalBank.hauptverbindung && bankDetails.hauptverbindung)) {
      aenderungsauftragValidators.push(Validators.required);
      bankGroupValidators.push(TauresValidators.aenderungsauftragVertragRequired);
    }
    return fb.group({
      id: bankDetails.id,
      status: bankDetails.status,
      iban: [{value: bankDetails.iban, disabled: !!bankDetails.id}, validation ? ibanValidators : []],
      inhaber: fb.array(bankDetails.inhaber || [], [Validators.required]),
      hauptverbindung: [bankDetails.hauptverbindung, []],
      bemerkung: bankDetails.bemerkung,
      privat: bankDetails.privat,
      geschaeftlich: bankDetails.geschaeftlich,
      gueltigAb: [{value: bankDetails.gueltigAb, disabled: !!bankDetails.id}, validation ? gueltigAbValidators : []],
      gueltigBis: bankDetails.gueltigBis,
      bank: [{value: bankDetails.bank, disabled: !!bankDetails.id}, []],
      bic: [{value: bankDetails.bic, disabled: !!bankDetails.id}, validation ? [TauresValidators.bic] : []],
      konto: [{
        value: bankDetails.konto,
        disabled: !!bankDetails.id
      }, validation ? [TauresValidators.accountNumber] : []],
      blz: [{value: bankDetails.blz, disabled: !!bankDetails.id}, validation ? [TauresValidators.blz] : []],
      aenderungsauftrag: [{value: bankDetails.aenderungsauftrag, disabled: false}, aenderungsauftragValidators],
      aenderungsauftragVertraege: [{value: bankDetails.aenderungsauftragVertraege, disabled: false}],
    }, {validators: bankGroupValidators});
  }

  deleteCard() {
    this.deleteBank.emit();
  }

  setCardAsPrimary() {
    this.control.get('aenderungsauftrag').setValidators(Validators.required);
    this.control.setValidators(TauresValidators.aenderungsauftragVertragRequired);
    this.setAsPrime.emit();
  }

  ngOnInit(): void {
    this.tariffs = this.tariffService.tariffs;
    this.kontoInhaber = this.control.get('inhaber') as UntypedFormArray;

    this.kontoInhaberIdControl.valueChanges.pipe(
      takeUntil(this.destroy)
    ).subscribe(customerId => {
      this.addInhaber(customerId);
      this.resetCustomerAutocompleteView();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contracts && changes.contracts.currentValue !== null &&
      changes.contracts.currentValue.length === 0) {
      this.control.get('aenderungsauftrag').setValue('NO');
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  addInhaber(customerId: number): void {
    if (!isNil(customerId) && !this.kontoInhaber.controls.find(c => Number(c.value) === customerId)) {
      this.kontoInhaber.push(new UntypedFormControl(customerId));
    }
  }

  removeInhaber(index: number): void {
    this.kontoInhaber.removeAt(index);
  }

  resetCustomerAutocompleteView() {
    if (!isNil(this.kontoInhaberIdControl.value)) {
      this.kontoInhaberIdControl.reset(null);
    }
  }

  onNewCustomer(customer: Person): void {
    this.addInhaber(customer.id);
  }

  showAenderungsauftrag() {
    return this.contracts && this.contracts.length > 0 && ((this.control.get('id').value === null && this.bankCount > 1)
        || (this.originalBank && !this.originalBank.hauptverbindung && this.control.get('hauptverbindung').value));
  }

  showAenderungsauftragVertraege() {
    return this.showAenderungsauftrag() && this.control.get('aenderungsauftrag').value === 'YES';
  }

  setAenderungsauftragVertraege(contractIdList: number[]) {
    this.control.get('aenderungsauftragVertraege').setValue(contractIdList);
  }
}
