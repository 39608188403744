import * as fromInbox from './inbox';
import * as fromWizard from './wizard';
import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

export interface State {
  inbox: fromInbox.State;
  wizard: fromWizard.State;
}

export const reducers: ActionReducerMap<State> = {
  inbox: fromInbox.reducer,
  wizard: fromWizard.reducer
};

export const getInboxState = createFeatureSelector<fromInbox.State>('inbox');
export const getTasks = createSelector(getInboxState, fromInbox.getTasks);
export const getPaging = createSelector(getInboxState, fromInbox.getPaging);
export const getFilter = createSelector(getInboxState, fromInbox.getFilter);

export const hasTasksLoading = createSelector(getInboxState, fromInbox.hasTasksLoading);

export const getWizardState = createFeatureSelector<fromWizard.State>('wizard');
export const getWizardTask = createSelector(getWizardState, fromWizard.getTask);
export const getWizardCustomer = createSelector(getWizardState, fromWizard.getCustomer);
export const getCurrentContract = createSelector(getWizardTask, fromWizard.getCurrentContract);
export const getCurrentStep = createSelector(getWizardState, fromWizard.getCurrentStep);
