<h1 mat-dialog-title>Person anlegen</h1>

<mat-dialog-content>
  <form [formGroup]="form" (submit)="onSubmit()" novalidate>
    <div class="flex flex-column gap-3">
      <tr-user-autocomplete placeholder="Vertriebspartner" class="flex"
                            [mode]="'agent'"
                            [additionalFilter]="includeConsultantFilter"
                            formControlName="vp">
      </tr-user-autocomplete>
      <tr-user-autocomplete placeholder="Betreuer" class="flex"
                            [mode]="'agent'"
                            formControlName="betreuer"
                            [additionalFilter]="betreuerFilter"
      ></tr-user-autocomplete>
      <mat-form-field>
        <mat-select formControlName="anrede" placeholder="Anrede" required>
          <mat-option [value]="'Herr'">Herr</mat-option>
          <mat-option [value]="'Frau'">Frau</mat-option>
          <mat-option [value]="'Firma'">Firma</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput placeholder="Vorname" formControlName="firstName" required>
        <mat-error>Ungültiges Zeichen</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input matInput placeholder="Nachname" formControlName="lastName" required>
        <mat-error>Ungültiges Zeichen</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Geburtsdatum</mat-label>
        <input matInput [matDatepicker]="birthdate" formControlName="birthdate" placeholder="Geburtsdatum" required
               [min]="minBirthday" [max]="maxBirthday">
        <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
        <mat-datepicker #birthdate></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="flex gap-3">
  <button mat-button type="button" mat-dialog-close [disabled]="loading">Abbrechen</button>
  <button mat-raised-button type="submit" color="primary" class="m-0" [disabled]="loading || !form.valid" (click)="onSubmit()">
    Anlegen
  </button>
</mat-dialog-actions>
