<h1 mat-dialog-title>AZA anlegen</h1>

<mat-dialog-content>
  <div class="flex gap-2">
    <tr-user-autocomplete
      class="flex-1"
      [mode]="'customer'"
      placeholder="Kunde"
      [formControl]="customerControl" required></tr-user-autocomplete>
    <app-create-customer (newCustomer)="onNewCustomer($event)"></app-create-customer>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button type="button" color="primary" [disabled]="!customerControl.valid"
          [mat-dialog-close]="dialogResult" cdkFocusInitial>
    Anlegen
  </button>
</mat-dialog-actions>

