<div *ngIf="taskContract">
  <div class="flex flex-column gap-2" *ngIf="tariffs | async as ts; else loading">
    <div class="flex flex-column">
      <span><b>Gesellschaft:</b></span>
      <span>{{getInsurer(ts)}}</span>
    </div>
    <div class="flex flex-column">
      <span><b>Sparte:</b></span>
      <span>{{getSparte(ts)}}</span>
    </div>
    <div class="flex flex-column">
      <span><b>Beginn:</b></span>
      <span>{{taskContract.beginlaufzeit | date: 'dd.MM.yyyy'}}</span>
    </div>
  </div>
</div>
<ng-template #loading>
  <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="2"></mat-progress-spinner>
</ng-template>

