import {ChangeDetectionStrategy, Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ErrorComponent {

  message: string;
  date: Date;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: HttpErrorResponse,
              private matSnackBarRef: MatSnackBarRef<ErrorComponent>) {
    this.message = data.statusText;
    if (data.error && typeof data.error === 'string') {
      this.message = data.error;
    }
    if (data.error && typeof data.error === 'object' && data.error.errorMessage) {
      this.message = data.error.errorMessage;
    } else if (data.error && typeof data.error === 'object' && data.error.message) {
      this.message = data.error.message;
    }

    this.date = new Date();
    if (this.data.headers.has('date')) {
      this.date = new Date(this.data.headers.get('date'));
    }
  }

  get error() {
    return this.data;
  }
}
