<app-data-comparison-card title="Adresse" [crmEntity]="isFromServer" [primary]="isPrimary">

  <div class="flex flex-column gap-2"
                    [formGroup]="control"
                    tr-data-comparison-card-content>

    <div class="flex gap-3 justify-content-center align-items-center">
      <mat-form-field class="flex">
        <mat-label>Typ</mat-label>
        <mat-select formControlName="geschaeftsanschrift">
          <mat-option [value]="false">Privat</mat-option>
          <mat-option [value]="true">Geschäftlich</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox (change)="setCardAsPrimary()" [disabled]="isPrimary" [checked]="isPrimary"
                    class="flex">
        Postanschrift
      </mat-checkbox>

      <mat-checkbox formControlName="meldeanschrift"
                    class="flex"
                    (change)="meldeAnschriftChanged()">
        Hauptwohnsitz
      </mat-checkbox>
    </div>

    <mat-form-field class="flex">
      <mat-label>C/O</mat-label>
      <input matInput formControlName="adresseco" appTrim>
    </mat-form-field>

    <div class="flex gap-3">
      <mat-form-field class="flex-auto">
        <mat-label>Straße</mat-label>
        <input matInput formControlName="strasse" appTrim required>
      </mat-form-field>
      <mat-form-field class="w-6rem">
        <mat-label>Nummer</mat-label>
        <input matInput formControlName="nummer" appTrim required>
      </mat-form-field>
    </div>

    <div class="flex gap-3">
      <mat-form-field class="w-6rem">
        <mat-label>PLZ</mat-label>
        <input matInput formControlName="plz" appTrim required>
      </mat-form-field>

      <mat-form-field class="flex-auto">
        <mat-label>Ort</mat-label>
        <input matInput formControlName="stadt" [matAutocomplete]="placeAutocomplete" required>
        <mat-autocomplete #placeAutocomplete="matAutocomplete">
          <mat-option *ngFor="let option of placeNames | async" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>


    <mat-form-field class="flex">
      <mat-label>Land</mat-label>
      <mat-select formControlName="land" required>
        <mat-option *ngFor="let country of sortedCountries" [value]="country.code">
          {{ country.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-auto" *ngIf="showAenderungsauftrag()">
      <mat-label>Änderungsauftrag erstellen</mat-label>
      <mat-select formControlName="aenderungsauftrag">
        <mat-option value="YES">Ja, für ausgewählte Verträge</mat-option>
        <mat-option value="ALL">Ja, für alle Verträge</mat-option>
        <mat-option value="NO">Nein</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="flex gap-3" *ngIf="showAenderungsauftragVertraege()">
      <app-multi-contract-select class="flex-auto"
                                 [tariffs]="tariffs"
                                 [allContracts]="contracts"
                                 [initialContracts]="control.get('aenderungsauftragVertraege').value"
                                 (resultList)="setAenderungsauftragVertraege($event)">
      </app-multi-contract-select>
    </div>
    <mat-error *ngIf="control.hasError('aenderungsauftragVertragRequired') && showAenderungsauftrag()">
      Es muss mindestens ein Vertrag ausgewählt werden.
    </mat-error>

    <mat-form-field class="flex">
        <mat-label>Bemerkung</mat-label>
        <textarea matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="10"
                  formControlName="bemerkung">
        </textarea>
    </mat-form-field>

    <div class="flex gap-3">
      <mat-form-field class="flex">
        <mat-label>Gültig ab</mat-label>
        <input matInput [matDatepicker]="fromPicker" formControlName="gueltigAb" required>
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker [disabled]="control.controls['gueltigAb'].disabled"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="flex">
        <mat-label>Gültig bis</mat-label>
        <input matInput [matDatepicker]="unitllPicker" formControlName="gueltigBis">
        <mat-datepicker-toggle matSuffix [for]="unitllPicker"></mat-datepicker-toggle>
        <mat-datepicker #unitllPicker [disabled]="control.controls['gueltigBis'].disabled"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="flex gap-3 justify-content-end align-items-center" tr-data-comparison-card-buttons>
    <button type="button" mat-icon-button (click)="deleteCard()" matTooltip="Adresse löschen" [disabled]="isFromServer">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

</app-data-comparison-card>
