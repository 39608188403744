<app-data-comparison-card title="Beschäftigungsverhältnis" [crmEntity]="isFromServer" [primary]="isPrimary">
  <div class="flex flex-column gap-2" [formGroup]="control" tr-data-comparison-card-content>
    <mat-checkbox (change)="setCardAsPrimary()" [disabled]="isPrimary" [checked]="isPrimary">
      Hauptbeschäftigungsverhältnis
    </mat-checkbox>
    <mat-form-field>
      <mat-label>Typ</mat-label>
      <mat-select formControlName="berufstatus" required>
        <mat-option *ngFor="let i of employmentOptions" [value]="i.id">
          <span>{{ i.bezeichnung }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="flex gap-2">
      <tr-user-autocomplete placeholder="Arbeitgeber"
                             [mode]="'customer'"
                             [additionalFilter]="companyFilter"
                             [displayNameFunc]="displayCompanyName"
                             formControlName="arbeitgeber"
                             class="flex-grow-1">
      </tr-user-autocomplete>
      <button mat-icon-button color="primary" type="button" (click)="createEmployer()"
              matTooltip="Neuen Arbeitgeber anlegen">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <mat-form-field>
      <mat-label>Bezeichnung</mat-label>
      <input matInput formControlName="bezeichnung" [matAutocomplete]="description" required>
      <mat-autocomplete #description="matAutocomplete">
        <mat-option *ngFor="let option of filteredDescriptionOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="flex gap-2">
      <mat-form-field class="flex-grow-1">
        <mat-label>Abteilung</mat-label>
        <input matInput formControlName="abteilung" appTrim>
      </mat-form-field>
      <mat-form-field class="flex-grow-1">
        <mat-label>Personalnummer</mat-label>
        <input matInput formControlName="personalnummer" appTrim>
      </mat-form-field>
    </div>

    <div class="flex gap-2">
      <mat-form-field class="flex-grow-1">
        <mat-label>Gültig ab</mat-label>
        <input matInput [matDatepicker]="fromPicker" formControlName="gueltigAb" required>
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="flex-grow-1">
        <mat-label>Gültig bis</mat-label>
        <input matInput [matDatepicker]="unitllPicker" formControlName="gueltigBis">
        <mat-datepicker-toggle matSuffix [for]="unitllPicker"></mat-datepicker-toggle>
        <mat-datepicker #unitllPicker></mat-datepicker>
      </mat-form-field>
    </div>

  </div>

  <div class="flex justify-content-end" tr-data-comparison-card-buttons>
    <button type="button" mat-icon-button (click)="removeCard()" matTooltip="Beschäftigung löschen"
            [disabled]="isFromServer">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</app-data-comparison-card>
