import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {SingleIdentityComponent} from './single-identity/single-identity.component';
import {Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService, IdentityType} from '../../../services/app-options.service';
import {Person, Status} from '@taures/angular-commons';

@Component({
  selector: 'app-customer-identity-comparison',
  templateUrl: 'customer-identity.component.html',
  styleUrls: ['customer-identity.component.scss']
})
export class CustomerIdentityComponent {
  customer: UntypedFormGroup;
  identifikationsmerkmale: UntypedFormArray;
  identityTypes: Observable<IdentityType[]>;
  private variablesGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, readonly options: AppOptionsService) {
    this.identityTypes = of(this.options.identificationTypes);
  }

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    this.customer = (this.variables.controls.customer as UntypedFormGroup);
    this.identifikationsmerkmale = (this.customer.controls.identifikationsmerkmale as UntypedFormArray);
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables) {
    const identities = fb.array([]);
    const customer = variables.customer as Person;

    if (customer && customer.identifikationsmerkmale) {
      customer.identifikationsmerkmale
        .forEach(e => identities.push(SingleIdentityComponent.buildGroup(fb, e, e.status !== Status.DELETED)));
    }

    return fb.group({
      customer: fb.group({
        identifikationsmerkmale: identities
      })
    });
  }

  addIdentity() {
    this.identifikationsmerkmale.push(SingleIdentityComponent.buildGroup(this.fb, {}));
  }

  removeIdentity(i: number) {
    const entity = this.identifikationsmerkmale.at(i);
    if (entity.get('id').value) {
      entity.patchValue({status: Status.DELETED}, {emitEvent: false});
    } else {
      this.identifikationsmerkmale.removeAt(i);
    }
  }
}
