<h1 mat-dialog-title>Kunde übertragen</h1>

<mat-dialog-content>
  <div class="flex gap-2">
    <tr-user-autocomplete [mode]="'customer'"
                          placeholder="Kunde"
                          class="flex flex-grow-1"
                          [formControl]="customerControl"
                          required>
    </tr-user-autocomplete>
    <app-create-customer (newCustomer)="onNewCustomer($event)"></app-create-customer>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Abbrechen</button>
  <button mat-raised-button type="button" color="primary" [disabled]="!customerControl.valid" (click)="finishDialog()">
    Übertragen
  </button>
</mat-dialog-actions>
