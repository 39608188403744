import {Injectable} from '@angular/core';
import { Interpreter} from 'eval5';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor() {
  }

  run(script: string, env: any) {
    const interpreter = new Interpreter(env, {
      timeout: 1000,
      ecmaVersion: 5
    });
    return interpreter.evaluate(script);
  }
}
