<div class="border-4px p-3 mb-2 mat-elevation-z2 flex flex-column gap-4">
  <button *ngFor="let step of steps"
          mat-raised-button
          class="transition-none"
          type="button"
          [@stepState]="stateOfStep(step)"
          [@attention]="seekAttention(step.valid)"
          (@attention.done)="attentionDone($event)"
          (click)="openStep.emit(step.step)">
    {{step.step.title}}
  </button>
</div>
